.product-feedback-form {
	flex: 1 1 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.rating-wrap {
		flex: 1 1 100%;
	}

	.feedback-input-wrap {
		flex: 1 1 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.feedback-label {
			font-weight: $font-weight-semibold;
			flex: 1 1 100%;
			display: flex;
			justify-content: space-between;

			.feedback-guidelines {
				font-size: $font-size-small;
				display: flex;
				align-items: flex-end;
			}
		}

		.form-control {
			flex: 1 1 100%;
			resize: none;
			margin-bottom: 4px;
		}

		.form-buttons {
			display: flex;

			.text-btn:not(:last-child) {
				margin-right: 4px;
			}
		}
	}

	.badge-small {
		background: #36973e;
		border-radius: 10px;
		height: 1.25em;
	}

	@media screen and (min-width: $screen-sm-min) {
		flex-wrap: nowrap;

		.rating-wrap {
			flex: 1 1 33%;
			padding-right: 8px;
		}

		.review-wrap {
			flex: 1 1 66%;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		.rating-wrap {
			flex: 1 1 25%;
		}

		.review-wrap {
			flex: 1 1 75%;
		}
	}
}
