.serious-business-mode {
	.member-header {
		background-image: none;
		.member-avatar {
			display: none;
		}
		.member-title {
			margin-left: 0;
			height: 80px; /*perks måste få plats*/

			.member-class {
				display: none;
			}
		}
		.bottom-stats .cheevo-counter {
			display: none;
		}
		.achievements {
			display: none;
		}
		.dropper {
			display: none;
		}
		.dropper-arrow {
			display: none;
		}
		.drop-info-wrapper {
			padding-right: 0;
		}
		.drop-info {
			font-family: inherit;
			font-size: 0.9em;
			font-weight: bold;
		}
	}
	.member-perks {
		float: right;
	}

	.ximg.avatar, .ximg.avatar-small {
		background: $gray-four;

		.ximg-content {
			background-size: 60% !important;
			background-position: center !important;
			background-repeat: no-repeat;
		}
	}

	/* Feedback related styling */
	.top-review-large .review-header .review-info {
		justify-content: center;
	}

	.review-info .user-level {
		display: none;
	}

	.review .review-header .review-info {
		grid-template-rows: [row1-start] $font-size-small [row1-end row2-start] auto [row2-end];

		.user {
			grid-column: 1 / span 2;
		}

		.votes {
			grid-row: 2 / span 1;
		}
	}

	.question-answer-wrap .feedback-header {
		.username {
			grid-row: 1 / span 2;
			height: auto;
		}

		.level {
			display: none;
		}
	}
}
