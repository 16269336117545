.p-0 {
	padding: 0 !important;
}

.pt-0,
.ptl-0,
.ptr-0,
.py-0 {
	padding-top: 0 !important;
}

.pbr-0,
.pr-0,
.ptr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.pbl-0,
.pbr-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pbl-0,
.pl-0,
.ptl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: ($spacer-y*0.25) ($spacer-x*0.25) !important;
}

.pt-1,
.ptl-1,
.ptr-1,
.py-1 {
	padding-top: ($spacer-y*0.25) !important;
}

.pbr-1,
.pr-1,
.ptr-1,
.px-1 {
	padding-right: ($spacer-x*0.25) !important;
}

.pb-1,
.pbl-1,
.pbr-1,
.py-1 {
	padding-bottom: ($spacer-y*0.25) !important;
}

.pbl-1,
.pl-1,
.ptl-1,
.px-1 {
	padding-left: ($spacer-x*0.25) !important;
}

.p-2 {
	padding: ($spacer-y*0.5) ($spacer-x*0.5) !important;
}

.pt-2,
.ptl-2,
.ptr-2,
.py-2 {
	padding-top: ($spacer-y*0.5) !important;
}

.pbr-2,
.pr-2,
.ptr-2,
.px-2 {
	padding-right: ($spacer-x*0.5) !important;
}

.pb-2,
.pbl-2,
.pbr-2,
.py-2 {
	padding-bottom: ($spacer-y*0.5) !important;
}

.pbl-2,
.pl-2,
.ptl-2,
.px-2 {
	padding-left: ($spacer-x*0.5) !important;
}

.p-3 {
	padding: $spacer-y $spacer-x !important;
}

.pt-3,
.ptl-3,
.ptr-3,
.py-3 {
	padding-top: $spacer-y !important;
}

.pbr-3,
.pr-3,
.ptr-3,
.px-3 {
	padding-right: $spacer-x !important;
}

.pb-3,
.pbl-3,
.pbr-3,
.py-3 {
	padding-bottom: $spacer-y !important;
}

.pbl-3,
.pl-3,
.ptl-3,
.px-3 {
	padding-left: $spacer-x !important;
}

.p-4 {
	padding: ($spacer-y*1.5) ($spacer-x*1.5) !important;
}

.pt-4,
.ptl-4,
.ptr-4,
.py-4 {
	padding-top: ($spacer-y*1.5) !important;
}

.pbr-4,
.pr-4,
.ptr-4,
.px-4 {
	padding-right: ($spacer-x*1.5) !important;
}

.pb-4,
.pbl-4,
.pbr-4,
.py-4 {
	padding-bottom: ($spacer-y*1.5) !important;
}

.pbl-4,
.pl-4,
.ptl-4,
.px-4 {
	padding-left: ($spacer-x*1.5) !important;
}

.p-5 {
	padding: ($spacer-y*3) ($spacer-x*3) !important;
}

.pt-5,
.ptl-5,
.ptr-5,
.py-5 {
	padding-top: ($spacer-y*3) !important;
}

.pbr-5,
.pr-5,
.ptr-5,
.px-5 {
	padding-right: ($spacer-x*3) !important;
}

.pb-5,
.pbl-5,
.pbr-5,
.py-5 {
	padding-bottom: ($spacer-y*3) !important;
}

.pbl-5,
.pl-5,
.ptl-5,
.px-5 {
	padding-left: ($spacer-x*3) !important;
}

.p-0-xs {
	padding: 0 !important;
}

.pt-0-xs,
.ptl-0-xs,
.ptr-0-xs,
.py-0-xs {
	padding-top: 0 !important;
}

.pbr-0-xs,
.pr-0-xs,
.ptr-0-xs,
.px-0-xs {
	padding-right: 0 !important;
}

.pb-0-xs,
.pbl-0-xs,
.pbr-0-xs,
.py-0-xs {
	padding-bottom: 0 !important;
}

.pbl-0-xs,
.pl-0-xs,
.ptl-0-xs,
.px-0-xs {
	padding-left: 0 !important;
}

.p-1-xs {
	padding: ($spacer-y*0.25) ($spacer-x*0.25) !important;
}

.pt-1-xs,
.ptl-1-xs,
.ptr-1-xs,
.py-1-xs {
	padding-top: ($spacer-y*0.25) !important;
}

.pbr-1-xs,
.pr-1-xs,
.ptr-1-xs,
.px-1-xs {
	padding-right: ($spacer-x*0.25) !important;
}

.pb-1-xs,
.pbl-1-xs,
.pbr-1-xs,
.py-1-xs {
	padding-bottom: ($spacer-y*0.25) !important;
}

.pbl-1-xs,
.pl-1-xs,
.ptl-1-xs,
.px-1-xs {
	padding-left: ($spacer-x*0.25) !important;
}

.p-2-xs {
	padding: ($spacer-y*0.5) ($spacer-x*0.5) !important;
}

.pt-2-xs,
.ptl-2-xs,
.ptr-2-xs,
.py-2-xs {
	padding-top: ($spacer-y*0.5) !important;
}

.pbr-2-xs,
.pr-2-xs,
.ptr-2-xs,
.px-2-xs {
	padding-right: ($spacer-x*0.5) !important;
}

.pb-2-xs,
.pbl-2-xs,
.pbr-2-xs,
.py-2-xs {
	padding-bottom: ($spacer-y*0.5) !important;
}

.pbl-2-xs,
.pl-2-xs,
.ptl-2-xs,
.px-2-xs {
	padding-left: ($spacer-x*0.5) !important;
}

.p-3-xs {
	padding: $spacer-y $spacer-x !important;
}

.pt-3-xs,
.ptl-3-xs,
.ptr-3-xs,
.py-3-xs {
	padding-top: $spacer-y !important;
}

.pbr-3-xs,
.pr-3-xs,
.ptr-3-xs,
.px-3-xs {
	padding-right: $spacer-x !important;
}

.pb-3-xs,
.pbl-3-xs,
.pbr-3-xs,
.py-3-xs {
	padding-bottom: $spacer-y !important;
}

.pbl-3-xs,
.pl-3-xs,
.ptl-3-xs,
.px-3-xs {
	padding-left: $spacer-x !important;
}

.p-4-xs {
	padding: ($spacer-y*1.5) ($spacer-x*1.5) !important;
}

.pt-4-xs,
.ptl-4-xs,
.ptr-4-xs,
.py-4-xs {
	padding-top: ($spacer-y*1.5) !important;
}

.pbr-4-xs,
.pr-4-xs,
.ptr-4-xs,
.px-4-xs {
	padding-right: ($spacer-x*1.5) !important;
}

.pb-4-xs,
.pbl-4-xs,
.pbr-4-xs,
.py-4-xs {
	padding-bottom: ($spacer-y*1.5) !important;
}

.pbl-4-xs,
.pl-4-xs,
.ptl-4-xs,
.px-4-xs {
	padding-left: ($spacer-x*1.5) !important;
}

.p-5-xs {
	padding: ($spacer-y*3) ($spacer-x*3) !important;
}

.pt-5-xs,
.ptl-5-xs,
.ptr-5-xs,
.py-5-xs {
	padding-top: ($spacer-y*3) !important;
}

.pbr-5-xs,
.pr-5-xs,
.ptr-5-xs,
.px-5-xs {
	padding-right: ($spacer-x*3) !important;
}

.pb-5-xs,
.pbl-5-xs,
.pbr-5-xs,
.py-5-xs {
	padding-bottom: ($spacer-y*3) !important;
}

.pbl-5-xs,
.pl-5-xs,
.ptl-5-xs,
.px-5-xs {
	padding-left: ($spacer-x*3) !important;
}

@media(min-width: #{$screen-sm-min}) {

	.p-0-sm {
		padding: 0 !important;
	}

	.pt-0-sm,
	.ptl-0-sm,
	.ptr-0-sm,
	.py-0-sm {
		padding-top: 0 !important;
	}

	.pbr-0-sm,
	.pr-0-sm,
	.ptr-0-sm,
	.px-0-sm {
		padding-right: 0 !important;
	}

	.pb-0-sm,
	.pbl-0-sm,
	.pbr-0-sm,
	.py-0-sm {
		padding-bottom: 0 !important;
	}

	.pbl-0-sm,
	.pl-0-sm,
	.ptl-0-sm,
	.px-0-sm {
		padding-left: 0 !important;
	}

	.p-1-sm {
		padding: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.pt-1-sm,
	.ptl-1-sm,
	.ptr-1-sm,
	.py-1-sm {
		padding-top: ($spacer-y*0.25) !important;
	}

	.pbr-1-sm,
	.pr-1-sm,
	.ptr-1-sm,
	.px-1-sm {
		padding-right: ($spacer-x*0.25) !important;
	}

	.pb-1-sm,
	.pbl-1-sm,
	.pbr-1-sm,
	.py-1-sm {
		padding-bottom: ($spacer-y*0.25) !important;
	}

	.pbl-1-sm,
	.pl-1-sm,
	.ptl-1-sm,
	.px-1-sm {
		padding-left: ($spacer-x*0.25) !important;
	}

	.p-2-sm {
		padding: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.pt-2-sm,
	.ptl-2-sm,
	.ptr-2-sm,
	.py-2-sm {
		padding-top: ($spacer-y*0.5) !important;
	}

	.pbr-2-sm,
	.pr-2-sm,
	.ptr-2-sm,
	.px-2-sm {
		padding-right: ($spacer-x*0.5) !important;
	}

	.pb-2-sm,
	.pbl-2-sm,
	.pbr-2-sm,
	.py-2-sm {
		padding-bottom: ($spacer-y*0.5) !important;
	}

	.pbl-2-sm,
	.pl-2-sm,
	.ptl-2-sm,
	.px-2-sm {
		padding-left: ($spacer-x*0.5) !important;
	}

	.p-3-sm {
		padding: $spacer-y $spacer-x !important;
	}

	.pt-3-sm,
	.ptl-3-sm,
	.ptr-3-sm,
	.py-3-sm {
		padding-top: $spacer-y !important;
	}

	.pbr-3-sm,
	.pr-3-sm,
	.ptr-3-sm,
	.px-3-sm {
		padding-right: $spacer-x !important;
	}

	.pb-3-sm,
	.pbl-3-sm,
	.pbr-3-sm,
	.py-3-sm {
		padding-bottom: $spacer-y !important;
	}

	.pbl-3-sm,
	.pl-3-sm,
	.ptl-3-sm,
	.px-3-sm {
		padding-left: $spacer-x !important;
	}

	.p-4-sm {
		padding: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.pt-4-sm,
	.ptl-4-sm,
	.ptr-4-sm,
	.py-4-sm {
		padding-top: ($spacer-y*1.5) !important;
	}

	.pbr-4-sm,
	.pr-4-sm,
	.ptr-4-sm,
	.px-4-sm {
		padding-right: ($spacer-x*1.5) !important;
	}

	.pb-4-sm,
	.pbl-4-sm,
	.pbr-4-sm,
	.py-4-sm {
		padding-bottom: ($spacer-y*1.5) !important;
	}

	.pbl-4-sm,
	.pl-4-sm,
	.ptl-4-sm,
	.px-4-sm {
		padding-left: ($spacer-x*1.5) !important;
	}

	.p-5-sm {
		padding: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.pt-5-sm,
	.ptl-5-sm,
	.ptr-5-sm,
	.py-5-sm {
		padding-top: ($spacer-y*3) !important;
	}

	.pbr-5-sm,
	.pr-5-sm,
	.ptr-5-sm,
	.px-5-sm {
		padding-right: ($spacer-x*3) !important;
	}

	.pb-5-sm,
	.pbl-5-sm,
	.pbr-5-sm,
	.py-5-sm {
		padding-bottom: ($spacer-y*3) !important;
	}

	.pbl-5-sm,
	.pl-5-sm,
	.ptl-5-sm,
	.px-5-sm {
		padding-left: ($spacer-x*3) !important;
	}
}
@media(min-width: #{$screen-md-min}) {

	.p-0-md {
		padding: 0 !important;
	}

	.pt-0-md,
	.ptl-0-md,
	.ptr-0-md,
	.py-0-md {
		padding-top: 0 !important;
	}

	.pbr-0-md,
	.pr-0-md,
	.ptr-0-md,
	.px-0-md {
		padding-right: 0 !important;
	}

	.pb-0-md,
	.pbl-0-md,
	.pbr-0-md,
	.py-0-md {
		padding-bottom: 0 !important;
	}

	.pbl-0-md,
	.pl-0-md,
	.ptl-0-md,
	.px-0-md {
		padding-left: 0 !important;
	}

	.p-1-md {
		padding: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.pt-1-md,
	.ptl-1-md,
	.ptr-1-md,
	.py-1-md {
		padding-top: ($spacer-y*0.25) !important;
	}

	.pbr-1-md,
	.pr-1-md,
	.ptr-1-md,
	.px-1-md {
		padding-right: ($spacer-x*0.25) !important;
	}

	.pb-1-md,
	.pbl-1-md,
	.pbr-1-md,
	.py-1-md {
		padding-bottom: ($spacer-y*0.25) !important;
	}

	.pbl-1-md,
	.pl-1-md,
	.ptl-1-md,
	.px-1-md {
		padding-left: ($spacer-x*0.25) !important;
	}

	.p-2-md {
		padding: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.pt-2-md,
	.ptl-2-md,
	.ptr-2-md,
	.py-2-md {
		padding-top: ($spacer-y*0.5) !important;
	}

	.pbr-2-md,
	.pr-2-md,
	.ptr-2-md,
	.px-2-md {
		padding-right: ($spacer-x*0.5) !important;
	}

	.pb-2-md,
	.pbl-2-md,
	.pbr-2-md,
	.py-2-md {
		padding-bottom: ($spacer-y*0.5) !important;
	}

	.pbl-2-md,
	.pl-2-md,
	.ptl-2-md,
	.px-2-md {
		padding-left: ($spacer-x*0.5) !important;
	}

	.p-3-md {
		padding: $spacer-y $spacer-x !important;
	}

	.pt-3-md,
	.ptl-3-md,
	.ptr-3-md,
	.py-3-md {
		padding-top: $spacer-y !important;
	}

	.pbr-3-md,
	.pr-3-md,
	.ptr-3-md,
	.px-3-md {
		padding-right: $spacer-x !important;
	}

	.pb-3-md,
	.pbl-3-md,
	.pbr-3-md,
	.py-3-md {
		padding-bottom: $spacer-y !important;
	}

	.pbl-3-md,
	.pl-3-md,
	.ptl-3-md,
	.px-3-md {
		padding-left: $spacer-x !important;
	}

	.p-4-md {
		padding: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.pt-4-md,
	.ptl-4-md,
	.ptr-4-md,
	.py-4-md {
		padding-top: ($spacer-y*1.5) !important;
	}

	.pbr-4-md,
	.pr-4-md,
	.ptr-4-md,
	.px-4-md {
		padding-right: ($spacer-x*1.5) !important;
	}

	.pb-4-md,
	.pbl-4-md,
	.pbr-4-md,
	.py-4-md {
		padding-bottom: ($spacer-y*1.5) !important;
	}

	.pbl-4-md,
	.pl-4-md,
	.ptl-4-md,
	.px-4-md {
		padding-left: ($spacer-x*1.5) !important;
	}

	.p-5-md {
		padding: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.pt-5-md,
	.ptl-5-md,
	.ptr-5-md,
	.py-5-md {
		padding-top: ($spacer-y*3) !important;
	}

	.pbr-5-md,
	.pr-5-md,
	.ptr-5-md,
	.px-5-md {
		padding-right: ($spacer-x*3) !important;
	}

	.pb-5-md,
	.pbl-5-md,
	.pbr-5-md,
	.py-5-md {
		padding-bottom: ($spacer-y*3) !important;
	}

	.pbl-5-md,
	.pl-5-md,
	.ptl-5-md,
	.px-5-md {
		padding-left: ($spacer-x*3) !important;
	}
}
@media(min-width: #{$screen-lg-min}) {
	.p-0-lg {
		padding: 0 !important;
	}

	.pt-0-lg,
	.ptl-0-lg,
	.ptr-0-lg,
	.py-0-lg {
		padding-top: 0 !important;
	}

	.pbr-0-lg,
	.pr-0-lg,
	.ptr-0-lg,
	.px-0-lg {
		padding-right: 0 !important;
	}

	.pb-0-lg,
	.pbl-0-lg,
	.pbr-0-lg,
	.py-0-lg {
		padding-bottom: 0 !important;
	}

	.pbl-0-lg,
	.pl-0-lg,
	.ptl-0-lg,
	.px-0-lg {
		padding-left: 0 !important;
	}

	.p-1-lg {
		padding: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.pt-1-lg,
	.ptl-1-lg,
	.ptr-1-lg,
	.py-1-lg {
		padding-top: ($spacer-y*0.25) !important;
	}

	.pbr-1-lg,
	.pr-1-lg,
	.ptr-1-lg,
	.px-1-lg {
		padding-right: ($spacer-x*0.25) !important;
	}

	.pb-1-lg,
	.pbl-1-lg,
	.pbr-1-lg,
	.py-1-lg {
		padding-bottom: ($spacer-y*0.25) !important;
	}

	.pbl-1-lg,
	.pl-1-lg,
	.ptl-1-lg,
	.px-1-lg {
		padding-left: ($spacer-x*0.25) !important;
	}

	.p-2-lg {
		padding: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.pt-2-lg,
	.ptl-2-lg,
	.ptr-2-lg,
	.py-2-lg {
		padding-top: ($spacer-y*0.5) !important;
	}

	.pbr-2-lg,
	.pr-2-lg,
	.ptr-2-lg,
	.px-2-lg {
		padding-right: ($spacer-x*0.5) !important;
	}

	.pb-2-lg,
	.pbl-2-lg,
	.pbr-2-lg,
	.py-2-lg {
		padding-bottom: ($spacer-y*0.5) !important;
	}

	.pbl-2-lg,
	.pl-2-lg,
	.ptl-2-lg,
	.px-2-lg {
		padding-left: ($spacer-x*0.5) !important;
	}

	.p-3-lg {
		padding: $spacer-y $spacer-x !important;
	}

	.pt-3-lg,
	.ptl-3-lg,
	.ptr-3-lg,
	.py-3-lg {
		padding-top: $spacer-y !important;
	}

	.pbr-3-lg,
	.pr-3-lg,
	.ptr-3-lg,
	.px-3-lg {
		padding-right: $spacer-x !important;
	}

	.pb-3-lg,
	.pbl-3-lg,
	.pbr-3-lg,
	.py-3-lg {
		padding-bottom: $spacer-y !important;
	}

	.pbl-3-lg,
	.pl-3-lg,
	.ptl-3-lg,
	.px-3-lg {
		padding-left: $spacer-x !important;
	}

	.p-4-lg {
		padding: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.pt-4-lg,
	.ptl-4-lg,
	.ptr-4-lg,
	.py-4-lg {
		padding-top: ($spacer-y*1.5) !important;
	}

	.pbr-4-lg,
	.pr-4-lg,
	.ptr-4-lg,
	.px-4-lg {
		padding-right: ($spacer-x*1.5) !important;
	}

	.pb-4-lg,
	.pbl-4-lg,
	.pbr-4-lg,
	.py-4-lg {
		padding-bottom: ($spacer-y*1.5) !important;
	}

	.pbl-4-lg,
	.pl-4-lg,
	.ptl-4-lg,
	.px-4-lg {
		padding-left: ($spacer-x*1.5) !important;
	}

	.p-5-lg {
		padding: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.pt-5-lg,
	.ptl-5-lg,
	.ptr-5-lg,
	.py-5-lg {
		padding-top: ($spacer-y*3) !important;
	}

	.pbr-5-lg,
	.pr-5-lg,
	.ptr-5-lg,
	.px-5-lg {
		padding-right: ($spacer-x*3) !important;
	}

	.pb-5-lg,
	.pbl-5-lg,
	.pbr-5-lg,
	.py-5-lg {
		padding-bottom: ($spacer-y*3) !important;
	}

	.pbl-5-lg,
	.pl-5-lg,
	.ptl-5-lg,
	.px-5-lg {
		padding-left: ($spacer-x*3) !important;
	}
}