.counter {
	background-color: $lightest;
	color: $brand-primary;
	font-weight: $font-weight-semibold;
	border-radius: 50rem;
	height: 1.5em;
	min-width: 1.5em;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: $font-size-small;
	line-height: $font-size-small;
	padding: 0 6px;
}
