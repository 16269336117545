@import "overrides/buttons.scss";

hr {
	margin-bottom: 8px;
	margin-top: 8px;
}

@media screen and (max-width: $screen-sm-max) {
	.container {
		padding-left: 0;
		padding-right: 0;
		> .row {
			margin-left: 0;
			margin-right: 0;
		}
	}
}
