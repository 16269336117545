// Variables

.christmas {
	.member-widget {
		background-color: #1a1a1d !important;
	}
	.member-widget-dropdown {
		background-color: #1a1a1d !important;

		@media screen and (max-width: $navbar-min-width) {
			background-color: transparent !important;
		}
	}
}

.member-logged-in-text, .member-widget-options {
	a {
		color: $lightest;

		&:hover {
			color: $lightest;
			text-decoration: underline;
		}
	}
}

.arrow-wrap {
	position: relative;
	z-index: 102;

	.member-widget-dropdown-arrow:after {
		background-color: $lightest;
	}
}


/* General member-form styling */

.member-form {
	h2 {
		margin: 0;
	}

	.login-form {
		max-width: 325px;
		margin: 0 auto;
	}

	.login-footer {
		max-width: 300px;
		margin: -10px auto 0 auto;
		display: flex;
		justify-content: space-between;
	}

	.register-footer {
		margin-top: -10px;
		text-align: center;
	}

	.already-member {
		margin: 22px auto -2px auto;
		max-width: 300px;
		text-align: center;
	}
}

.toggle-label.button-dropdown.member-widget {
	height: 100%;
	color: $lightest;
	display: flex;
	align-items: center;

	.member-logged-in-text {
		display: none;
		line-height: 1.2;
		max-width: 150px;
		padding: 0;

		.logged-in-as {
			display: block;
			font-size: .6em;
		}

		.username {
			font-weight: $font-weight-semibold;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-bottom: 4px;
		}

		.member-level-text {
			.glyphicon {
				line-height: 0;
			}

			line-height: $line-height-small;
			font-size: $font-size-small;
		}

		.user-links {
			font-size: $font-size-small;
			display: flex;

			.admin {
				margin-left: 4px;
			}
		}

		@media screen and (min-width: $navbar-min-width) {
			display: block;
		}
	}
	.down-arrow {
		display: none;
		height: 18px;
		margin: 0 6px;

		@media screen and (min-width: $navbar-min-width) {
			display: flex;
		}
	}

	.member-dropdown-button {
		line-height: 0;
		display: block;
	}

	.profile-picture-wrap {
		line-height: 0;
		position: relative;

		.profile-picture {
			width: $headerHeight-xs;
			height: $headerHeight-xs;
			margin: 0;
			display: flex;

			@media screen and (min-width: $screen-sm-min) {
				width: $headerHeight-sm;
				height: $headerHeight-sm;
			}
			@media screen and (min-width: $screen-md-min) {
				width: $headerHeight-md;
				height: $headerHeight-md;
			}
			@media screen and (min-width: $navbar-min-width) {
				width: $headerHeight-lg;
				height: $headerHeight-lg;
			}

			@media screen and (min-width: $navbar-min-width) {
				margin: 0 $headerHeight-lg * .125;
			}
		}

		.avatar-notification {
			padding: 2px;
			border-radius: 50%;
			background-color: $destructive;
			@include icon-center-placement("/api/dynimg/icon/handelser/FFFFFF");
			position: absolute;
			bottom: 7px;
			border: 1px solid $lightest;
			z-index: 1;
			left: -4px;
			width: 16px;
			height: 16px;

			@media screen and (min-width: $navbar-min-width) {
				left: 2px;
				width: 18px;
				height: 18px;
			}
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		align-items: flex-start;
	}
}

#main-header .member-widget-dropdown {
	$listItemHeight: 26px;
	position: static;
	height: 100%;

	.member-widget-options {
		display: none;
		padding-right: 12px;

		@media screen and (min-width: $navbar-min-width) {
			display: block;
		}
	}

	.cover-view {
		background-color: rgba(0,0,0,.4);
	}

	.ximg-content {
		height: 42px;
		width: auto;
	}

	.header-dropdown {
		border: none;
		background-color: $lightest;
		line-height: 1.3;
		overflow: hidden;
		transition: max-height .8s ease, max-width .5s ease, transform .2s ease;
		max-height: 1000px; // max-height needed for transition
		transform: translateX(0);

		&.member-form {
			padding: 12px 12px;
			z-index: 102;

			@keyframes rollDown {
				0% {
					max-height: 0;
					padding: 0 12px;
				}
				100% {
					max-height: 150px;
				}
			}

			&.mini-prompt {
				max-width: 180px;
				max-height: 150px;
				transition: max-height .8s ease, max-width .5s ease, transform .2s ease;
				animation: rollDown 1s ease;
				box-shadow: $box-shadow;
				text-align: center;
				transform: translateX(-32%);

				@media screen and (min-width: $navbar-min-width) {
					&.cart-items {
						transform: translateX(-86%);
					}
				}

				.text-btn {
					margin-bottom: 10px;
				}
			}

			.alert.alert-danger {
				margin: 22px 0px -2px 0px;
				max-width: 300px;
			}
		}

		.user-exp-text span {
			color: $gray-two;
		}

		.progress-level {
			background-color: $gray-three;
		}

		.list-settings {
			padding: 0;
		}

		.popup-flex {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			height: 100%;
		}

		.pop-top img {
			width: 7%;
			height: 12px;
			vertical-align: text-bottom;
			margin-left: 0.5em;


		}

		.pop-cart {
			padding-bottom: 10px;
		 		img {
					width: 9%;
					height: 12px;
					vertical-align: text-bottom;
					margin-left: 0.5em;
				}
		}
	}

	.tile {
		@media screen and (min-width: $screen-lg-min) {
			height: 30px;
		}
		.member-item-counter {
			height: 100%;
		}
		.member-item-counter img {
			height: 1.4em;
			vertical-align: text-bottom;
			margin-left: 0.5em;
		}
		a {
			display: block;
			width: 100%;
			height: 100%;
			padding: 0 10px;
			display: flex;
			align-items: center;
			@media screen and (min-width: $screen-lg-min) {
				padding: 0 8px;
			}
			&:hover, &:focus, &:active {
				text-decoration: none;
			}
		}
	}

	.notification-wrapper {
		color: $text-color;
		margin-top: 14px;

		.notification-card {
			height: 65px;
			padding: 12px;
			display: flex;
			justify-content: flex-start;
			cursor: pointer;

			.notification-img {
				height: 25px;
				width: 25px;
				margin-right: 8px;
				border-radius: 100%;
			}

			.notification-description {
				font-weight: bold;
			}

			.right-column {
				margin-left: auto;
				margin-top: 4px;
				display: flex;
				flex-direction: column;
			}

			.notification-dot {
				height: 6px;
				width: 6px;
				border-radius: 50%;
				background-color: $destructive;
				margin-top: 15px;
				margin-left: auto;
			}

			.hidden {
				display: none;
			}

			.card-title {
				white-space: break-spaces;
				word-wrap: break-word;
			}
		}

		a._center {
			display: flex;
			justify-content: center;
		}
	}
}

.member-list-item-icon {
	height: 16px;
	width: 16px;
	margin-right: 8px;
}

.add-to-wislisht-member-form {
	width: 500px;
	max-width: 100%;

	h2 {
		font-size: $level-one-heading;
		width: 100%;
		text-align: center;
	}
}
