.insurance-selector-panel {
	display: inline-flex;
	justify-content: flex-start;
	border: $border-width solid transparent;
	box-sizing: border-box;
	margin-bottom: 6px;
	border-radius: $border-radius;
	padding: 0;
	background-color: $gray-four;
	user-select: none;
	transition: background-color 0.2s ease-out;
	font-size: $font-size-small;
	cursor: pointer;

	.checkbox-wrap {
		margin: 0;

		.checkbox {
			margin: 6px 8px;
		}

		.checkbox-label {
			@include icon-left-placement('/img/icons/carepack_shield_green.svg', 20px);
			padding: 6px 8px;
			border-left: $border-width solid $lightest;
			display: flex;
			align-items: center;

			&::before {
				margin-right: 4px;
				width: 18px;
			}

			.insurance-price {
				font-weight: $font-weight-semibold;
				min-width: fit-content;
			}
		}
	}

	.info {
		color: $darkest;
		display: flex;
		align-items: center;
		margin: 0 8px;
		width: 12px;

		.price {
			font-weight: bold;
		}
	}

	&.selected {
		border: $border-width solid $brand-primary;
	}

	&._compact {
		.checkbox {
			margin: 2px 8px;
		}

		.checkbox-label {
			padding: 2px 8px;
		}
	}
}

.insured-order-row {
	display: inline-block;
	background-color: $brand-primary;
	color: $lightest;
	white-space: normal;

	.info > .ximg {
		height: 20px;
		width: 20px;
		vertical-align: sub;
	}
}

.insurance-info {
	padding: 4px;

	.ximg {
		width: 100%;
		height: auto;
		aspect-ratio: 760 / 247;

		.ximg-content {
			border-radius: $border-radius;
		}
	}

	* {
		margin: 6px 0;
	}

	ul {
		margin: 1em 0;
	}

	.fine-print {
		color: $text-secondary-color;
		font-size: $font-size-small;
		font-style: italic;
	}

	.add-insurance {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.text-btn {
			flex: 1 1 50%;
			margin: 4px auto;
		}

		@media screen and (min-width: $screen-md-min) {
			flex-direction: row;
			gap: 8px;
		}
	}

	&.insurance-popup {
		& > .ximg {
			max-width: 414px;
			width: 100%;
			aspect-ratio: 40 / 13;

			&.para {
				max-width: none;
				aspect-ratio: 1200 / 160;
			}
		}

		.insurance-popup-price {
			display: flex;
			align-items: center;
			margin-top: 12px;
			gap: 4px;

			* {
				margin: 0;
			}

			.ximg {
				width: 20px;
				height: 20px;
			}
		}
	}
}
