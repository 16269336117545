.flash-sale {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	color: $destructive;
	margin: -35px auto 10px;
	width: 80%;

	.end-time .time-number {
		&._days {
			display: none;
		}

		&._seconds {
			display: inline-block;
			width: 27px;
			text-align: left;
		}
	}

	&.compact {
		justify-content: flex-end;
	}

	&.sale-over {
		opacity: 0.5;

		.product-amount {
			font-weight: $font-weight-semibold;
			color: $darkest;
		}
	}

	/* Used when flash sale attribute is true */
	&.sales-progress {
		justify-content: space-between;

		.progress {
			flex: 1 1 100%;
			border-radius: 100px;
			height: 10px;
			margin: 4px 0;

			.progress-bar {
				border-radius: 100px;
				background-color: $destructive;
				height: 100%;
			}

			.progress-text {
				display: none;
			}
		}

		.product-amount {
			color: $darkest;
			font-weight: $font-weight-semibold;
		}

		.end-time .time-number {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			line-height: 0;
			color: $lightest;
			background-color: $destructive;
			font-weight: $font-weight-semibold;
			border-radius: $border-radius;
			margin: 0 1px;
			width: 20px;
			height: 20px;

			&._days {
				display: none;
			}

			.time-prefix {
				display: none;
			}
		}
	}

	/* Used for product lists */
	&.compact {
		margin: 0px auto 10px;
		padding: 0 6px;
		width: 100%;
		font-size: $font-size-small;
	}

	/* Non released products with overbook protection */
	&.pre-book {
		color: $gray-two;
	}
}

/* Flash sales banner for campaign sliders */
.flash-sales-banner {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	background-color: $gray-one;
	border-radius: $border-radius;
	margin: 16px 0 44px;

	.ximg {
		padding-bottom: 20px;
	}
}