.splash {
	position: absolute;
	display: flex;
	gap: 4px;
	flex-direction: column;
	z-index: 1;
	left: 12px;
	top: 4px;

	.single-splash {
		.ximg {
			width: 120px;
			height: 90px;

			.ximg-content {
				object-position: left;
			}
		}

		&.small-splash {
			background-color: $darkest;
			color: $lightest;
			border-radius: 50em;
			height: 34px;
			font-size: $font-size-base;
			display: inline-flex;
			align-items: center;
			gap: 4px;
			padding: 0 12px 0 4px;
			line-height: .5;

			.ximg {
				height: 26px;
				width: 26px;

				.ximg-content {
					border-radius: 50em;
				}
			}
		}
	}
}

.product-grid-item .splash {
	gap: 2px;
	left: 6px;
	top: 4px;

	.single-splash {
		.ximg {
			width: 60px;
			height: 50px;
			padding: 0;
			margin: 0;
		}

		&.small-splash {
			height: 20px;
			font-size: $font-size-tiny;
			padding: 0 8px 0 2px;

			.ximg {
				height: 16px;
				width: 16px;
			}
		}
	}

}

.product-list-item .splash {
	top: 4px;
	left: 0px;
	right: 12px;
	flex-direction: row;
	width: 140%;

	.single-splash {
		.ximg {
			width: 32px;
			height: 24px;
		}

		&.small-splash {
			height: 18px;
			font-size: $font-size-tiny;
			padding: 0 8px;
			width: max-content;

			.ximg {
				display: none;
			}

			&.sponsored-splash {
				color: $darkest;
				background-color: $lightest;
				border: 1px solid $gray-three;
			}
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		top: -2px;
		left: 0px;
		width: 120%;
	}
}

.product-list-compact-item .splash {
	left: 0px;
	top: -1px;
	flex-direction: row;
	width: 140%;
	.single-splash {
		.ximg {
			width: 32px;
			height: 24px;
		}

		&.small-splash {
			font-size: $font-size-tiny;
			padding: 0 6px;
			height: 13px;
			width: max-content;

			.ximg {
				display: none;
			}

			&.sponsored-splash {
				color: $darkest;
				background-color: $lightest;
				border: 1px solid $gray-three;
			}
		}
	}
	@media screen and (max-width: $screen-sm-max) {
		width: 120%;
	}
}
