#alert-bar {
	position: fixed;
	bottom: 40%;
	left: 0;
	right: 0;
	// Tired joke.
	z-index: 9999999;
	text-align: center;
	pointer-events: none;

	.toast-alert {
		z-index: 10001;
		cursor: default;
		user-select: none;
		margin-top: 4px;
		pointer-events: auto;
		text-shadow: 0px 0px 3px black;
		color: white;
		background: rgba(15, 16, 17, .85);
		border-radius: 999px;
		padding: 6px 16px;
		min-width: 256px;
		display: inline-block;
		box-shadow: 0px 2px 8px rgba(0,0,0,.4);
		font-size: $level-three-heading;
		transition: all .2s ease-out;
		max-height: 512px;

		&.vanished {
			max-height: 0;
			min-width: 0;
			padding: 0;
			margin-top: 0;
			font-size: 0;
			opacity: 0;
		}
	}
}
