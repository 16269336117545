.quick-search-list {
	.price-value:not(._regular)._below {
		padding-bottom: 2px;
	}
}

.quick-search-list-item-categories {
	font-size: $font-size-small;
	color: $text-secondary-color;

	a {
		color: $text-secondary-color;
	}
}

.quick-search-results-panel {
	min-width: 120%;
	max-width: 100vw;
	position: absolute;
	left: 0;
	display: flex;
}

.quick-search-results-products {
	float: left; width: 70%
}

.quick-search-results-products-slim {
	min-width: 100%;
	max-width: 100vw;
}

.quick-search-event-item, .quick-search-page-item {
	height: 52px;
	border-bottom: 1px solid $gray-three;
	display: flex;
	align-items: center;
	text-align: center;
	margin: 0 8px;

	.page-link {
		flex: 1;
		font-weight: $font-weight-semibold;
		display: flex;
		justify-content: center;
		align-items: center;

		.page-icon {
			margin-right: 7px;
			width: 16px;
			height: 16px;
		}
	}
}

.quick-search-event-item .page-link {
	color: $destructive;
}

.quick-search-page-item .page-link {
	color: $brand-primary;
}

.quick-search-results-panel-slim {
	min-width: 100%;
	max-width: 100vw;
	position: absolute;
	right: 0;
	left: 0;
	display: flex;

	.quick-search-list-item {
		height: 52px;

		&.panel, &.phrase-item .panel {
			box-shadow: none;
			border-bottom: 1px solid $gray-three;
		}

		&.phrase-item {
			height: inherit;
			a {
				font-weight: $font-weight-bold;
				span {
					font-weight: normal;
				}
			}
		}

		.panel-body {
			display: flex;
			padding: 4px;
			align-items: center;
			height: 100%;

			.product-link {
				margin-top: -4px;
				padding: 4px;
				width: 30%;
				max-width: 50px;
				min-width: 50px;
				line-height: 0;

				.ximg {
					margin-top: 2px;
					width: 100%;
					height: 48px;
				}
			}

			.quick-search-product-info {
				padding: 0 4px;
				position: relative;
				width: 100%;
				white-space: normal;
				line-height: 1.4;

				&.has-energy-marking {
					align-self: flex-end;

					.product-energy-marking {
						position: absolute;
						top: -12px;
						color: #a2aab8;
						font-size: $font-size-small;
						line-height: 1;
					}
				}
			}

			.quick-search-price {
				top: -6px;
				position: relative;
				padding: 0 4px 1px;
				text-align: right;

				.price-value:not(._regular):not(._below) {
					margin-top: 0;
				}
			}
		}
	}

	@media screen and (min-width: $screen-md-min) {
		width: 70vw;
		right: 0;
		left: 50%;
		transform: translateX(-50%);

		.quick-search-list-item {
			height: 56px;

			.panel-body {
				.product-link .ximg {
					height: 52px;
				}

				.quick-search-price {
					top: -4px;
				}
			}
		}
	}

	@media screen and (min-width: $navbar-min-width) {
		width: auto;
		right: 0;
		left: 0;
		margin-left: 0;
		transform: none;

		.quick-search-list-item {
			height: 66px;

			.panel-body {
				.quick-search-product-info.has-energy-marking {
						align-self: center;
						margin-top: 4px;

						.product-energy-marking {
							top: -14px;
						}
					}

				.quick-search-price {
					top: -6px;
				}
			}
		}
	}
}

.quick-search-results-categories {
	float: right;
	width: 30%;
	background: #f3f7fb;
	padding-bottom: 100%;
	align-items: stretch;
}

#search-selector {
	position: absolute;
	display: flex;
	justify-content: flex-end;
	right: 50px;
	z-index: 99;
	padding-top: 6px;

	#search-mode-text {
		display: flex;
		flex-direction:column;
		margin-right: 5px;
		line-height: 1.5;
		font-weight: $font-weight-semibold;
		color: $text-secondary-color;
		font-size: $font-size-small;

		span {
			cursor: pointer;
			text-align: right;
		}

		.active-search-mode {
			color: $brand-primary;
		}

		.active-search-mode:hover {
			color: $brand-primary;
			cursor: default;
		}
	}

	#search-mode-buttons {
		display: flex;
		flex-direction:column;

		.toggle-btn {
			height: 18px;
			margin: 0;


			&:first-child {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;
			}

			&:last-child {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}

			span {
				display: flex;
				font-size: $font-size-small;
				top: -1px;
			}
		}
	}
}

@media screen and (max-width: $navbar-min-width) {
	.quick-search-list-item {
		.ximg {
			height: 34px;
		}
		.quick-search-list-item-title {
			padding-top: 0;
		}

		.quick-search-list-item-categories {
			padding-top: 0;
			font-size: $font-size-small;
		}
	}

	.quick-search-results-panel {
		min-width: 100%;
		max-width: 100vw;
		display: inline;
	}
	.quick-search-results-products {
		width: 100%;
	}
	.quick-search-results-categories {
		display: none;
	}
}
