@include screen-size-class('border-<SIZE>', 1){
   border: 1px solid $hr-border !important;
}
@include screen-size-class('border-top-<SIZE>', 1) {
   border-top: 1px solid $hr-border !important;
}
@include screen-size-class('border-right-<SIZE>', 1) {
   border-right: 1px solid $hr-border !important;
}
@include screen-size-class('border-bottom-<SIZE>', 1) {
   border-bottom: 1px solid $hr-border !important;
}
@include screen-size-class('border-left-<SIZE>', 1) {
   border-left: 1px solid $hr-border !important;
}
