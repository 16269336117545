.ifs-table-true {
	vertical-align: middle !important;
	text-align: center;
	color: rgb(0, 255, 0);
}
.ifs-table-false {
	vertical-align: middle !important;
	text-align: center;
	color: red;
}
.ifs-table-categories {
	padding-left: 0;
	list-style-type: none;
}
.ifs-table-provider {
	vertical-align: middle !important;
}
.ifs-table-row {
	.shipping-class {
		white-space: nowrap;
			vertical-align: middle;
	}
}

.ifs-different-row {
	border-bottom: 1px double black;
}