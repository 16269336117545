.hover-saver {
	$saveBorderSize: 30px;
	$saveBorderTime: 1s;
	$saveBorderMode: ease-out;
	position: absolute;
	//background-color: rgba(0,128,0,0.3);
	&._careful {
		display: none;
	}

	&._bottom,
	&._top {
		left: -$saveBorderSize;
		right: -$saveBorderSize;
		height: $saveBorderSize;

		&._top {
			top: -$saveBorderSize;

			&:hover {
				transition: height $saveBorderTime $saveBorderMode, top $saveBorderTime $saveBorderMode, right $saveBorderTime $saveBorderMode, left $saveBorderTime $saveBorderMode;
				left: 0;
				right: 0;
				height: 0;
				top: 0;
			}
		}

		&._bottom {
			bottom: -$saveBorderSize;

			&:hover {
				transition: height $saveBorderTime $saveBorderMode, bottom $saveBorderTime $saveBorderMode, right $saveBorderTime $saveBorderMode, left $saveBorderTime $saveBorderMode;
				left: 0;
				right: 0;
				height: 0;
				bottom: 0;
			}
		}
	}

	&._left,
	&._right {
		top: 0;
		bottom: 0;
		width: $saveBorderSize;

		&._left {
			left: -$saveBorderSize;

			&:hover {
				transition: width $saveBorderTime $saveBorderMode, left $saveBorderTime $saveBorderMode;
				width: 0;
				left: 0;
			}
		}

		&._right {
			right: -$saveBorderSize;

			&:hover {
				transition: width $saveBorderTime $saveBorderMode, right $saveBorderTime $saveBorderMode;
				width: 0;
				right: 0;
			}
		}
	}
}