.textarea-text {
	position: relative;

	&.active {
		z-index: 10;
	}
	&.has-placeholder textarea {
		padding-top: 12px;
		padding-bottom: 2px;
	}
	&.has-icon textarea {
		padding-left: 2.5em;
	}
	.textarea-placeholder {
		position: absolute;
		z-index: 6;
		top: 50%;
		transform: translateY(-50%);
		display: block;
		display: flex;
		align-items: center;
		pointer-events: none;
		padding-left: .5em;
		color: $input-color-placeholder;
		transition: top .2s, transform .2s;
		span {
			transition: font-size .2s;
		}
	}
	&.has-icon .textarea-placeholder {
		padding-left: 2.5em;
	}
	.textarea-marker {
		position: absolute;
		z-index: 6;
		top: 18%;
		right: 0;
		height: 64%;
		display: block;
		display: flex;
		align-items: center;
		pointer-events: none;
		padding-right: .5em;
	}
	textarea {
		box-shadow: rgba(0,0,0,0) 0px 0px 4px 3px;
		transition: box-shadow .2s, border-color .2s;
		padding-right: 1.8em;
		min-height: 36px;
		overflow: hidden;
		word-wrap:break-word;
		resize: none;
	}
	textarea:focus {
		box-shadow: rgb(52, 161, 248) 0px 0px 4px 3px;
		border-color: #0072cc;
		outline: 0;
	}
	textarea:not([disabled]):active, textarea:not([disabled]):focus, textarea.nonempty {
		~ .textarea-placeholder {
			span {
				font-size: 10px;
			}
			transform: translateY(0);
			top: 3px;
		}
	}
}

.text-group {
	margin-top: $spacer-y * 1.5;
	margin-bottom: $spacer-y * 1.5;
	> .text-group-row:not(:first-child) > .textarea-text > textarea, > .textarea-text:not(:first-child) > textarea {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: 0;
	}
	> .textarea-text:not(:last-child) > textarea {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: -1px;
	}

	> .text-group-row:not(:last-child) > .textarea-text > textarea {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: -1px;
	}

	> .text-group-row {
		&:after {
			clear: both;
			display: block;
			content: "";
		}
		> .textarea-text {
			float: left;
		}

		> .textarea-text:not(:first-child) > textarea {
			margin-left: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		> .textarea-text:not(:last-child) > textarea {
			margin-right: 0;
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}

	/* Error Handling */

	/* General for errors */
	& > .textarea-text.has-error, .text-group-row > .textarea-text.has-error {
		& > textarea.form-control {
			background-color: #fff8f7;
			border: 1px solid #e63200;
			position: relative;
			z-index: 5;
		}

		&.has-icon {
			&:after {
				filter: grayscale(100%) brightness(85%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
			}
			.glyphicon {
				z-index: 6;
				filter: none;
				color: #e63200;
			}
		}

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:focus,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover,
		textarea:-webkit-autofill:focus,
		textarea:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus,
		select:focus {
			-webkit-box-shadow: 0 0 0px 1000px #fff8f7 inset;
			box-shadow: 0 0 0px 1000px #fff8f7 inset;
			transition: background-color 5000s ease-in-out 0s;
		}

		.textarea-placeholder, & + .textarea-placeholder {
			color: #e63200;
			opacity: 1;
		}
	}

	/* Specific for textareas that share row */
	& .text-group-row > .textarea-text.has-error {
		&.textarea-text:not(:last-child) > textarea {
			border-right: none;
		}
		& + .textarea-text > textarea.form-control {
			border-left-color: #e63200;
		}
	}
}

/* Error specific for fields not nested inside text-group div*/
.textarea-text.has-error.has-placeholder {
	& > textarea.form-control {
		background-color: #fff8f7;
		border: 1px solid #e63200;
		position: relative;
		z-index: 5;

		& + .textarea-placeholder {
			color: #e63200;
			opacity: 1;
		}
	}

	& {
		&:after {
			filter: grayscale(100%) brightness(85%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
		}
		.glyphicon {
			z-index: 6;
			filter: none;
			color: #e63200;
		}
	}
}