* {
	word-wrap: break-word;
}

html {
	overflow-y: scroll;
	overflow-x: hidden;
}

/* Links */

a, label {
	font-weight: $font-weight-normal;
}

.fake-link {
	@extend a;
}

a, .fake-link {
	cursor: pointer;
	color: $brand-primary;

	&:hover {
		color: $brand-primary;
		text-decoration: underline;
	}

	&.secondary-link {
		color: $darkest;

		&:hover {
			color: $darkest;
			text-decoration: underline;
		}
	}

	&.underlined-link {
		color: $darkest;
		text-decoration: underline;
	}

	&.white-link {
		color: $lightest;
		text-decoration: underline;

		&:hover {
			color: $lightest;
			text-decoration: none;
		}
	}
}

a.product-link, .product-link a {
	@extend .secondary-link;
}

/* Elements */

.list-unstyled {
	padding-left: 0;
	list-style: none;
}

.tile {
	background-color: $gray-four;
	border-radius: $border-radius;
	min-height: $input-normal;
	margin: 2px 0;
	display: flex;
	align-items: center;
	transition: all 0.1s ease-in-out;
	color: $darkest;

	a {
		@extend .secondary-link;
	}

	&.active {
		background-color: $gray-two;
		color: $lightest;

		.ximg {
			filter: invert(100%) brightness(109%) contrast(105%);
		}

		&:hover {
			background-color: $gray-one;
			color: $lightest;
		}
	}

	&:hover, &.hover {
		background-color: $gray-three;
		color: $darkest;
	}
}

hr {
	border-top: 1px solid $gray-three;
	width: 100%;

	&._strong {
		border-top: 1px solid $gray-two;
	}

	&._dark {
		border-color: $gray-two;

		&._strong {
			border-color: $gray-three;
		}
	}
}

.text-box {
	color: $gray-one;
	background-color: $gray-four;
	border: transparent solid 1px;
	border-radius: 2px;
}

.vote {
	padding-left: 22px;
	height: 14px;
	margin-left: 10px;
	background-repeat: no-repeat;
	color: $gray-two;
	cursor: pointer;
	user-select: none;
	display: inline-flex;
	align-items: center;

	span {
		vertical-align: middle;
	}

	&.vote-up {
		background-image: cdn-url('/api/dynimg/icon/thumb_up/1A1A1D');

		&.active {
			background-image: cdn-url('/api/dynimg/icon/thumb_up_filled/3338FF');
		}
	}

	&.vote-down {
		background-image: cdn-url('/api/dynimg/icon/thumb_down/1A1A1D');

		&.active {
			background-image: cdn-url('/api/dynimg/icon/thumb_down_filled/3338FF');
		}
	}
}

.alert {
	padding: 15px;
	margin-bottom: 22px;
	border: 1px solid transparent;
	border-radius: $border-radius;
}

.alert-info {
	background-color: transparent;
	border-color: $brand-primary;
	color: $text-color;
}

.alert-danger {
	background-color: $error-light;
	border-color: $destructive;
	color: $destructive;
}

/* Icons */

.down-arrow {
	@include icon-right-placement('/api/dynimg/icon/chevron_down/1A1A1D', 20px);
	display: flex;
	align-items: center;

	&._white::after {
		background-image: cdn-url('/api/dynimg/icon/chevron_down/FFFFFF');
	}
}

.wh-logo {
	$logo-size: 14px;
	display: inline-block;
	position: relative;
	background-color: $brand-primary;
	width: $logo-size;
	height: $logo-size;
	font-size: $logo-size;
	border-radius: 100%;
	color: $lightest;
	font-weight: $font-weight-bold;
	line-height: 1;

	&::after {
		$logo-scale: 0.6;
		$logo-position: calc((#{$logo-size} / #{$logo-scale}) / 2 * -1);
		content: 'W';
		position: absolute;
		height: 100%;
		width: 100%;
		left: 50%;
		top: 50%;
		transform: scale($logo-scale) translate($logo-position, $logo-position);
	}
}

@media print {
	#main-header, footer, .site-message, .site-message-transition, .btn, .admin-sidebar, .avatar {
		display: none !important;
	}
	.ximg, .ximg-content, .gallery, .product-top, .product-header {
		-webkit-print-color-adjust: exact !important;/* Chrome, Safari */
		color-adjust: exact !important;
	}
}

/* Layout */

.list-settings {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	padding: 0 9px;

	& > * {
		margin: 6px 3px;
	}

	.progress-result-counter, .toggle-group, & > .toggle-btn, .dropdown-select {
		margin: 6px 3px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	@media screen and (max-width: $screen-lg-min) {
		.progress-result-counter {
			min-width: 50%;
		}

		.toggle-group {
			margin-right: 0;
		}

		& > .toggle-btn, .dropdown-select {
			flex: 1 0 45%;
		}

		& > .toggle-btn {
			margin-left: 0;
		}

		.dropdown-select {
			@include center-dropdown-label();
		}
	}

	@media screen and (max-width: $screen-sm-min) {
		& > .toggle-btn, .dropdown-select {
			flex: 1 0 100%;
			margin: 6px 0;
		}
	}
}

.pixelated, .pixelated .ximg-content, #main-header .pixelated.profile-picture .ximg-content {
	image-rendering: optimizeSpeed;
	image-rendering: -o-crisp-edges;
	image-rendering: pixelated;
}

.child-view {
	position: relative;
}

.new-bubble {
	color: #fff;
	font-size: 0.65em;
	vertical-align: middle;
	text-align: center;
	line-height: 20px;
	font-weight: bold;
	background: #c00;
	width: 20px;
	height: 21px;
	border-radius: 100%;
	text-transform: uppercase;
	transform: rotate(-7deg);
	text-shadow: 0px 1px 1px #711;
	border-bottom: 2px solid #9a1915;
	box-shadow: 0px 2px 4px rgba(black, 0.7);
}

.new-text {
	color: $destructive;
	text-transform: uppercase;
}

.help {
	cursor: help;
}

.banner {
	width: 100%;
	margin-bottom: 8px;

	& > .ximg-content {
		border-radius: $border-radius;
	}
}

.fade-enter-active, .fade-leave-active {
	transition: opacity .25s;
}

.fade-enter, .fade-leave-to, .fast-fade-enter, .fast-fade-leave-to, .slow-fade-enter, .slow-fade-leave-to, .fadein-enter .fadein-leave-to {
	opacity: 0;
}

.fast-fade-enter-active, .fast-fade-leave-active {
	transition: opacity .125s
}

.slow-fade-enter-active, .slow-fade-leave-active {
	transition: opacity .5s
}

.admin-subpanel-enter-active {
	transition-delay: 1s;
	transition: opacity 0.5s;
}
.admin-subpanel-enter, .admin-subpanel-leave-to {
	opacity: 0;
}
.admin-subpanel-leave-active {
	position: absolute;
	opacity: 0;
}

.fadein-enter-active {
	transition: opacity .25s
}

.container {
	max-width: 100%;

	&.apple-cto .banner {
		aspect-ratio: 16 / 5;
	}
}

.release-date {
	color: gray;
	text-decoration: none !important;
}

.max-purchase {
	font-weight: bold;
	margin-bottom: 6px;
	text-align: center;
}

.long-delivery,
.long-delivery-row {
	color: red;
	margin-bottom: 11px;
	text-align: center;
}

.long-delivery-row {
	font-size: .7em;
	vertical-align: text-bottom;
}

.delivery-notice.modal-root {
	color: #333;
}

.delivery-notice .modal-container {
	max-width: 500px;

	> div {
		padding: 10px 10px 12px !important;
	}
}

.ship-from-store-info {
	.bold {
		font-weight: bold !important;
	}
}

.vdp-datepicker .cell {
	float: left;
}

.panel-product {
	border: 0px;
}

.table-condensed tbody tr td, .table-condensed tbody tr th {
	padding: 4px 8px;
}

.ell-wrap {
	display: table;
	table-layout: fixed;
	width: 100%;
	white-space: nowrap;

	.ell {
		display: table-cell;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.page-description {
	margin-top: $spacer-y * 3;
}

.loading-screen {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(255,255,255,.3);
	background-image: cdn-url('/img/loading_light.svg');
	background-size: 50%;
	background-position: center;
	background-repeat: no-repeat;
	z-index: 999999;
}

.map-frame {
	cursor: pointer;
	width: 120px;
	padding: 1px;
	border: 1px solid #d9e3e9;
	border-radius: 2px;
}

.rank-name {
	padding: 1px 2px;
	border-radius: 2px;
	font-weight: bold;
}

.responsive-embed {
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.mask-input {
	-webkit-text-security: disc;
	-moz-text-security: disc;
}

.sticky-column {
	position: static; // Fallback för browsers utan sticky-support. detta gör så att elementet ignorerar top-propertyn
	position: sticky;
	will-change: transform;
}

#chat-widget-container {
	z-index: 10000 !important;
}

#facebook-jssdk-iframe {
	display: none;
}

.dropzone .dz-preview.dz-error .dz-error-message {
	display: none;
}

.error-row {
	background-color: #ffbbbb !important;
}

.warning-row {
	background-color: #fbea90 !important;
}

.cto-attribute {
	display: flex;
	align-items: center;
}

.table-striped > tbody > tr:nth-of-type(odd) {
	background-color: $gray-four;
}

.member-video {
	display: block;
	width: 100%;
	height: auto;
}

.profile-settings .panel {
	border-radius: $border-radius;
	box-shadow: none;

	.panel-heading {
		border-radius: $border-radius $border-radius 0 0;
	}

	.checkbox-wrap {
		margin: 8px 0;
	}
	.checkbox-description {
		color: $text-secondary-color;
		font-size: $font-size-small;
		margin-top: -8px;
	}

	.color-input {
		width: 100%;
		background: #fff;
		height: 30px;
	}
}

.toplist-description * {
	max-width: 100%;
}

.search-banner {
	display: block;
	margin-top: 12px;

	.ximg {
		width: 100%;

		.ximg-content {
			aspect-ratio: 16 / 5;

			@media screen and (min-width: $screen-md-min) {
				aspect-ratio: 16 / 3;
			}
		}
	}
}