.achievement {
	box-shadow: 0 1px 2px rgba(black, 0.15);
	height: 102px;
	overflow: hidden;
	line-height: 1em;
	cursor: pointer;
	padding-right: 10px;

	.achievement-title {
		margin: 3% 0 0 0;
		color: $darkest;
	}

	.achievement-xp {
		display: block;
		margin: 0;
		margin-bottom: .5em;
		color: $brand-primary;
	}

	.achievement-description {
		font-size: $font-size-small;
		line-height: 0.9em;
	}

	.ximg {
		background-color: #eceff0;
		height: 93px;
		width: 95px;
		margin: 4px 12px 4px 4px;
		float: left;
	}

	.achievement-icon-rarity {
		z-index: 1;
		float: right;
		margin: 10px;
		margin-right: 0px;
		width: 10px;
		height: 15px;
	}
}

/* Update achievements modal */

.achievement-updater-container {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	text-align: center;
	margin-bottom: 10px;
	max-width: $screen-sm;
	width: 100%;

	& > * {
		margin: 15px 0 0 0;
	}

	h2 {
		margin-top: 20px
	}

	.text-btn {
		margin-top: 15px;
		padding: 0 25px;
	}

	.ximg {
		margin-top: 30px;
		width: auto;
		height: 100%;
		max-height: 250px;
		aspect-ratio: 2 / 1;

		.ximg-content {
			border-radius: $border-radius;
			aspect-ratio: 2 / 1;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		width: auto;
	}
}

.achievements-sub-header {
	display: flex;

	.toggle-wrapper {
		margin-left: auto;
	}

	.achievement-update-modal-button {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $brand-primary;
		border-radius: 50%;
		border: none;
		height: 40px;
		width: 40px;
		padding: 0;
		position: relative;
		top: 5px;

		@media screen and (max-width: $screen-md-min) {
			top: 15px;
		}
	}

	.achievement-update-modal-button-icon {
		height: 20px;
		margin: 0;
		filter: invert(100%) sepia(5%) saturate(0%) hue-rotate(174deg) brightness(110%) contrast(101%);
	}
}


.achievement-modal-container {
	max-width: 550px;
	width: 100%;
	margin: 22px auto;
	text-align: center;

	.cheevo-exp {
		color: $brand-primary;
	}

	p {
		color: $text-secondary-color;
	}

	.rarity-description {
		font-size: $level-two-heading;

		img {
			margin-top: -5px;
			margin-right: 4px;
		}

		.text-highlight {
			color: $darkest;
		}
	}

	.ximg {
		width: 90%;
		max-width: 325px;
		height: auto;
		aspect-ratio: 1 / 1;
	}

	@media screen and (min-width: $screen-md-min) {
		width: 85vw;
	}
}

.list-settings.not-current-user {
	justify-content: flex-start;
}