.hamburger-menu {
	$buttonSize: 42px;
	padding: 18px 11px 12px;
	background-color: $lightest;

	.hamburger-header {
		width: 100%;
		display: flex;
		align-items: center;
		text-align: center;
		margin: 8px 0 18px;
		border-bottom-color: rgba(0, 0, 0, 0);
		border-bottom-width: 2px;
		border-bottom-style: solid;

		&.home {
			text-align: left;
			align-items: flex-end;
			padding-bottom: 0;
		}

		&.category {
			border-bottom-color: $gray-one;
			padding-bottom: 16px;
		}

		.clickable {
			display: flex;
			align-items: center;
		}

		.ximg {
			height: 24px;
			width: 24px;
		}

		h2 {
			flex: 1 1 0%;
			margin: 0;

			&.current-route {
				text-overflow: ellipsis;
			}
		}
	}

	.burger-history .dropdown-area {
		background-image: cdn-url('/img/icons/arrow-up-blue.svg');
	}

	.burger-nav {
		display: block;
		padding: 0;

		li {
			display: block;
			position: relative;
			line-height: 1.2;
			font-weight: $font-weight-semibold;

			a {
				min-height: $buttonSize;
				display: block;
				display: flex;
				align-items: center;
				padding-right: $spacer-x;
				background-color: transparent;
				color: $darkest;
				text-decoration: none;

				.ximg {
					height: 1.5em;
					width: 1.5em;
					margin: 0 12px;
				}

				&.has-next, &.current-route {
					@include icon-right-placement('/api/dynimg/icon/chevron_right/1A1A1D', 12px);
				}

				&.current-route {
					padding-left: 12px;
				}

				span {
					flex: 1;
				}

				&.active {
					text-decoration: underline;
				}

				&.campaign-link {
					color: $destructive;
				}
			}
		}
	}

	.loading-data {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		margin: 12px 0;

		span {
			font-size: $level-two-heading;
			font-weight: $font-weight-semibold;
			color: $lightest;
		}
	}
}

.hamburger-menu-dropdown-arrow {
	z-index: 102;

	&::after {
		background-color: $lightest;
	}
}
