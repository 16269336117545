.profile-settings .panel {
	border-radius: $border-radius;
	box-shadow: none;

	.panel-heading {
		border-radius: $border-radius $border-radius 0 0;
	}

	.checkbox-wrap {
		margin: 8px 0;
	}
	.checkbox-description {
		color: $text-secondary-color;
		font-size: $font-size-small;
		margin-top: -8px;
	}

	.color-input {
		width: 100%;
		background: $lightest;
		height: 30px;
	}

	.notification-settings {
		display: grid;

		.radio-checkbox-label {
			display: flex;
			align-items: center;

			.radio-text {
				margin-left: 8px;
				font-size: $font-size-small;
			}

			input {
				margin: 0;
				position: absolute;
				opacity: 0;
				cursor: pointer;
				height: 0;
				width: 0;

				&:checked ~ .checkbox-custom {
					border: 2px solid $brand-primary;

					.checkbox-dot {
						background: $brand-primary;
					}
				}
			}

			.checkbox-custom {
				display: flex;
				align-items: center;
				justify-content: center;
				height: $input-check-radio-small;
				width: $input-check-radio-small;
				background-color: transparent;
				border-radius: 100%;
				border: 1px solid $gray-three;

				.checkbox-dot {
					display: inline-block;
					background: $lightest;
					width: 100%;
					height: 100%;
					transform: scale(0.7);
					border-radius: 100%;
				}
			}
		}
	}
}