.review-section {
	.no-feedback {
		font-style: italic;
		text-align: center;
	}
}

/* General review styling */

.review, .top-review-large, .top-review {
	a, .fake-link {
		@extend .secondary-link;
	}

	.avatar {
		aspect-ratio: 1 / 1;
		flex: 0 0 auto;
		border-radius: 100%;
		overflow: hidden;
	}

	.review-header {
		display: flex;
		width: 100%;
		margin-bottom: 8px;

		.avatar {
			width: 46px;
			height: 46px;
			margin-right: 8px;

			.ximg-content {
				height: 40px;
				width: 42px;
				margin: auto;
				transform: translateY(10%);
			}

			&.avatar-small {
				width: 30px;
				height: 30px;

				.ximg-content {
					height: 100%;
					width: 100%;
					transform: scale(1.4) translateY(12%);
				}
			}
		}

		.review-info {
			flex: 1 1 auto;

			.user {
				display: flex;
				align-items: center;

				.name {
					line-height: 0.8;
				}

				.icon-description {
					color: $text-secondary-color;
					font-size: $font-size-small;
					margin-left: 3px;
				}

				.wh-logo, .review-verified {
					margin: 0 0 0 3px;
				}
			}

			.user-level {
				color: $text-secondary-color;
				font-size: $font-size-small;
				line-height: 0.8;
			}
		}
	}

	.text-box {
		border-radius: $border-radius;
	}
}

/* Small reviews on product page and in product lists */

.review {
	display: flex;
	flex-direction: column;

	.review-header .review-info {
		display: grid;
		justify-content: space-between;
		grid-template-columns: [first] auto [line2] auto [line3] auto [end];
		grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end];

		.score-time {
			display: flex;
			justify-content: space-between;
			grid-column: 1 / span 3;

			.sub-title {
				line-height: $font-size-small;
				height: $font-size-small;
			}
		}

		.user {
			grid-column: 1 / span 3;
			grid-row: 2 / span 1;
		}

		.user-level {
			grid-row: 3 / span 1;
			align-self: end;
		}

		.votes {
			display: flex;
			grid-row: 2 / span 2;
			grid-column: 3 / span 1;
			place-self: end;
		}

		@media screen and (min-width: $screen-md-min) {
			grid-template-columns: [first] 50% [line2] 50% [end];

			.score-time {
				grid-column: 1 / span 2;
			}

			.user {
				grid-column: 1 / span 1;
			}

			.votes {
				grid-row: 2 / span 2;
				grid-column: 2 / span 1;
				align-self: center;
			}
		}
	}

	.review-text {
		line-height: 1.4em;
		max-height: none;

		&.compact-review-text {
			display: block;
			text-overflow: ellipsis;
			word-wrap: break-word;
			overflow: hidden;
			max-height: 8.4em;
		}

		& > p {
			min-height: 1.4em;
			margin-bottom: 0;
		}

		@media screen and (max-width: $screen-md-min) {
			&.compact-review-text {
				max-height: 5.6em;
			}
		}
	}

	.read-more, .read-more:hover {
		color: $brand-primary;
	}

	/* For pages with compact reviews, for exmple friend list */

	&.compact {
		border: 1px solid $gray-three;
		border-radius: $border-radius;
		background: $lightest;

		.review-header {
			margin-bottom: 4px;

			.review-info {
				grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end];

				.user {
					grid-column: 1 / span 2;

					.name {
						font-weight: $font-weight-semibold;
					}
				}
			}
		}
	}
}

/* Review in product lists */

.top-review {
	display: flex;
	position: relative;
	padding: 0 5px;
	padding-bottom: 6px;
	font-size: $font-size-small;

	.avatar {
		width: 24px;
		height: 24px;
		margin-right: 4px;

		.ximg-content {
			transform: scale(1.8) translateY(14%);
		}

		@media screen and (min-width: $screen-md-min) {
			width: 32px;
			height: 32px;

			.ximg-content {
				transform: scale(1.3) translateY(10%);
			}
		}
	}

	.username {
		font-weight: $font-weight-semibold;
	}

	.text-box {
		display: block;
		line-height: 1.3;
		display: flex;
		flex: 1 1 auto;
		align-items: center;
		padding: 4px;

		&:hover {
			text-decoration: none;
		}
	}

	.hide-overflow {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

/* Top review on product pages */

.top-review-large {
	position: relative;
	margin: 8px 12px 10px;

	.review-header .review-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.user-level {
			align-self: flex-start;
		}
	}

	.ximg {
		padding: 0px;
	}

	.text-box {
		padding: 8px;

		&:hover {
			cursor: pointer;
		}

		.fixed-lines {
			max-height: 4em;
		}
	}

	.hide-overflow {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

/* Verified circle and checkmark */

.review-verified {
	@extend .checkmark;
	display: inline-block;
	background-repeat: no-repeat;
	background-size: contain;
	height: $font-size-base;
	width: $font-size-base;
	border-radius: 100%;
	vertical-align: text-bottom;
	margin: 0px 3px;
	position: relative;

	&::after {
		$checkmark-height: 60%;
		$checkmark-width: 100%;
		height: $checkmark-height;
		width: $checkmark-width;
		border-width: 3px 3px 0 0;
		transform: scale(0.5) rotate(135deg) translate(calc(#{$checkmark-height} * -1), calc((#{$checkmark-height} / 2) * -1));
	}

	&.hype {
		background-color: $violet-c;
	}

	&.purchase {
		background-color: $brand-primary;
	}
}

/* Review info modal */

.review-section-info {
	max-width: 500px;
	padding: 32px 28px;

	h2, p, hr {
		margin-bottom: 18px;
	}

	h3 {
		margin-bottom: 4px;
	}

	.icon-description {
		display: flex;
		align-items: center;
		margin-bottom: 4px;

		.review-verified, .wh-logo {
			margin-left: 0;
			margin-right: 4px;
		}
	}
}