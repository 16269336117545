@keyframes check-fast {
	0% {
		height: 0;
		width: 0;
	}

	25% {
		height: 0;
		width: 10%;
	}

	40% {
		height: 0;
		width: 20%;
	}
}

@keyframes check {
	0% {
		height: 0;
		width: 0;
	}

	25% {
		height: 0;
		width: 0;
	}

	40% {
		height: 0;
		width: 20%;
	}
}

@keyframes check-delayed {
	0% {
		height: 0;
		width: 0;
	}

	25% {
		height: 0;
		width: 0;
	}

	45% {
		height: 0;
		width: 20%;
	}
}

.checkmark:after {
	animation: check 1.1s;
}

.checkmark.checkmark-slow:after {
	animation: check-delayed 1.4s;
}

.checkmark::after {
	animation: check-fast .5s;
	transform-origin: left top;
	content: "";
	position: absolute;
	box-sizing: content-box;
	border: solid $lightest;
	border-width: 2px 2px 0 0;
	top: 50%;
	left: 50%;
	transform: scaleX(-1) rotate(135deg);
	height: 40%;
	width: 20%;
}

.checkmark-sm:after {
	border-width: 4px;
}

.checkmark-thin:after {
	animation: check-fast 0.4s;
}

.checkmark-thin:after {
	left: 0;
	height: 75%;
	border-right: 2px solid white;
	border-top: 2px solid white;

}