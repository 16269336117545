/* Colors */

/* Primary */
$call-to-action: #0006FF;
$brand-primary: #3338FF;
$violet-c: #3F2884;
$darkest: #1A1A1D; // Primary icon color
$lightest: #FFFFFF;

/* Grayscale */
$gray-one: #333333;
$gray-two: #58585A;
$gray-three: #D6D6D6;
$gray-four: #F6F6F6;

/* Complementary / Usage */
$destructive: #D50855; // Discount / Error / Destructive
$destructiveHover: #A30641;
$success-dark: #29BB59;
$success-light: #1E8540;
$highlight: #FED900; // Highlight / Warning

/* Other / Usage */
$secondary-light: #EEEEEE; //temporary?
$temp-discount: #EA83AA; // Temporary for dark mode?
$hover: #0005C3;
$turquoise: #13F2DC;
$disabled: #EBEBEB;
$error-light: #FCF1F5;
$error-dark: #1F010C;
$cart: linear-gradient(141.15deg, $brand-primary 20.42%, $destructive 85.41%);

/* Image filter */
/* Use with filter: property to transform icon colors */
$lightest-filter: grayscale(99%) brightness(400%); // makes $brand-primary become $lightest
$darkest-filter: grayscale(96%) brightness(34%); // makes $brand-primary become $darkest
$gray-two-filter: grayscale(99%) brightness(114%); // makes $brand-primary become $gray-two
$destructive-filter: saturate(103%) grayscale(2%) brightness(84%) hue-rotate(89deg); // makes $brand-primary become $destructive

/* Typography */
$font-family-sans-serif: "Averta", sans-serif;
$level-one-heading: 30px;
$level-one-heading-mobile: 24px;
$level-two-heading: 18px;
$level-three-heading: 16px;
$font-size-base: 14px;
$font-size-small: 12px;
$font-size-tiny: 10px;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 800;
$line-height-base: 1.6;
$line-height-small: 1;
$text-color: $darkest;
$text-secondary-color: $gray-two;

/* Body */
$header-bg: $darkest;
$navbar-bg: $gray-one;
$body-bg: $gray-four;
$main-bg: $lightest;
$dark-body-bg: #222B3B;
$dark-main-bg :#273143;
$grid-gutter-width: 16px;
$member-bg: #21254d;
$footer-bg: $darkest;


/* Borders */
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

$border-radius: 4px;
$border-width: 1px;
$box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(53, 55, 58, 0.08);

/* External button related */
$btn-default-border: $brand-primary;
$btn-default-bg: $brand-primary;
$btn-primary-border: $brand-primary;
$btn-danger-border: $destructiveHover;
$btn-danger-bg: $destructive;
$btn-danger-color: $lightest;
$btn-default-color: $lightest;

/* Like Bootstrap's original btn-default. */
$btn-white-bg: $lightest;
$btn-white-color: $text-color;
$btn-white-border: $brand-primary;

/* Fyndwares */
$fyndware-bg: #eceff0;

/* Elements */
$dropdownIconWidth: 30px;

/* Navbar */
$navbar-min-width: 1052px; // Minimum allowed window width for large mediaquery topnav

/* Input fields */
$input-border: $gray-three;
$input-border-focus: $brand-primary;
$input-color-placeholder: $text-secondary-color;
$input-color: $text-color;

/* Input sizes */
$input-large: 48px;
$input-normal: 36px;
$input-small: 32px;
$input-tiny: 24px;

$input-check-radio: 22px;
$input-check-radio-small: 16px;


/* Panels */
$panel-default-heading-bg: #fff;

/* Valid sizes in ascending order: */
// $screen-xs-max 575px
// $screen-sm-min
// $screen-sm-max 767px
// $screen-md-min
// $screen-md-max 991px
// $screen-lg-min

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;

$container-xs: 100%;
$container-md: 100%;
$container-sm: 100%;
$container-lg: 1168px;

/* Breadcrumbs  classes from Bootstrap */
$breadcrumb-bg: transparent;
$breadcrumb-color: #6b6b6b;

/* Air */
$spacer-y: 4px;
$spacer-x: 4px;

$hr-border: $gray-three;

$rounded-radius: 2px;

$siteNavHeight: 36px;

$headerSpacer-y: 8px;
$headerHeight-xs: 51px;
$headerHeight-sm: 58px;
$headerHeight-md: 58px;
$headerHeight-lg: 72px;
$headerIconSize: .45;

$grid-float-breakpoint: $screen-md-min;

$small-quick-search-height: 48px;

$input-bg-disabled: #ccc;

/* Header related styling to ensure live search is always 100% of window width */
$logoSpacer: 12px;
$searchSpacer: 12px;

/* Variable exports to use in vue components */
:export {
	callToAction: $call-to-action;
	brandPrimary: $brand-primary;
	violetC: $violet-c;
	darkest: $darkest;
	lightest: $lightest;

	grayOne: $gray-one;
	grayTwo: $gray-two;
	grayThree: $gray-three;
	grayFour: $gray-four;

	destructive: $destructive;
	successDark: $success-dark;
	highlight: $highlight;

	secondaryLight: $secondary-light;
	hover: $hover;
	turquoise: $turquoise;
	tempDiscount: $temp-discount;
	disabled: $disabled;
	errorLight: $error-light;
	errorDark: $error-dark;

	bodyBg: $body-bg;
	mainBg: $main-bg;
	darkBodyBg: $dark-body-bg;
	darkMainBg: $dark-main-bg;

	btnDefaultBg: $btn-default-bg;

	fyndwareBg: $fyndware-bg;

	textSecondaryColor: $text-secondary-color;
}
