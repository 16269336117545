.ximg {
	display: inline-block;
	position: relative;
	height: auto;
	width: auto;

	.ximg-content {
		height: 100%;
		width: 100%;
		object-fit: contain;
		display: block;
		margin: auto;

		&.hide-content {
			visibility: hidden;
		}
	}

	&.cover .ximg-content {
		object-fit: cover;

		&.loading {
			object-fit: contain;
		}
	}
}
