.toggle-label {
	display: block;
	max-width: initial;
	padding: 0;
	margin: 0;
	font-weight: normal;
	.btn-dropdown {
		display: flex;
		justify-content: center;
		align-items: center;
		.badge {
			margin-left: 4px;
			top: 0;
			display: inline-flex;
			align-items: center;
		}
	}
}

input[type="checkbox"].toggle-checkbox:not(:checked) {
	~ * {
		display: none;
	}
}
