.panel {
	box-shadow: 0 1px 2px rgba(black, 0.15);
	margin-bottom: $spacer-y;
	border-radius: $border-radius;
}

.panel-link{
	padding: 0px 15px  0px 15px;
}

.panel-primary {
	border-color: $gray-three;
}

%panel-thick {
	border-width: $spacer-y $spacer-x;
	.panel-body {
		padding: $spacer-y $spacer-x;
		position: relative;
		font-size: $font-size-small;
		padding: $spacer-y $spacer-x
	}
	.panel-heading {
		margin-top: -4px;
		border-radius: 0;
		padding: 0.2em 0.3em;
		font-size: 14px;
		font-weight: $font-weight-semibold;

	}
	.my-store {
		font-size: 0.9em;
	}
}

%panel-thin {
	border: none;
	.panel-body {
		position: relative;
	}

	.panel-body .panel-bottom {
		display: flex;
		align-items: center;
		margin-bottom: 1px;
	}
}

.panel-thin {
	@extend %panel-thin;
}

.panel-thin-blue {
	@extend %panel-thin;
	background: #cbdfee;
	color: #55738d;
}

.panel-thick-blue {
	@extend %panel-thick;
	@include panel-variant(#2a659d, $gray-lighter, #2a659d, #2a659d);
	.panel-body {
		background-color: #4074a5;
		padding: 0;
	}
	> .panel-footer {
		background-color: #2a659d;
		border-radius: 0;
		padding: 0.2em 0.3em;
	}

}

.panel-thick-gray {
	@extend %panel-thick;
	@include panel-variant(#eceff0, $gray-one, #eceff0, #eceff0);
}

.panel-thick-gold {
	@extend %panel-thick;
	@include panel-variant(#fbc867, $gray-one, #fbc867, #fbc867);
	.panel-body {
		background-color: #fde5ae;
		color: $gray-one;
	}
}

.panel-thick-white {
	@extend %panel-thick;
	@include panel-variant(#cbdfee, $gray-one, #cbdfee, #cbdfee);
}

.panel-highlight {
	@extend %panel-thin;
	@include panel-variant(#b4cfdf, $gray-one, #cbdfee, #b4cfdf);
	> .panel-heading {
		border-radius: 0;
		font-size: 14px;
		color: #55738D;
		clear: both;
		border: 1px solid #b4cfdf;
		height: 30px;
	}
}
