.article-description {
    * {
        max-width: 100%;
    }

    .text-btn {
        @include button-base();
        min-width: 124px;
        max-width: max-content;

        &._large,
        &._small,
        &._tiny {
            min-width: 124px;
        }
    }
}