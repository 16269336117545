.button-dropdown {
	user-select: none;
}

.dropdown-select {
	@include input();
	width: 220px;
	user-select: none;
	position: relative;
	border: $border-width solid $darkest;
	border-radius: $border-radius;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 8px;
	cursor: pointer;
	background-color: $lightest;
	color: $darkest;

	&.center {
		@include center-dropdown-label();
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;

		.dropdown-label {
			pointer-events: none;
		}
	}

	&.full-width {
		width: 100%;
	}

	&.dropdown-description {
		height: 58px;

		.dropdown-label {
			display: flex;
			flex-direction: column;
		}

		.dropdown-list.dropdown-open {
			top: 56px;
		}
	}

	.dropdown-icon {
		width: $dropdownIconWidth;
		height: $dropdownIconWidth;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-right: 8px;

		&::before, &::after {
			content: '';
			height: 2px;
			width: 9px;
			border-radius: 50rem;
			background-color: $darkest;
		}

		&::before {
			transform: rotate(45deg) translate(2px, -1px);
			transition: transform linear .15s;
		}

		&::after {
			transform: rotate(-45deg) translate(-2px, -1px);
			transition: transform linear .15s;
		}
	}

	&.dropdown-open .dropdown-icon {
		&::before {
			transform: rotate(-45deg) translate(2px, 1px);
		}

		&::after {
			transform: rotate(45deg) translate(-2px, 1px);
		}
	}

	.dropdown-label {
		margin: 0;
		font-weight: $font-weight-semibold;
		color: $darkest;
		pointer-events: all;
		overflow: hidden;
		text-overflow: ellipsis;

		span {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.description-label {
			font-weight: $font-weight-normal;
		}
	}

	.select {
		display: none;
	}

	.dropdown-list {
		display: none;

		ul {
			padding: 0;
			list-style-type: none;
			margin: 0;
		}

		&.dropdown-open {
			display: block;
			position: absolute;
			top: calc(#{$input-normal} + 2px);
			left: 0;
			background-color: $lightest;
			z-index: 1002;
			list-style: none;
			padding: 0;
			margin: 0;
			border-radius: $border-radius;
			box-shadow: $box-shadow;
			width: 100%;

			.dropdown-item {
				display: flex;
				padding: 8px 12px;
				flex-direction: column;

				&:hover {
					background-color: $gray-four;
				}

				hr {
					flex: 1 0 100%;
					margin-bottom: 0;
				}

				.sort-label {
					margin-left: 4px;
				}

				&.first-cnet {
					padding-top: 0;

					hr {
						margin-top: 0;
						margin-bottom: 8px;
					}
				}

				&.product-list-variant-item, &.item-description {
					padding-bottom: 0;

					.list-label {
						font-weight: $font-weight-semibold;
					}

					&:last-child {
						padding-bottom: 8px;

						hr {
							display: none;
						}
					}
				}
			}
		}
	}

	&.filled {
		border: none;
		background-color: $gray-four;
	}

	&._dark {
		border-color: $lightest;
		background-color: $darkest;
		color: $lightest;

		.dropdown-label {
			color: $lightest;
		}

		.dropdown-icon::before, .dropdown-icon::after {
			background-color: $lightest;
		}

		& .dropdown-list.dropdown-open, &.filled .dropdown-list.dropdown-open {
			background-color: $gray-two;

			.dropdown-item:hover {
				background-color: $darkest;
			}
		}

		&.filled {
			background-color: $gray-two;
		}
	}

	&.variant-selector {
		&::before {
			content: '';
			width: $dropdownIconWidth;
			height: $dropdownIconWidth;
			flex: 0 1 auto;
		}

		.dropdown-label {
			flex: 1 1 auto;
			text-align: center;

			.lowest-price {
				color: $destructive;
			}
		}

		.variant-tab-wrap {
			display: flex;
			border-bottom: 1px solid $gray-three;

			.variant-tab {
				flex: 1 1 auto;
				background-color: $lightest;
				color: $brand-primary;
				border-radius: 0;

				&:first-child {
					border-radius: $border-radius 0 0 0;
				}

				&:last-child {
					border-radius: 0 $border-radius 0 0;
				}

				&.selected-tab {
					background-color: $brand-primary;
					color: $lightest;
				}
			}
		}

		.product-list-variant-item.dropdown-item {
			overflow: hidden;

			.variant-product-wrap {
				display: flex;
				height: 44px;
				align-items: center;

				.product-thumb {
					width: 30%;
					max-width: 50px;
					min-width: 50px;
					line-height: 0;
					align-self: baseline;
					margin-right: 4px;
				}

				span {
					flex-grow: 1;
					text-align: left;
				}

				.ximg {
					margin-top: 2px;
					width: 100%;
					height: 34px;
				}

				.fixed-lines {
					display: inline;
				}

				.variant-item-title {
					line-height: 1.2em;
					font-size: .9em;
					padding-top: 0;
					display: block;
					max-height: 2.4em;
					overflow: hidden;
				}

				.variant-item-price  {
					align-self: normal;
					white-space: nowrap;
					margin-right: 6px;
					margin-top: 0;
					display: flex;
					align-items: center;

					& > span {
						display: flex;
						align-items: center;
						.price-value {
							display: inline-block;

							&._regular {
								line-height: initial;
							}
						}
					}
				}

				.stock-icon {
					flex: 0 0 auto;
				}
			}

			&.selected {
				font-weight: $font-weight-semibold;
				color: $brand-primary;
			}
		}
	}
}