#main-header-wrapper {
	left: 0px;
	right: 0px;
	height: auto;
	z-index: 1004;
	position: absolute;
	pointer-events: none;
}

.sticky-scroll-header {
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
}

.header-container {
	margin: 0 auto;
	max-width: 1168px;
	width: 100%;
}

$headerArrowHeight: 15px;
$headerArrowWidth: 30px;
$headerArrowOffset: 5px;
$borderWidth: 3px;

#main-header {
	pointer-events: all;
	height: $headerHeight-xs;
	background: $header-bg;

	.main-header-inner {
	    height: 100%;
		position: relative;
	}
	.logo {
		line-height: 0;
		.ximg, svg, img {
			height: $headerHeight-xs * .75;
			width: $headerHeight-xs * .75 * (510/177.5);
		}
		.logo-link {
			padding-left: $logoSpacer / 2;
			margin: 0 $logoSpacer;
		}
	}
	.icon-button-wrapper {
		display: inline-block;
		position: relative;
		margin: #{($headerHeight-xs - $headerHeight-xs * $headerIconSize)/2};
		width: $headerHeight-xs * $headerIconSize;
		height: $headerHeight-xs * $headerIconSize;
	}
	.icon-button {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.header-item {
		height: $headerHeight-xs + $borderWidth;
		display: table-cell;
		vertical-align: top;
		white-space: nowrap;

		&.logo, &.search {
			padding-bottom: $borderWidth;
			vertical-align: middle;
		}

		&.hide-header-item {
			@media screen and (min-width: $navbar-min-width) {
				display: none;
			}
		}

		label[for=admin-hamburger-menu] {
			background-color: $gray-two;
		}
	}

	.header-dropdown {
		position: absolute;
		top: $headerHeight-xs + $headerArrowHeight - $headerArrowOffset - 1px;
		right: $spacer-x;
		max-width: 500px;
		width: 95%;
		border-radius: $rounded-radius;

		@media screen and (max-width: $screen-xs-max) {
			width: 100%;
			border-radius: 0;
			right: 0;
		}
	}

	.header-dropdown-arrow {
		width: $headerArrowWidth;
		height: $headerArrowHeight;
		position: absolute;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		left: 0;
		right: 0;
		top: -$headerArrowOffset;
		&:after {
			$squareSize: round(sqrt(strip-unit($headerArrowWidth * $headerArrowWidth / 2))) + 0px;
			$squareDiagonal: round(sqrt(strip-unit($squareSize * $squareSize * 2))) + 0px;
			content: "";
			position: absolute;
			width: $squareSize;
			height: $squareSize;
			transform: rotate(45deg);
			top: $headerArrowWidth/5;
			left: $headerArrowWidth/5;
			box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
		}
	}

	&.christmas {
		background-image: cdn-url('/images/652282-julstaden22');
		background-position: 50% 100%;
		background-size: auto 73px;
		background-repeat: no-repeat;
		background-color: #1a1a1d;
		border-color: #1a1a1d;
		image-rendering: optimizeSpeed;
		image-rendering: -o-crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-pixelated;
		image-rendering: pixelated;

		@media screen and (max-width: $screen-sm-max) {
			background-position: 88% 100%;
		}
	}
}

.header-border {
	height: $borderWidth;
	width: 100%;
	overflow: hidden;
	position: relative;
	background-color: $brand-primary;

	.gradient {
		background: linear-gradient(to right, $brand-primary 50%, $turquoise 60%, $brand-primary 80%);
		position: absolute;
		height: 100%;
		width: 50%;
		left: -100%;
		will-change: transform;
		animation-name: headerGradient;
		animation-duration: 15s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
		animation-direction: alternate;
		transform: scale(0);

		@keyframes headerGradient {
			from {
				transform: scale(0);
			}
			to {
				transform: scale(100);
			}
		}
	}
}

.header-spacer {
	margin-top: $headerHeight-xs + $small-quick-search-height + $headerSpacer-y * 2 + $borderWidth;
	clear: both;
}

.checkout-spacer {
	margin-top: $headerHeight-xs;
	clear: both;
}

.page-spacer {
	margin-bottom: $spacer-y*1.5;
	clear: both;
}

@media screen and (min-width: $screen-sm-min) {
	#main-header {
		height: $headerHeight-sm;

		.header-item {
			height: $headerHeight-sm + $borderWidth;
		}
		.icon-button-wrapper {
			margin: #{($headerHeight-sm - $headerHeight-sm * $headerIconSize)/2};
			width: $headerHeight-sm * $headerIconSize;
			height: $headerHeight-sm * $headerIconSize;
		}
		.logo .ximg, .logo svg, .logo img {
			height: $headerHeight-sm * .75;
			width: $headerHeight-sm * .75 * (510/177.5);

			& > .ximg-content {
				top: 2px;
			}
		}
		.header-dropdown {
			top: $headerHeight-sm + $headerArrowHeight - $headerArrowOffset - 1px;
		}
	}
	.header-spacer {
		margin-top: $headerHeight-sm + $small-quick-search-height + $headerSpacer-y * 2 + $borderWidth;
		clear: both;
	}
	.checkout-spacer {
		margin-top: $headerHeight-sm + $borderWidth;
		clear: both;
	}

}
@media screen and (min-width: $screen-md-min) {
	#main-header {
		height: $headerHeight-md;

		.header-item {
			height: $headerHeight-md;

			&.logo, &.search {
				padding-bottom: 0;
			}
		}
		.icon-button-wrapper {
			margin: #{($headerHeight-md - $headerHeight-sm * $headerIconSize)/2};
			width: $headerHeight-md * $headerIconSize;
			height: $headerHeight-md * $headerIconSize;
		}
		.logo .ximg, .logo svg, .logo img {
			height: $headerHeight-md * .75;
			width: $headerHeight-md * .75 * (510 / 177.5);
		}
		.header-dropdown {
			top: $headerHeight-md + $headerArrowHeight - $headerArrowOffset - 1px;
		}
	}
	.header-spacer {
		margin-top: $headerHeight-md + $borderWidth;
		clear: both;
	}
	.checkout-spacer {
		margin-top: $headerHeight-md + $borderWidth;
		clear: both;
	}
}
@media screen and (min-width: $navbar-min-width) {
	#main-header {
		height: $headerHeight-lg;

		.header-item {
			height: $headerHeight-lg;
		}
		.icon-button-wrapper {
			margin: #{($headerHeight-lg - $headerHeight-lg * $headerIconSize)/2};
			width: $headerHeight-lg * $headerIconSize;
			height: $headerHeight-lg * $headerIconSize;
		}
		.logo .ximg, .logo svg, .logo img {
			height: $headerHeight-lg * .75;
			width: $headerHeight-lg * .75 * (510/177.5);
		}
		.header-dropdown {
			top: $headerHeight-lg + $headerArrowHeight - $headerArrowOffset - 1px;
		}
	}
	.header-spacer {
		margin-top: $headerHeight-lg + $siteNavHeight + $borderWidth;
		clear: both;
	}

	.checkout-spacer {
		margin-top: $headerHeight-lg + $borderWidth;
		clear: both;
	}
}