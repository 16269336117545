/*
* Mörka temat
*/

main.dark {
	background-color: $dark-main-bg;
	.wrap .main-col .main-container {
		background-color: $dark-main-bg;
		color: #f5f5f5;
	}

	.modal-container {
		color: $text-color;
		.table-striped > tbody > tr:nth-of-type(odd) {
			background-color: $table-bg-accent;
		}
	}

	.highlight-section {
		header {
			color: #f5f5f5;
		}
	}
	.panel {
		background-color: $dark-main-bg;
		color: #f5f5f5;
	}

	.panel-thick-white {
		border-color: #39485d;
		background-color: $dark-main-bg;
		color: #f5f5f5;
		> .panel-heading {
			background-color: #39485d;
			border-color: #39485d;
			color: #f5f5f5;
		}
	}

	.panel-thick-gray {
		border-color: #39485d;
		> .panel-heading {
			background-color: #39485d;
			border-color: #39485d;
			color: #f5f5f5;
		}
		.panel-body {
			background-color: $dark-main-bg;
		}
	}

	.panel-thick-blue {
		border-color: $dark-main-bg;
		> .panel-heading {
			background-color: $dark-main-bg;
			border-color: $dark-main-bg;
			color: #f5f5f5;
		}
		.panel-body {
			background-color: #39485d;
		}
		.panel-footer {
			background-color: $dark-main-bg;
		}
	}

	.panel-thin-blue {
		border-color: $dark-main-bg;
		> .panel-heading {
			background-color: #39485d;
			border-color: $dark-main-bg;
			color: #f5f5f5;
		}
		.panel-body {
			background-color: $dark-main-bg;
		}
	}

	.panel-highlight {
		> .panel-heading {
			background: #39485d;
			border-color: $dark-main-bg;
			color: #f5f5f5;
		}
	}

	.product-list-page > div, .product-list > div {
		border-bottom: 1px solid #39485D;
	}

	.btn-product-page {
		color: #f5f5f5;
		&:before {
			background-color: #39485d;
			border-color: $dark-main-bg;
		}
	}

	.btn-top-list {
		color: #f5f5f5;
		&:before {
			background-color: $dark-main-bg;
			border-color: $dark-main-bg;
		}
	}

	.top-list-dropdown {
		.dropdown-menu {
			background-color: $dark-main-bg;
			color: #f5f5f5;
		}

		.dropdown-menu > li > a {
			color: #f5f5f5;
			&:hover {
				background-color: rgba(255, 255, 255, 0.2);
			}
		}

		.selected-item {
			background-color: #39485d;
		}
	}

	.top-review {
		.text-box {
			color: #fff;
			border: #56697e solid 1px;
			background-color: #39485d;
		}
	}

	.top-review-large {
		.text-box {
			color: #fff;
			border: #56697e solid 1px;
			background-color: #39485d;
		}

		.username {
			color: #fff;
		}

		.badge {
			background-color: #56697e;
		}
	}

	.question {
		.text-box {
			border-color: #56697e;
			background-color: #39485d;
		}
	}

	.popular-categories-tile {
		&.icon-tiles {
			.ximg.popular-categories-img {
				height: 60px;
				.ximg-content {
					filter:none;
				}
			}
		}
	}

	.product-list-item, .product-list-compact-item, .product-tip-item, .popular-categories-tile {
		.image-link, .popular-categories-img {
			background-color: #fff;
			opacity: .85;
		}

		&:hover {
			.image-link {
				opacity: 1;
			}
		}

		.popular-categories-img:hover {
			opacity: 1;
		}
	}
	.product-grid-item, .product-highlight-grid-item, .product-tip-grid-item {
		.ximg {
			background-color: #fff;
			opacity: .85;
		}

		&:hover {
			.ximg {
				opacity: 1;
			}
		}
	}

	.product-list-item-number {
		background-color: #39485d;
		color: #f5f5f5;
	}

	.category-highlight-name {
		background-color: #1e222d;
		border: none;
	}

	.price-value {
		color: #f5f5f5;
	}

	.price-value._campaign {
		color: #ff5252;
	}

	.price-explainer {
		.price-type {
			color: #eee;
		}
		.regular-price {
			color: #ccc;
		}
	}

	.table-striped > tbody > tr:nth-of-type(odd) {
		background-color: #39485d;
	}

	input.form-control {
		background-color: #39485d;
		border-color: #1e222d;
		color: #f5f5f5;
	}

	.progress {
		background-color: #39485d;
	}

	.slider-blue {
		background-color: #39485d;
	}

	.btn-light-blue {
		color: #f5f5f5;
		&:before {
			background-color: #56697e;
			border-color: #39485d;
		}
	}

	.btn-default {
		color: #f5f5f5 !important;
		&:before {
			background-color: #39485d;
			border-color: #1d2637;
		}
		&.active:before {
			background-color: #56697e;
		}
	}

	.btn-variants {
		color: #f5f5f5;
		&:before {
			background-color: #39485d;
		}
	}

	.gallery-thumbnail {
		background-color: #fff;
		&.selected {
			box-shadow: inset 0 1px 6px black;
		}
	}

	.gallery {
		.ximg {
			background-color: white;
		}
	}

	hr {
		border-color: #39485d;
	}

	.table > tbody > tr > td {
		border-top: none;
	}

	.product-page {

		.product-nav {
			background-color: $dark-main-bg;
			li {
				border-bottom: solid $dark-body-bg 4px;
				a {
					color: #fff
				}
			}
		}

		.nav > li.disabled > a {
			color: #39485d;
			:hover {
				color: #39485d;
			}
		}
	}

	.product-nav-btn {
		background-color: #39485D;
		border-bottom: 2px solid #303C4D;
	}

	.product-nav-btn:hover {
		background-color: #455770;
	}

	.product-nav-btn:active {
		background-color: #303C4D;
	}

	.product-nav-btn.left-side::after {
		border-top: 4px solid #303C4D;
	}

	.product-nav-btn.right-side::after {
		border-top: 4px solid #303C4D;
	}

	.product-loading {
		cursor: not-allowed;
		background-color: #2f3c4f;
		color: #26313f;
	}

	.product-loading:hover {
		background-color: #2f3c4f;
		color: #26313f;
	}

	.read-more-footer {
		background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba($dark-main-bg, 1) 80%);
		&.open {
			background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba($dark-main-bg,1) 20%);
		}
	}

	.highlight-section .highlight-nav .hl-nav-box {
		border-bottom: none;
	}

	.product-grid-item, .product-highlight-grid-item .energy-prod-info {
		color: #949dad;
		background-color: #39485d;
	}

	.product-list-page .product-grid-item {
		background-color: $dark-main-bg;
		.product-list-subtitle, .product-list-item-subtitle, .stock-modal, .stock-bar-wrapper div span, .product-tip-item-rating  {
			color: $gray-three;
		}
	}

	.stars {
		background-image: cdn-url('/img/icons/grey-star-dark.svg');
	}

	.flames {
		background-image: cdn-url('/img/icons/feedback/hype_0_dark.svg');
	}

	.gallery-dot {
		background-color: #56697e;
	}

	.gallery-dot.active {
		background-color: #fff;
	}

	.thumbnail-arrow {
		text-shadow: 0px 1px 1px $dark-main-bg;
	}

	.stock-favorite span {
		background-image: cdn-url('/api/dynimg/icon/heart/39485D');
	}

	.stock-modal-content .stock-favorite span {
		background-image: cdn-url('/api/dynimg/icon/heart/E3E6E6');
	}

	a:hover {
		color: #52b2ec;
	}

	.badge {
		background-color: #39485d;
	}

	.filter-list-header {
		color: #eee;
	}

	.filter-category label, .filter-category a {
		color: #eee;
	}

	.split-payment-info {
		color: #f5f5f5;
	}

	.insurance-selector-panel {
		background-color: #39485d;

		&.selected {
			background-color: $link-color;
		}
	}

	.sticky-add-to-cart {
		background-color: $dark-main-bg;
		border-color: #39485D;
	}

	.free-shipping-wrap .free-shipping-sum {
		color: #969BA0;
	}

	.popular-categories-img.dark {
		background-color: #202430;
	}

	.product-list-progress-container {
		background: #273143;
		color: #eee;
	}
}
