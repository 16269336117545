.countdown-timer {
	&.big {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		min-width: fit-content;
		width: 35vw;
		margin: 0 auto;
		text-align: center;

		span {
			display: block;
		}

		.style-counter-label {
			font-size: 8px;
		}

		.style-counter {
			font-weight: $font-weight-bold;
			font-size: 3vw;
		}

		.style-separator {
			margin-bottom: 1px;
		}

		@media screen and (min-width: $screen-sm-min) {
			.style-counter-label {
				font-size: 2vw;
			}

			.style-separator {
				margin-bottom: 2px;
			}
		}

		@media screen and (min-width: $screen-md-min) {
			.style-separator {
				margin-bottom: 4px;
			}
		}

		@media screen and (min-width: $screen-lg-min) {
			width: 345px;

			.style-counter-label {
				font-size: 18px;
			}

			.style-counter {
				font-size: 40px;
			}
		}
	}
}