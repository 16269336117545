//Knighthood symbol + modal
.knighthood-modal {
	display: flex;
	align-items: center;
	flex-direction: column;
	text-align: center;
	background-color: $lightest;
	max-width: 100%;
	border-radius: 20px;

	* {
		padding: 10px;
	}

	.knighthood-modal-image {
		max-width: 250px;
		width: 100%;
	}
}

.knighthood-icon {
	display: flex;
	height: 22px;
	width: 22px;
	margin: 0 0 0 3px;
}

.kategoribild {
	margin-top: 10px;
	margin-bottom: 25px;
}

//community quest
.community-quest-section {
	//center all content
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 15px;
	margin-top: 15px;

	.community-quest-info {
		margin-top: 15px;
	}
}

//Knighthood progress page
.knighthood-card-row {
	display: flex;
	flex-wrap: wrap;
	margin: auto;
	justify-content: space-around;
}

.knighthood-card-container {
	flex: 1 0 calc(30%);
	margin: 0 3px 20px;
	background-color: $darkest;
	border-radius: 4px;
	border: 8px solid $darkest;
	border-bottom: 40px solid $darkest;

	.knighthood-goal {
		align-items: center;
		flex-direction: column;
		justify-content: center;
		text-align: center;

		.progress-image {
			height: 400px;
			width: 100%;
		}

		.ximg-content {
			min-width: 100%;
			min-height: 100%;
			object-fit: cover;
		}

		.top-right-icon {
			position: absolute;
			top: 0;
			right: 6px;
			z-index: 5;
			height: 50px;
			transform: translate(20%, -27%);
		}

		.title-check {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			bottom: -5px;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			z-index: 5;
		}

		.knighthood-goal-progress {
			position: absolute;
			background-color: $gray-one;
			width: 100%;
			bottom: 5%;
			height: 3px;
			left: 0;
			right: 0;
			margin: auto;
		}

		.knighthood-title {
			font-family: $font-family-sans-serif;
			font-weight: $font-weight-bold;
			font-style: normal;
			font-size: $level-one-heading-mobile;
			line-height: 125%;
			position: relative;
			color: $lightest;
			text-align: center;
			z-index: 2;
		}


		.knighthood-progress-bar-goal-text {
			font-weight: $font-weight-semibold;
			position: relative;
			color: $destructive;
			text-align: center;
			z-index: 2;
		}

		.goal-icon {
			height: 60px;
		}

		.card-footer {
			bottom: -40px;
			left: 0;
			right: 0;
			position: absolute;

			.above-bar {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-content: center;
				margin: 10px
			}

			.logos {
				display: flex;
				align-items: center;
				justify-content: space-evenly;
				filter: invert(35%) sepia(5%) saturate(158%) hue-rotate(202deg) brightness(93%) contrast(89%);

				.logo-right {
					margin-right: 12px;
					height: 16px;
				}

				.logo-left {
					height: 16px;
					margin-left: 12px;
				}
			}

			.progress-bar {
				background: rgb(2, 0, 36);
				background: linear-gradient(90deg, #3338ff 0%, #B2387C 100%);
			}
		}
	}

	@media (max-width: 1284px) {
		flex: 1 0 calc(33%);
	}

	@media (max-width: $screen-md-min) {
		flex: 1 0 calc(50%);
	}

	.progress-text {
		display: none;
	}
}