.input-text.has-icon {
	position: relative;
	&:after {
		content: "";
		position: absolute;
		z-index: 6;
		top: 0;
		left: 0;
		bottom: 0;
		width: 14px;
		pointer-events: none;
		background-repeat: no-repeat;
		background-size: contain;
		background-position-y: 50%;
		margin: 10px;
		filter: $gray-two-filter;
		transition: filter .5s;
	}

	.glyphicon {
		position: absolute;
		z-index: 6;
		top: 0;
		left: 0;
		bottom: 0;
		width: 14px;
		pointer-events: none;
		margin: 10px;
		filter: $gray-two-filter;
		color: $brand-primary;
		transition: filter .5s;
	}

	&.active:after, &.active .glyphicon {
		filter: none;
	}

	&.icon-user:after {
		background-image: cdn-url('/api/dynimg/icon/user/3338ff');
	}

	&.icon-lock:after {
		background-image: cdn-url('/api/dynimg/icon/lock/3338ff');
	}

	&.icon-mail:after {
		background-image: cdn-url('/api/dynimg/icon/mail/3338ff');
	}

	&.icon-phone:after {
		background-image: cdn-url('/api/dynimg/icon/phone_black/3338ff');
	}

	&.icon-corporate:after {
		background-image: cdn-url('/api/dynimg/icon/business/3338ff');
	}

	&.icon-geopin:after {
		background-image: cdn-url('/api/dynimg/icon/geopin_black/3338ff');
	}

	&.icon-house:after {
		background-image: cdn-url('/api/dynimg/icon/house/3338ff');
	}

	&.icon-gift:after {
		background-image: cdn-url('/api/dynimg/icon/presentkort/3338ff');
	}
}
