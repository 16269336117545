@mixin textGlitch($name, $intensity, $textColor, $background, $highlightColor1, $highlightColor2, $width, $height) {

	color: $textColor;
	position: relative;
	$steps: $intensity;

	// Ensure the @keyframes are generated at the root level
	@at-root {
	  // We need two different ones
	  @for $i from 1 through 2 {
		@keyframes #{$name}-anim-#{$i} {
		  @for $i from 0 through $steps {
			#{percentage($i*(1/$steps))} {
			  clip: rect(
				random($height)+px,
				$width+px,
				random($height)+px,
				0
			  );
			}
		  }
		}
	  }
	}
	&:before,
	&:after {
	  content: attr(data-text);
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 100%;
	  background: $background;
	  clip: rect(0, 0, 0, 0);
	}
	&:after {
	  left: -1px;
	  text-shadow: 1px 0 $highlightColor1;
	  animation: #{$name}-anim-1 2s infinite linear alternate-reverse;
	}
	&:before {
	  left: 1px;
	  text-shadow: 1px 0 $highlightColor2;
	  animation: #{$name}-anim-2 3s infinite linear alternate-reverse;
	}
}

.rank-legend {
	color: #fff;
	$legend-background: repeating-linear-gradient( -45deg, #723292, #1078b4 16.66%, #10856a 33.32%, #e8a15d 49.98%, #ce4a56 66.64%, #a12d52 83.3%, #a12d52 100% );
	background: $legend-background;
}

.rank-void {
	$void-background: #3C225E;
	@include textGlitch("glitch", 17, #61F5A1, $void-background, red, blue, 80, 30);
	color: #A662A4;
	background-color: $void-background !important;
}

.rank-black {
	color: #969BA0;
	background-color: #1B1B20 !important;
}

.rank-white {
	color: #F8F8F8;
}

.rank-gold {
	color: #F8D400;
}

.rank-silver {
	color: #CED3DC;
}

.rank-bronze {
	color: #EBA656;
}
