$custom-radio-box: 16px;
$custom-radio-dot: 8px;

.radio-picker {
	display: flex;
	flex-direction: column;

	.radio-option {
		height: $input-large;
		font-weight: $font-weight-semibold;
		display: flex;
		align-items: center;
		gap: 8px;
		border: 1px solid $gray-three;
		padding: 0 10px;
		margin-top: -1px;
		margin-bottom: 0;

		input {
			display: none;
			margin: 0;
		}

		.custom-radio {
			height: $custom-radio-box;
			width: $custom-radio-box;
			border: 1px solid $gray-three;
			border-radius: 100%;
		}

		.right-text {
			margin-left: auto;
		}

		.icon {
			height: $custom-radio-box;
		}

		&:first-child {
			margin-top: 0;
			border-radius: $border-radius $border-radius 0 0;
		}

		&:last-child {
			border-radius: 0 0 $border-radius $border-radius;

			&:first-child {
				border-radius: $border-radius;
			}
		}

		&.checked {
			border-color: $brand-primary;
			border-width: 2px;
			padding: 0 9px;
			z-index: 1;

			.custom-radio {
				border-color: $brand-primary;
				border-width: 2px;
				display: flex;
				align-items: center;
				justify-content: center;

				&::before {
					content: '';
					height: $custom-radio-dot;
					width: $custom-radio-dot;
					border-radius: 100%;
					background-color: $brand-primary;
				}
			}
		}

		&.disabled {
			background-color: $gray-four;
			color: $gray-two;

			.custom-radio {
				visibility: hidden;
			}
		}

		/* Dark mode */
		&.dark, &.dark .custom-radio {
			border-color: $gray-two;

			&.checked, &.checked .custom-radio {
				border-color: $brand-primary;
			}
		}
	}
}