@mixin button-buy-variant($text-color, $background-color, $border-color) {
	@include button-variant($text-color, $background-color, $border-color);
	font-weight: $font-weight-semibold;
	&._small {
		padding: 0.3em 1em;
	}
}

.btn, .btn-buy {
	font-size: $font-size-base;
}

.btn-lg {
	font-size: $level-two-heading;
	font-weight: $font-weight-semibold;
}

.btn-buy {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	@include button-buy-variant($gray-one, #fbc867, #fba633);
}

.btn-fully-booked, .btn-unavailable, .btn-not-yet-available {
	@include button-buy-variant($gray, #888, #999);
}

.btn-preorder {
	@include button-buy-variant($gray-one, #cbdfee, #8aa9c7);
}

.btn-light-blue {
	@include button-variant($gray-one, #cbdfee, #8aa9c7);
}

.btn-variants {
	@include button-variant($gray-one, #fff, #fff, 5%);
}

.btn-top-list {
	@include button-variant($gray-one, #fff, #fff);
}

.btn-product-page {
	@include button-variant($gray-one, #eceff0, #c1cbce, 5%);
}

.btn-dropdown {
	font-weight: $font-weight-semibold;;
	&.open:before {
		background-image: cdn-url('/img/icons/pil-upp.svg');
	}
	&:before {
		background-image: cdn-url('/img/icons/pilner2.svg');
		background-size: 12px 12px;
		background-repeat: no-repeat;
		background-position: right center;
		background-origin: content-box;
		padding-right: 12px;
	}
	padding-right: 0;
}

.btn-inline {
	@include button-size(0, 0, 1em, 1, 2px);
	margin-left: .1em;
	&:before {
		border-radius: $rounded-radius;
	}
}

.input-group-btn > .btn{
	height: $input-height-base;
}

.btn-buy._small {
	&::before {
		background-image: cdn-url('/img/icons/varukorg_plus.svg') !important;
		background-repeat: no-repeat;
		background-size: 16px;
		background-position: center;
	}
	width: 54px;
}

.btn-group-views > .btn {
	padding: 8px 11px;

	&.active {
		transform: none;
	}
}

/* NEW Button stuff */

/* Single icon buttons */

.btn-remove-item {
	@include single-icon-button($gray-three);

	&:hover {
		background-color: $destructive;
	}
}

.btn-delete-item {
	@include single-icon-button($destructive);

	&:hover {
		background-color: $destructiveHover;
	}
}

.btn-close {
	@include single-icon-button($destructive, 12px);
	z-index: 10;
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 0 0 0 50rem;
	height: $input-large;
	width: $input-large;
	min-width: $input-large;

	.icon {
		transform: translate(3px, -3px);
	}
}

.btn-remove-one {
	@include single-icon-button($gray-three);
}

.btn-check {
	@include single-icon-button($brand-primary);
}

.btn-add-one {
	@include single-icon-button($gray-three);
}

.btn-more {
	@include single-icon-button($gray-three);
}

.btn-next {
	@include single-icon-button($brand-primary);
}

.btn-prev {
	@include single-icon-button($brand-primary);
}

.btn-scroll {
	@include button-base($gray-three);
	@include button-icon-center-placement(15px);
	padding: 0;
	position: relative;
	height: $input-large;
	min-width: auto;
	width: calc(#{$input-large} / 2);

	.icon {
		height: 15px;
		width: auto;
		aspect-ratio: 14/25;
	}
}

.btn-scroll-left {
	@extend .btn-scroll;
	border-radius: 50rem 0 0 50rem;
}

.btn-scroll-right {
	@extend .btn-scroll;
	border-radius: 0 50rem 50rem 0;
}

/* Various buttons with text */

.text-btn {
	@include button-base();
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: $font-weight-semibold;
	min-width: 124px;
	max-width: max-content;

	&._large, &._small, &._tiny {
		min-width: 124px;
	}

	&._destructive {
		background-color: $destructive;

		&:hover {
			background-color: $destructiveHover;
		}
	}

	&._cart {
		background: $cart;
		background-size: 400% 400%;
		color: $lightest;
		animation: gradient 10s ease infinite;

		@keyframes gradient {
			0% {
				background-position: 0% 50%;
			}
			50% {
				background-position: 100% 50%;
			}
			100% {
				background-position: 0% 50%;
			}
		}
	}

	&._secondary .counter {
		background-color: $brand-primary;
		color: $lightest;
	}

	&.add {
		margin-right: 4px;
	}

	&._stretch {
		padding: 0;
	}

	&._narrow {
		min-width: max-content;
	}

	.icon {
		height: 12px;
		width: 12px;
		aspect-ratio: 1;

		&:first-child {
			margin-right: 4px;
		}

		&:last-child {
			margin-left: 4px;
		}
	}

	.glyphicon {
		margin-right: 4px;
		top: -1px;
	}

	.counter {
		margin-left: 4px;
	}
}

.toggle-btn {
	@include input();
	padding: 0;
	border: none;
	border-radius: $border-radius;
	background-color: $gray-three;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	color: $darkest;
	margin: 0 3px;

	&:hover {
		background-color: $gray-two;
		color: $lightest;

		.toggle-icon {
			filter: invert(100%) brightness(109%) contrast(105%);
		}
	}

	&._disabled {
		background-color: $gray-three;
		pointer-events: none;
		color: $disabled;

		.counter {
			background-color: $disabled;
			color: $gray-three;
		}
	}

	&:first-of-type {
		margin-left: 0;
	}

	&:last-of-type {
		margin-right: 0;
	}

	&.active {
		background-color: $gray-one;
		color: $lightest;

		&:hover {
			background-color: $darkest;
		}

		.toggle-icon {
			filter: invert(100%) brightness(109%) contrast(105%);
		}

		.counter {
			color: $gray-one;
		}
	}

	&.autoscroll {
		z-index: 2;
		position: fixed;
		margin-left: 4px;
		bottom: 50px;

		&.hide-margin {
			bottom: 0;
		}

		@media screen and (min-width: $screen-lg-min) {
			bottom: 55px;
		}
	}

	.toggle-icon {
		height: 16px;
		width: 16px;

		img {
			width: 100%;
			height: 100%;
			vertical-align: initial;
		}
	}

	.counter {
		color: $gray-two;
	}

	& > :first-child {
		margin-right: 6px;
		margin-left: 8px;
	}

	& > :not(:first-child) {
		margin-right: 6px;
		margin-left: 0;
	}

	& > :last-child {
		margin-right: 8px;
	}
}

.toggle-group {
	display: flex;

	.toggle-btn {
		border-radius: 0px;
		margin: 0;

		& > span {
			margin: 0;
		}

		&:first-child {
			border-radius: $border-radius 0 0 $border-radius;
		}

		&:last-child {
			border-radius: 0 $border-radius $border-radius 0;
		}
	}

	&.btn-dividers {
		.toggle-btn {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				right: 0;
				height: 50%;
				border-right: 1px solid $gray-four;
			}

			&:last-child::after {
				border: none;
			}

			&:hover::after {
				border-color: $gray-three;
			}
		}
	}
}

.paginator-buttons {
	display: flex;
	justify-content: center;

	.text-btn {
		@include button-base();
		margin: 0;

		&._secondary {
			border: none;
		}
	}
}

.add-to-wishlist-btn .icon {
	margin-left: 4px;
	height: 24px;
	width: 24px;
}