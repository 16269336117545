/* Styling related to cart and/or wishlist elements */

.add-to-cart-footer {
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	height: $input-large;

	.total {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		font-weight: $font-weight-bold;
		color: $text-secondary-color;
		line-height: 1;
		text-align: right;

		.sum {
			font-size: $level-two-heading;
			color: $text-color;
		}
	}
}

.cart-bg {
	background: $cart;
}

.save-to-wishlist-btn {
	float: right;
}

.cart-counter {
	$size: 22px;
	font-size: $font-size-small;
	position: absolute;
	text-align: center;
	width: $size;
	height: $size;
	line-height: $size * 0.85;
	font-weight: $font-weight-semibold;
	top: -$size/2.5;
	right: -$size/2.5;
	border-radius: #{$size / 2};
	background-color: $lightest;
	color: $darkest;
	border: 2px solid;
	border-color: $lightest;

	&.max {
		font-size: $font-size-small;
	}
}

/* General styling for cart and wishlist */

.cart-list {
	$height: 60px; // Used on main children for nice flex
	list-style-type: none;
	margin-block: 0;
	padding-inline-start: 0;

	.cart-list-row {
		height: auto;

		.cart-list-product-wrap {
			align-items: center;
			display: flex;
			height: 100%;
			padding: 8px 0;
			gap: 8px;

			.image-link {
				flex: 1 0 10%;
				margin: 0;
				padding: 0;
				max-width: 60px;
				min-width: 40px;
				line-height: 0;

				.ximg {
					height: 45px;
					width: 100%;
				}
			}

			.product-title-wrap {
				display: flex;
				flex: 1 1 60%;
				flex-direction: column;
				align-self: center;
				white-space: normal;
				word-break: break-word;
				justify-content: space-evenly;
				height: $height;

				.product-id {
					line-height: 1.2em;
				}
			}

			.cart-price-qty {
				flex: 1 1 30%;
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-between;
				height: $height;
				max-width: fit-content;

				.price-value .price-value._regular {
					bottom: auto;
				}

				.cart-item-quantity {
					display: flex;
					align-items: center;

					.btn-remove-item {
						margin-left: 4px;
					}
				}
			}

			.stock-modal {
				display: none;
				border-left: 1px solid $gray-three;
				width: 20%;
				max-width: 120px;
				padding-left: 14px;
				margin-left: 8px;
				position: relative;

				.starred-stores-hr::after {
					left: 14px;
				}

				@media screen and (min-width: $screen-md-min) {
					display: block;
				}
			}

			.alert {
				margin: 0;
				flex: 1 0 auto;
				text-align: center;

				@media screen and (max-width: $screen-md-min) {
					padding: 8px;
					font-size: $font-size-small;
				}
			}
		}

		.insurance-selector-panel {
			width: 100%;
			margin-bottom: 8px;
		}

		&:last-child > hr {
			display: none;
		}

		hr {
			margin: 0;
		}
	}
}

/* Styling shared by dropdown cart and checkout cart */

.cart-dropdown, .checkout-wrap {
	.product-title-wrap {
		.product-list-item-title {
			margin-top: 6px;
		}

		.product-id {
			margin-top: auto;
		}
	}
}

/* Cart dropdown specific styling */

.cart-dropdown {
	.cart-header {
		background: $cart;
		background-size: 150%;
		color: $lightest;

		.icon-space-filler {
			width: $headerHeight-xs;
			height: $headerHeight-xs;
		}

		&._empty {
			background: $header-bg;

			.cart-icon {
				filter: $gray-two-filter;
			}
		}

		.cart-icon-wrapper {
			display: inline-block;
			position: relative;
			margin: #{($headerHeight-xs - $headerHeight-xs * $headerIconSize)/2};
			width: $headerHeight-xs * $headerIconSize;
			height: $headerHeight-xs * $headerIconSize;
		}

		.cart-icon {
			$transitionTime: 0.25s;
			$transitionFunction: ease;
			padding: 0;
			position: relative;
			transition: margin $transitionTime $transitionFunction, width $transitionTime $transitionFunction, height $transitionTime $transitionFunction;
			width: 100%;
			height: 100%;
			filter: $lightest-filter;

			&.new {
				transition: none;
				margin: -25%;
				width: 150%;
				height: 150%;
			}
		}

		.cart-widget-info {
			margin-right: 12px;
			display:none;

			@media screen and (min-width: $navbar-min-width) {
				display: inline-block;
			}
		}

		.down-arrow {
			display: none;
			margin: 0 6px 0 0;

			@media screen and (min-width: $navbar-min-width) {
				display: flex;
			}
		}
	}

	.cart-dropdown-arrow:after {
		background-color: $lightest;
	}

	.cart-large {
		background-color: $lightest;
		z-index: 102;

		.additions-panel {
			background-color: $gray-four;
			border-radius: $border-radius;
			margin: 8px 0;
			padding: 10px 12px;

			.additions-row {
				display: grid;
				grid-template-columns: 1fr 1fr;

				& > span  {
					font-size: $font-size-small;

					&:nth-child(even) {
						text-align: end;
					}
				}
			}
		}

		.cart-wrap {
			h2.level-one-heading {
				border-bottom: 2px solid $gray-one;
				padding: 18px 0 12px;
			}

			.cart-footer {
				background-color: $gray-four;
				padding: 6px 12px;
				width: 100%;
				border-radius: $border-radius;

				hr {
					width: 100%;
				}

				& > div {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}

				.cart-total {
					font-weight: bold;

					.sum-ex-inkvat {
						display: flex;
						flex-direction: column;
					}
				}
			}

			.to-checkout {
				display: flex;
				justify-content: center;
				margin: 8px 0;
			}
		}

		.product-tip-slider-anchor {
			margin: 0 -6px;

			.product-tip-slider-anchor-wrap {
				padding: 0;

				.product-grid-item .price-bubble {
					right: 0;
				}
			}
		}
	}

	@media screen and (min-width: $screen-sm-min) {
		.cart-header {
			.icon-space-filler {
				width: $headerHeight-sm;
				height: $headerHeight-sm;
			}

			.cart-icon-wrapper {
				display: inline-block;
				position: relative;
				margin: #{($headerHeight-sm - $headerHeight-sm * $headerIconSize)/2};
				width: $headerHeight-sm * $headerIconSize;
				height: $headerHeight-sm * $headerIconSize;
			}
		}
	}

	@media screen and (min-width: $screen-md-min) {
		.cart-header {
			.icon-space-filler {
				width: $headerHeight-md;
				height: $headerHeight-md;
			}

			.cart-icon-wrapper {
				display: inline-block;
				position: relative;
				margin: #{($headerHeight-md - $headerHeight-sm * $headerIconSize)/2};
				width: $headerHeight-md * $headerIconSize;
				height: $headerHeight-md * $headerIconSize;
			}
		}
	}

	@media screen and (min-width: $navbar-min-width) {
		.cart-header {
			.icon-space-filler {
				width: $headerHeight-lg;
				height: $headerHeight-lg;
			}

			.cart-icon-wrapper {
				display: inline-block;
				position: relative;
				margin: #{($headerHeight-lg - $headerHeight-lg * $headerIconSize)/2};
				width: $headerHeight-lg * $headerIconSize;
				height: $headerHeight-lg * $headerIconSize;
			}
		}
	}
}
