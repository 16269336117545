.gallery {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	line-height: 0;

	.ximg {
		height: 100%;
		width: 100%;
	}

	.gallery-touch-swipe {
		width: 100%;
		flex: 1 1 80%;
		height: 320px;
		min-height: 320px;
		position: relative;

		.modal-wrap {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: #ffffff;
			cursor: pointer;

			.toggle-label {
				height: 100%;
				padding: 12px;
			}
		}
	}
}

.gallery-slideshow.image-slideshow {
	line-height: 1;
}

.image-slideshow {
	user-select: none;
  	width: 85vw;
  	overflow: hidden;
  	outline: 0;
  	padding: 70px 0;

	.carousel {
		transform: translateX(-100%);
  	}

	.inner {
		width: 100%;
		height: 100%;
		white-space: nowrap;

		&.move-left {
			transition: transform 0.5s;
			transform: translateX(-100%) translateX(-100%);
		}

		&.move-right {
			transition: transform 0.5s;
			transform: translateX(100%) translateX(-100%);
		}

		.card {
			width: 100%;
			height: 100%;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			padding: 0 60px;
		}

		.ximg {
			flex: none;
			height: 100%;
			width: 100%;
		}
	}

	.modal-container a {
		padding: 10px;
	}

	.btn-product-page {
		padding: 18px 4px 0 4px;
		font-size: 24px;
		color: #bbb;
		text-shadow: 0px 1px 1px white;
	}

	.btn-scroll-left, .btn-scroll-right {
		position: absolute;
		top: 50%;
		z-index: 1;

		@media screen and (max-width: $screen-sm-min) {
			display: none;
		}
	}

	.btn-scroll-left {
		left: 16px;
	}

	.btn-scroll-right {
		right: 16px;
	}

	.invisible-scroll {
		cursor: pointer;
		position: absolute;
		top: 0;
		bottom: 0;

		&._left {
			left: 0;
			right: 60%;
		}

		&._right {
			left: 60%;
			right: 0;
		}
	}

	.gallery-dot-wrap {
		position: absolute;
		bottom: 32px;
		display: flex;
		justify-content: center;
		width: 100%;
		left: 0;
  	}

	.modal-container center {
		padding: 10px;
  	}
}

.gallery-thumbnail {
	&:last-child {
		margin-right: 2px;
  	}

  	&.selected {
		box-shadow: 0px 1px 2px rgba(black, 0.25);
  	}

	&:first-child {
		margin-left: 2px;
  	}

  	border-radius: $rounded-radius * 3;
  	height: 66px;
  	width: 66px;
  	padding: 2px;
  	display: inline-block;
  	margin: 2px 4px;
  	cursor: pointer;
  	transition: box-shadow 0.2s linear;
}

.gallery-thumbnail-wrap {
	display: flex;
	justify-content: space-around;
	margin-top: 4px;
	line-height: 1;
	height: 72px;
	align-items: center;
}

.gallery-thumbnails {
	display: inline-block;
	height: 72px;
	max-width: 230px;
	overflow: hidden;
	white-space: nowrap;
}

.gallery-dot {
	width: 12px;
	height: 12px;
	background: $gray-three;
	border-radius: 100px;
	display: inline-block;
	margin: 0 4px;
	transition: background 0.3s linear;
	cursor: pointer;

	&.active {
		background: $gray-one;
	}
}
