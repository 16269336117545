$admin-panel-width: 200px;

.admin {
	display: flex;
	justify-content: center;

	& #app {
		width: 100%;
	}

	.admin-sidebar {
		height: fit-content;
		z-index: 1003;
		background-color: $lightest;
		padding: 15px;
		width: $admin-panel-width;
		flex-shrink: 0;
		clear: both;
		min-height: 100vh;

		.admin-settings-wrap {
			margin-bottom: 40px;
		}
	}
}

.admin > .container {
	flex-shrink: 1;
	margin: 0;
	min-width: 0;
}

.admin-sidebar-header, .admin-list-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	h2 {
		flex: 1 1 auto;
		border-bottom: 2px solid $darkest;
		padding: 8px 0;
	}
}

.admin-sidebar-header {
	margin-bottom: 10px;
}

.hamburger-menu {
	white-space: normal;
	z-index: 102;
	.admin-sidebar {
		margin: 10px 0 15px 0;

		@media screen and (max-width: $navbar-min-width) {
			top: 0 !important;
		}
	}

	.admin-sidebar-header {
		justify-content: center;
	}
}

.admin-sidebar-header button {
	padding-left: 10px;
	padding-right: 10px;
}

.admin-list, .admin-list > div {
	display: flex;
	flex-direction: column;
}

.admin-list {
	margin-bottom: 10px;

	h3 {
		margin: 4px 0;
		border: 0 !important;
		line-height: 1.0 !important;
		min-height: 1.0em !important;
	}

	ul {
		padding-inline-start: 0;
	}

	a {
		margin: 1px 0 1px 0;
		padding: 5px;
		font-size: $font-size-small;
	}

	button {
		margin: 5px 5px 3px 5px;
	}
}

.news-modal > .modal-container {
	padding: 2px;

	@media screen and (max-width: $screen-sm-max) {
		width: 100%;
	}
	@media screen and (min-width: $screen-sm-max) {
		width: 40%;
	}
}

.news-modal-container {
	padding: 10px;
	border-radius: 2px;

	h3 {
		margin-top: 4px;
		margin-bottom: 2px;
	}

	a {
		text-decoration: underline;
	}

	 a:hover {
		text-decoration: none;
	}

	p {
		font-size: $font-size-small;
		margin-bottom: 0;
		color: $text-secondary-color;
	}

	section {
		margin-top: 11px;
	}

	section {
		border-top: 1px solid $gray-three;
		padding-top: 10px;
	}
}

a.admin-btn:hover {
	background-color: #5386b6;
}

a.admin-btn:active {
	border-bottom: 0;
	margin-bottom: 4px;
	transform: translateY(1px);
}

a.admin-btn {
	padding-top: 2px;
	padding-bottom: 2px;
	margin: 0 2px 2px 0;
	border-radius: 2px;
	border-bottom: 2px solid #225483;
}

.admin-setting {
	display: block;
	font-size: $font-size-small;
	font-weight: $font-weight-normal;
	margin-bottom: 8px;

	.toggle-switch {
		margin-right: 2px;
		margin-bottom: -3px;
	}
}
