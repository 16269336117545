.quick-search {
	z-index: 100;
	&:focus {
		outline: none;
	}

	.input-group {
		& > .form-control {
			-webkit-appearance: none;
			height: $small-quick-search-height;
			font-size: 16px;
			border: none;

			&:first-child {
				width: 100%;
				position: absolute;
				border-radius: 50px;
				padding-right: 50px;
				padding-left: 20px;
				box-shadow: none;

				&:focus {
					box-shadow: 0 0 8px rgba(152, 203, 232, 0.6)
				}
			}
		}
		.input-group-btn {
			z-index: 5;
			padding: 4px;
			margin: 0;
			height: 100%;

			& > .search-button {
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0 50px 50px 0;
				padding: 0;
				height: $small-quick-search-height - 8;
				width: $small-quick-search-height - 6;
				background-color: $brand-primary;
				font-size: $font-size-base;
				border: none;
				color: $lightest;

				img {
					margin-right: 2px;
				}

				@include remove-sticky-hover() {
					&:hover {
						background-color: $hover;
					}
				}
			}
		}
	}

	@media screen and (max-width: $screen-sm-max) {
		position: absolute;
		left: 0;
		right: 0;
		top: 100%;
		padding: $headerSpacer-y 12px;
		background-color: $navbar-bg;
		// @Darkmode
		// &.dark-search {
		// 	background-color: $dark-main-bg;
		// }
		.input-group > .form-control {
			border: 1px solid #ccc;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		margin: 0 $searchSpacer;
		position: relative;
	}
}

/* Loop54 nedan */
[contenteditable=true]:empty:before {
	/* Placeholders för contenteditable */
	color: #969ba0;
	cursor: text;
	content: attr(placeholder);
	display: block; /* For Firefox */
}

.suggested-search-phrase {
	display: block;
	color: #333;
}

.suggested-search-phrase:hover {
	background-color: #eee;
	color: #111 !important;
	text-decoration: underline !important;
}

.suggested-search-phrase.active {
	background-color: #e0e0e0;
	color: #000 !important;
	text-decoration: underline !important;
}

.quick-search-filter {
	user-select: none;
	margin-left: 8px;
	background: #F2F4F5;
	border-radius: 2px;
	margin-left: 6px;
	padding: 2px 6px;
	font-size: 0.8em;
	box-shadow: 0px 1px 1px rgba(0, 0, 0, .2);
	position: relative;
	top: -1px;
}

.form-control .quick-search-filter {
	cursor: default;
}

.quick-search-filter-remove-icon {
	cursor: pointer;
	background: #DADADA;
	border-radius: 100px;

	border-radius: 100px;
	padding-left: 2.6px;
	padding-top: 1.5px;
	color: #273043;
	line-height: 0;
	width: 12px;
	height: 12px;
	display: inline-block;
	margin-left: 3px;

	position: relative;
	top: -2px;
}

.quick-search-filter-remove-icon:hover {
	background: #aa0000;
}

.quick-search-filter-remove-icon:hover .glyphicon-remove {
	color: #eee;
}

.quick-search-filter-remove-icon .glyphicon-remove {
	font-size: 7px;
}

.quick-search-filter-icon {
	height: 12px;
	margin-right: 6px;
}
/* End of loop54-grejer */