.site-usp {
	display: flex;
	padding: 14px;
	margin-top: 6px;
	color: $darkest;
	background-color: $gray-four;
	font-size: $font-size-small;

	a {
		@extend .secondary-link;
	}

	.messages {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: column;

		&.checkout .banner-info, .site-message {
			&::before {
				width: 20px;
			}

			.current-message {
				flex: 1 1 auto;
			}
		}

		.banner-info, .site-message {
			display: flex;

			&::before {
				content: '';
				width: 140px;
				flex: 0 1 auto;
			}

			@media (max-width: $screen-lg-min - 1) {
				&::before {
					width: 20px;
				}

				.current-message {
					flex: 1 1 auto;
				}
			}
		}

		.banner-info {
			width: 100%;
			justify-content: center;

			.banner-text {
				flex: 1 0 auto;
				text-align: center;
				font-weight: $font-weight-bold;
				font-size: $level-three-heading;
			}
		}

		.current-message {
			display: flex;
			flex: 1 0 auto;
			align-items: center;
			font-weight: $font-weight-semibold;

			a {
				text-decoration: underline;
			}

			.site-message-avatar {
				margin-right: 5px;
				width: 24px;
				height: 24px;
				flex-shrink: 0;
			}
		}
	}

	.navigation {
		display: flex;
		flex-shrink: 1;
		align-items: center;

		& > div {
			display: flex;
		}

		a {
			padding: 0 4px;

			&:not(:last-child){
				border-right: 1px solid $darkest;
			}
		}

		.message-paginator {
			align-items: center;
			flex-direction: column;
			justify-content: center;

			span:nth-child(2) {
				width: 20px;
				text-align: center;
				margin-bottom: -1px;
			}
		}
	}


	/* OBS TEMPORARY */
	&.blackweek {
		.messages {
			.current-message, .current-message a {
				font-size: $level-two-heading;
				color: $brand-primary;
				font-weight: $font-weight-bold;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}

				.site-message-avatar {
					display: none;
				}
			}

			.banner-text {
				font-size: $font-size-base;
				font-weight: $font-weight-normal;
			}
		}

		@media (max-width: $screen-sm-min) {
			padding: 14px 0;

			.messages {
				.banner-info::before, .site-message::before {
					display: none;
				}

				.banner-text {
					font-size: $font-size-small;
				}
			}
		}
	}
}