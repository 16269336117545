/*TOPPNAVIGERING, FULLSCREEN*/
#navbar-top {
	pointer-events: all;
	margin-bottom: 0;
	border: none;
	min-height: 1px;

	.navbar-nav .dropdown.hover > .dropdown-menu {
		display: block;
		&.hover > .hover-saver._careful {
			display: block;
		}
	}

	/* Hover enable specific */
	@media (hover: hover) {
		&.hover-enabled {
			.navbar-nav .dropdown:hover > .dropdown-menu {
				display: block;

				&:hover > .hover-saver._careful {
					display: block;
				}
			}

			.nav > li.section:hover {
				background: $darkest;
				transition: all 0.2s ease-in-out;

				.dropdown-menu li.dropdown:hover > .dropdown-menu {
					display: block;
				}
			}
		}
	}
}

.navbar {
	background: $navbar-bg;
	border-radius: 0;

	&.hidden-navbar {
		display: none;
		@media screen and (min-width: $navbar-min-width) {
			display: block;
		}
	}
	.navbar-top-container {
		position: relative !important;

		.nav {
			width: 100%;
			display: flex;
			justify-content: space-between;
		}

		.nav > li {
			flex-grow: 1;
			text-align: center;
			position: static;

			&.section {
				&.hover {
					background: $darkest;
					transition: all 0.2s ease-in-out;
				}

				&:first-child {
					border-bottom-left-radius: inherit;
				}

				& > a {
					color: $lightest;
					font-weight: $font-weight-semibold;
					font-size: $font-size-small;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 6px;
					padding: 0;
					height: $input-normal;

					&:focus, &:hover {
						background-color: transparent;
					}
				}
			}

			.ximg {
				width: 1.5em;
				height: 1.5em;
			}

			.dropdown-menu {
				padding: 0;
				border: none;

				&.dropdown-section {
					color: $darkest;
					width: $container-lg;
					max-width: 100vw;
					top: $siteNavHeight;
					left: 0 !important;
					padding: 25px 10px;

					margin-top: 0px;

					.section-heading {
						margin-bottom: 15px;
						padding-left: 15px;
						display: flex;
						align-items: center;

						.section-icon-wrap {
							background-color: $gray-four;
							border-radius: 50rem;
							display: inline-flex;
							align-items: center;
							justify-content: center;
							height: $input-tiny;
							width: $input-tiny;

							.ximg {
								height: 100%;
								width: 100%;
								transform: scale(0.7);
							}
						}

						h2 {
							display: inline;
							margin-left: 12px;
						}

						.underlined-link {
							margin-left: 12px;
							font-size: $font-size-base;
							padding: 0.2em 0;
							align-self: flex-end;
						}
					}

					.masonry {
						grid-template-columns: repeat(auto-fill, 25%) !important;
						width: 80%;
					}

					.line-breaks {
						margin-top: 10px;
						height: calc(100% - 115px);
						width: 0px;
						border: solid 1px #eee;
						position: inherit;
					}

					.section-categories {
						display: inline-grid;
						grid-template-columns: repeat(auto-fill, 100%);
						grid-row-gap: 1px;
						grid-auto-rows: 0;

						& > li {
							padding: 0 15px;

							h3 {
								padding: 0.2em 0;
							}

							.sub-category {
								margin-bottom: 18px;

								a {
									font-size: $font-size-base;
									padding: 0.2em 0;
									display: inline; // för att göra klickytan något mindre så man inte råkar klicka på fel?
								}
							}
						}
					}

					a {
						color: #000;
						text-shadow: none;
						padding: 0.6em 0;
					}
				}

				li.dropdown {
					position: relative;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;

					.dropdown-menu {
						z-index: 1;
						display: none;
						margin-top: 0;
						top: 0;
						left: 100%;
					}

					&.hover > .dropdown-menu {
						display: block;
					}
				}

				li.black-arrow {
					background-image: cdn-url('/img/icons/arrow-right-black-large.svg') !important;
				}
			}
		}

		.pull-right .nav .root {
			padding: 8px 4px 8px 0;
			a {
				padding: 0px;
				padding-right: 4px;
			}
			&:not(:last-child) a {
				border-right: 1px solid #404A5D;
			}
		}
	}
}