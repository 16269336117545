/**
 Denna fyller ett element med färgen $brand-primary.
 Usage: div.fill i en div.fill-anchor i elementet du vill fylla
 	lägg på klassen active för att aktivera fillen

tips: fillen ritar sig gärna över andra element. För att slippa bråka med
z-index kan du sätta position: relative på alla siblings så bör de ritas ovanpå
fillen, förutsatt att fillen ligger först
*/

.fill-anchor {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100%;
	height: 100%;
}

.fill {
	background: $brand-primary;
	border-radius: 100%;
	transform: translate(-50%, -50%);
	padding-top: 0%;
	width: 0;
	opacity: 0;
	transition: .3s all;
	transition-timing-function: ease-out;
}

.fill.active {
	width: 140%;
	padding-top: 140%;
	opacity: 1;
	transition: .6s all;
}
