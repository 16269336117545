.stock-modal {
	.stock-small {
		position: relative;
		width: 114px;
	}
	.stock-status {
		min-width: 50px;
	}
	.stock-favorite span {
		display: inline-block;
		background-image: cdn-url('/api/dynimg/icon/heart/D6D6D6');
		background-size: 10px 10px;
		width: 10px;
		height: 10px;
	}

	.stock-favorite input {
		display: none;
	}

	.stock-favorite input[type=checkbox]:checked + span {
		display: inline-block;
		background-image: cdn-url('/api/dynimg/icon/heart/EA83AA');
		background-size: 10px 10px;
		width: 10px;
		height: 10px;
	}
}

.product-list-compact-item .stock-modal {
	margin-top: -1px;
}

.stock-modal-content {
	padding: 12px;
	max-width: 480px;

	.ximg {
		height: 80px;
		width: 80px;
	}
}
