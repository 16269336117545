$colors: (#1E8540 #29BB59 #EEB300 #F0972E #D8824E);
@for $value from 1 through length($colors) {
  	.fynd-class-#{$value} {
    	color: nth($colors, $value);
  	}
}

.fynd-class-default {
	color: #848B8D;
}

.fyndware-background {
	background-color: $fyndware-bg;
  	margin: 2px; 
  	padding: 8px 15px
}

.fyndware-heading {
  	font-size: 25px; 
  	margin-left: 8px
}

.fyndware-paragraph {
  	margin-top: 18px;
}

.fyndware-image {
  	width: 25px; 
  	height: 25px; 
  	align-self: center
}

.fyndware-class {
  	padding: 3px 15px; 
  	align-self: center;
    span:nth-child(1) {
      	font-size: 13px;
    }
    span:nth-child(2) {
      	font-size: 12px;
    }
}