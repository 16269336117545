.welcome-modal {
	.modal-container {
		background: #fff;
		padding: 0;
		width: 900px;
	}

	.link {
		font-weight: bold;
		color: #000;
		font-size: 1.3em;
		position: absolute;
		bottom: 10px;
		padding: 6px;
		&._left {
			left: 26px;
		}
		&._right {
			right: 26px;
		}
	}

	.modal-content {
		color: #000;
		position: relative;
	}

	.content {
		padding: 0px  10px 48px 8px;

		> h2 {
			color: #000;
			padding: 10px 0 10px 0px;
		}
	}

	.slide-content {
		padding: 0 0 0 0;
		margin: 0 12%;
	}
	.plupp {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		display: inline-block;
		margin: 4px;
		background: #D6D6D6;
		transition: all ease-out 0.4s;
		cursor: pointer;
		&.active {
			background: #333;
			transition: all ease-out 0.6s;
		}
	}

}
