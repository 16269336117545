@mixin generateRadius($i, $multiplier) {
	 @include screen-size-class('r-#{$i}-<SIZE>'){
		border-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rt-#{$i}-<SIZE>') {
		border-top-left-radius: ($rounded-radius * $multiplier) !important;
		border-top-right-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rb-#{$i}-<SIZE>') {
		border-bottom-left-radius: ($rounded-radius * $multiplier) !important;
		border-bottom-right-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rl-#{$i}-<SIZE>') {
		border-bottom-left-radius: ($rounded-radius * $multiplier) !important;
		border-top-left-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rr-#{$i}-<SIZE>') {
		border-bottom-right-radius: ($rounded-radius * $multiplier) !important;
		border-top-right-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rtl-#{$i}-<SIZE>') {
		border-top-left-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rtr-#{$i}-<SIZE>') {
		border-top-right-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rbl-#{$i}-<SIZE>') {
		border-bottom-left-radius: ($rounded-radius * $multiplier) !important;
	}
	@include screen-size-class('rbr-#{$i}-<SIZE>') {
		border-bottom-right-radius: ($rounded-radius * $multiplier) !important;
	}
}
@include generateRadius(0, 0);
@include generateRadius(1, .25);
@include generateRadius(2, .5);
@include generateRadius(3, 1);
@include generateRadius(4, 1.5);
@include generateRadius(5, 3);

@include screen-size-class('circle-<SIZE>') {
	border-radius: 9999999px !important;
}