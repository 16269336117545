.newsletter-popup {
	width: 500px;
	max-width: 100%;
	padding: 0 10px 20px;

	h2 {
		margin-bottom: 20px;
	}

	.newsletter-settings {
		display: flex;
		flex-direction: column;

		.text-btn {
			margin: 4px 0;
			width: 100%;
		}
	}

	@media screen and (min-width: $screen-sm-min) {
		.newsletter-settings {
			flex-direction: row-reverse;
			justify-content: space-between;

			.text-btn {
				flex: 1 1 50%;
				max-width: 49%;
			}
		}
	}
}