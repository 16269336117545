/* General styling */
.product-browser {
	width: 100%;
	overflow-anchor: none;

	.failed-search {
		margin: 6px 12px;
	}
}

.product-list-item, .product-list-compact-item, .product-grid-item {
	a {
		@extend .secondary-link;
	}
}

.product-list-small {
	margin-bottom: $spacer-x*3;
	position: relative;
}

.right-col-panel-heading {
	box-shadow: none;
	border: none;
	display: block;
	border-bottom: 2px solid $darkest;
	margin: 8px 0;

	.level-four-heading {
		margin: 6px 0;
	}

	.recently-viewed {
		border-bottom-width: 2px;
		border-radius: 2px;
	}
}

.right-col-panel > .right-col-panel-heading {
	margin-bottom: 4px;
}

.product-list {
	.price-value:not(._regular)._below {
		padding-bottom: 2px;
	}

	@media (min-width: 992px) {
		.checkout-col-lg-3 {
			width: 20%;
		}
	}

	.product-checkout-item {
		background-color: $lightest;
		border: 1px solid $gray-three;
		border-radius: $border-radius;
		box-shadow: none;
		margin: 6px 0;
		padding: 6px;

		.product-info {
			text-align: center;

			.ximg {
				margin-bottom: 8px;
				width: 100%;
				height: auto;
				aspect-ratio: 16 / 9;
			}

			.energy-rating {
				position: absolute;
				top: 10%;
				left: 5px;
			}

			.fake-link.energy-prod-info {
				@extend .secondary-link;
				position: absolute;
				right: 15px;
				bottom: 40%;
				background-color: $lightest;
				font-size: $font-size-tiny;
			}
		}

		.panel-bottom {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			.price-value:not(._regular) {
				margin-bottom: -4px;
			}
		}
	}
}

.product-list-page {
	position: relative;

	& > div, .product-list > div {
		border-bottom: 1px solid $gray-three;

		&.clearfix {
			border: none;
		}
	}
}

.product-list-simple-item {
	overflow: hidden;
	padding: 15px;

	.product-thumb {
		margin-right: 20px;
		line-height: 0;
	}

	.ximg {
		width: 62px;
		height: 62px;
	}
}

.product-tip-item {
	position: relative;

	.ximg {
		width: 100%;
		height: 80px;
	}
}

.product-tip-item-rating {
	color: $gray-two;
	font-size: $font-size-tiny;
	line-height: 0.9;
}

.product-list-item-number {
	z-index: 10;
	color: #55738d;
	font-size: 0.9em;
	font-weight: bold;
	padding: 1px 8px;
	border-bottom-right-radius: 2px;
}

.product-browser-sort-mobile {
	flex-grow: 1;

	.toggle-label.button-dropdown {
		width: 100%;
	}

	.btn-dropdown {
		width: 100%;
	}
}

.product-list-item-title {
	font-size: $font-size-base;
}

.product-list-item-subtitle {
	font-size: $font-size-small;
}

.product-list-subtitle {
	display: block;
	text-decoration: none;
	color: $text-secondary-color;
	font-size: $font-size-small;
}

.product-list-subtitle:hover {
	text-decoration: none;
}

.product-grid-item, .product-list-item, .product-list-item.panel {
	box-shadow: none;
}

/* Grid item */

.product-grid-item.panel-thin {
	display: flex;
	flex-direction: column;

	&.panel {
		box-shadow: none;
		border-radius: 0;
	}

	.panel-body {
		padding: 6px;

		.panel-top {
			text-align: center;
			margin-bottom: 0;

			.price-bubble {
				line-height: 0.8;
				position: absolute;
				right: -4px;
				top: -4px;
			}
		}

		.panel-bottom {
			justify-content: space-between;
			align-items: flex-end;

			.product-tip-item-rating {
				display: inline-flex;
				align-items: center;
				flex: 0 0 auto;
				margin-bottom: 0.6em;

				.review-count {
					margin-left: 3px;
				}
			}

			.price-value {
				flex: 1 1 50%;
				text-align: right;
			}

			@media screen and (max-width: 400px) {
				flex-wrap: wrap;
				align-items: flex-start;

				.product-tip-item-rating, .rating-filler {
					height: 12px;
					min-width: 1px;
					margin-bottom: 0;
					margin-top: -6px;
					order: 2;
					flex: 1 0 100%;
				}

				.price-value {
					order: 1;
					flex: 1 0 100%;
				}
			}

			&.show-stock {
				flex-wrap: wrap;
				align-items: center;
				justify-content: flex-end;

				.product-tip-item-rating, .rating-filler {
					height: 12px;
					min-width: 88px;
					flex: 12 0 auto;
					margin-bottom: 0;
					order: 2;
					margin-top: 0;
				}

				.price-value {
					flex: 1 1 100%;
					order: 1;
				}

				.stock-modal {
					flex: 1 1 50%;
					min-width: 152px;
					order: 3;
					padding-left: 0 !important;
					.stock-bar-wrapper.stock-type-grid {
						justify-content: space-between;
					}

					@media screen and (max-width: 575px) {
						min-width: 124px;
					}
				}
			}
		}
	}

	.ximg {
		display: block;
		padding: 12px;
		margin-bottom: 6px;
		width: 100%;
		height: auto;
		aspect-ratio: 15 / 9;
	}

	.fixed-lines {
		max-height: 4em;
	}

	.product-list-item-title {
		height: auto !important;
		max-height: 4.2em;

		&.title-height {
			max-height: 2.8em;
		}
	}

	.product-list-item-subtitle {
		height: auto !important;
		max-height: 2.8em;
	}
}

.product-highlight-grid-item .ximg {
	margin-bottom: -8px;
	padding-bottom: 8px;
}

.product-tip-grid-item {
	.panel-body {
		padding: 6px 6px 32px 6px;
	}

	.image-link .ximg {
		width: 100%;
		height: auto;
		aspect-ratio: 5 / 2;
	}

	.ximg {
		padding: 6px;
	}
}

/* Grid items in aside */
.product-aside .product-grid-item .panel-body .review-count {
	display: none;
}

/* List item */

.product-list-item {
	height: 101px;

	.product-list-wrap {
		display: flex;
		align-items: center;
		padding: 4px;
		height: 100%;

		.product-image {
			position: relative;
			flex: 1 0 30%;
			width: 28%;
			margin-right: 2%;
			max-width: 100px;
			min-width: 40px;
			line-height: 0;
			padding: 6px;

			@media screen and (max-width: $screen-xs-max) {
				flex: 1 0 25%;
				width: 20%;
				margin-right: 5%;
				padding: 6px 0;
			}

			.ximg {
				width: 100%;
				height: 80px;

				.price-bubble {
					line-height: 0.6;
					position: absolute;
					right: -16px;
					top: -8px;
				}
			}
		}

		.energy-marking-icon {
			position: absolute;
			left: 6px;
			bottom: 16px;
		}

		.energy-marking {
			position: absolute;
			bottom: 6px;
			left: 0;
			margin-left: 6px;

			@media screen and (max-width: $screen-xs-max) {
				margin-bottom: 0;
			}
		}

		.title-wrap {
			position: relative;
			flex: 1 1 55%;
			width: 55%;
			padding: 0 4px;
			height: 100%;
			white-space: normal;
			display: flex;
			flex-direction: column;
			justify-content: center;
			word-break: break-word;

			@media screen and (max-width: $screen-xs-max) {
				flex: 1 1 45%;
				width: 45%;
			}

			@media screen and (max-width: $screen-md-max) {
				flex: 1 1 50%;
				width: 50%;
			}

			.inner-title-wrap {
				max-height: 5.6em;
				display: flex;
				flex-direction: column;
				justify-content: center;

				&.title-height {
					max-height: 4.6em;
				}

				.product-list-item-title {
					line-height: 1.3em !important;
					height: auto !important;
					max-height: 5.7em;

					&.title-height {
						max-height: 2.8em;
					}
				}

				.product-list-item-subtitle {
					line-height: 1.2em !important;
					height: auto !important;
					flex: 2 0 auto;
					max-height: 2.4em;
				}
			}

			.product-ratings {
				position: absolute;
				bottom: 0;
				transform: translateY(4px);
				line-height: 1;
			}
		}

		.product-price {
			position: relative;
			flex: 1 1 30%;
			width: 30%;
			max-width: 7em;
			padding: 0 4px;
			text-align: right;
			align-self: center;

			.price-value {
				margin: 0;
				word-break: keep-all;
			}

			@media screen and (min-width: $screen-md-min) {
				flex: 1 1 20%;
				width: 20%;
				padding-bottom: 0;
			}

			@media screen and (min-width: $screen-lg-min) {
				flex: 1 1 15%;
				width: 15%;
			}
		}

		.stock-modal {
			flex: 1 0 114px;
			padding-left: 12px;

			@media screen and (max-width: $screen-xs-max) {
				flex: none;
				padding: 0 !important;
			}
		}

		.price-bubble._condensed .release-bubble-content {
			.releases {
				font-size: 0.5em;
			}

			.today {
				font-size: 0.9em;
			}
		}
	}
}

/* Compact list item */

.product-list-compact-item {
	height: 64px;

	&:hover .feed-remove-icon {
		display: inline-block;
	}

	.feed-remove-icon {
		display: none;
	}

	.product-list-compact-wrap {
		display: flex;
		align-items: center;
		padding: 4px;
		height: 100%;

		.compact-product-image {
			position: relative;
			flex: 1 0 30%;
			width: 28%;
			margin-right: 2%;
			min-width: 40px;
			max-width: 60px;
			line-height: 0;
			padding: 6px;

			@media screen and (max-width: $screen-xs-max) {
				flex: 1 0 20%;
				width: 17%;
				margin-right: 3%;
				padding: 6px 0;
			}

			.ximg {
				width: 100%;
				height: 40px;

				.price-bubble {
					line-height: 0.6;
					position: absolute;
					right: -16px;
					top: -8px;

					@media screen and (max-width: $screen-xs-max) {
						right: -10px;
					}
				}
			}
		}

		.compact-energy-marking {
			position: absolute;
			bottom: 2px;
			left: 0;
			margin-left: 6px;

			@media screen and (max-width: $screen-xs-max) {
				margin-bottom: 0;
			}
		}

		.compact-title-wrap {
			position: relative;
			flex: 1 1 55%;
			width: 55%;
			padding: 0 4px;
			height: 100%;
			white-space: normal;
			align-self: center;
			display: flex;
			flex-direction: column;
			justify-content: center;
			word-break: break-word;

			@media screen and (max-width: $screen-xs-max) {
				align-self: flex-start;
			}

			.product-list-item-title {
				line-height: 1.2em !important;
				max-height: 1.2em !important;
			}

			.product-list-item-subtitle  {
				line-height: 1.2em !important;
				max-height: 1.2em !important;
			}

			.compact-product-ratings {
				position: absolute;
				bottom: 0;
				transform: translateY(4px);
				line-height: 1;
			}
		}

		.compact-product-price {
			position: relative;
			flex: 1 1 32%;
			width: 32%;
			max-width: 7em;
			padding: 0 4px;
			text-align: right;
			align-self: flex-start;
			align-self: center;

			@media screen and (max-width: $screen-xs-max) {
				align-self: flex-start;
			}

			@media screen and (min-width: $screen-md-min) {
				flex: 1 1 20%;
				width: 20%;
				padding-bottom: 0;
			}

			@media screen and (min-width: $screen-lg-min) {
				flex: 1 1 15%;
				width: 15%;
			}

			.price-value {
				word-break: keep-all;
				margin: 0;
				margin-top: .4em;

				@media screen and (min-width: $screen-md-min) {
					margin-top: 0;
				}
			}
		}
	}
}

.sponsored-list {
	color: $darkest;
	background-color: $lightest;
	border: 1px solid $gray-three;
	font-size: $font-size-tiny;
	width: max-content;
	border-radius: 50em;
	display: inline-flex;
	align-items: center;
	gap: 4px;
	line-height: 0.5;
	position: absolute;

	&.sponsored-compact {
		height: 13px;
		padding: 0 6px;
		left: 0px;
		top: -1px;
	}

	&.sponsored-list-item {
		top: 4px;
		left: 0px;
		right: 12px;
		height: 18px;
		padding: 0 8px;
	}

	&.grid-item {
		height: 20px;
		padding: 0 8px 0 8px;
		bottom: 24px;
		right: -5px;

		&.no-energy {
			bottom: 4px
		}
	}
}