.gift-card-theme-thumb {
	padding: 6px;
	display: inline-block;
	border-radius: 4px;
	height: 72px;
	width: 72px;

	.ximg {
		height: 60px;
		width: 60px;
	}
}

.gift-card-theme-thumb.active {
	box-shadow: 0px 0px 3px rgba(0,0,0,.5);
}