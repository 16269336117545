.category-highlight-description {
	position: absolute;
	left: 74px;
	top: 6px;
	bottom: 6px;
	right: 6px;
	overflow: hidden;
}
.category-highlight-name {
	background-color: #eee;
	border: 1px solid #fff;

	.ximg {
		width: 43px;
		height: auto;
		aspect-ratio: 1 / 1;
	}
}
.category-highlight-info {
	font-size: 12px;
	color: #969ba0;
}

.top-list-section {
	padding: 0 12px;
	margin-top: 4px;
}

.section-page {
	.page-description {
		padding: 12px;
		margin: 0;
	}

	h1 .ximg {
		height: 22px;
		width: 22px;
	}
}