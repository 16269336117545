.author {
	h2 {
		margin-bottom: 10px;
	}

	.authorContainer {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		gap: 10px;

		.ximg.avatar-small {
			height: 50px;
			width: 50px;
		}

		.authorInfo {
			display: flex;
			flex-direction: column;

			.authorname-and-logo {
				display: flex;
				align-items: center;
				gap: 5px;

				.sub-title {
					display: inline-flex;
					align-items: center;
					gap: 5px;
				}
			}
		}
	}
}

#bubble {
	position: relative;
	width: fit-content;
	padding: 2%;
	border-radius: $border-radius;
	background-color: $gray-four;
}

#bubble::before {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	bottom: 100%;
	left: 1.5em;
	border: .75rem solid transparent;
	border-top: none;
	border-bottom-color: $gray-four;
}