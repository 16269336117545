.variant-picker {
	h2 {
		text-align: left;
		margin-bottom: 8px;
	}

	.radio-picker, .image-picker {
		margin-bottom: 16px;
	}

	.image-picker {
		display: flex;
		gap: 8px;

		a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			height: $input-large;
			width: $input-large;
			border: 1px solid $gray-three;
			border-radius: $border-radius;

			&.current {
				border-color: $brand-primary;
				border-width: 2px;
			}

			.ximg {
				width: 95%;
				height: auto;
				aspect-ratio: 15 / 9;
			}

			&.disabled {
				background-color: $gray-four;

				&::after {
					content: '';
					height: 110%;
					width: 1px;
					background-color: $gray-two;
					transform: rotate(44deg) translate(-15px, 15px);
				}

				.ximg {
					opacity: .4;
				}
			}
		}
	}

	.radio-picker .radio-option.disabled .right-text {
		font-weight: $font-weight-normal;
		font-size: $font-size-tiny;
	}
}