.top-panel {
    display: flex;
    text-decoration: none;

    .top-panel-banner {
        flex: 1 1 80%;
        position: relative;
        height: max-content;

        .banner {
            margin: 0;
            padding: 8px 0;
        }
        .banner-button {
            padding: .3em .8em;
            text-decoration: none;
            position: absolute;
            bottom: 25px;
            right: 20px;
            border-radius: $rounded-radius;
            font-weight: $font-weight-semibold;
            font-size: $level-three-heading;
            &:hover {
                text-decoration: none;
            }
        }

        .banner-countdown {
            font-family: "Averta", sans-serif;
            color: $lightest;
            position: absolute;
            top: 40%;
            right: 24%;
            transform: translateX(50%);
        }
    }

    .top-panel-right-section {
        flex: 1 1 20%;
        padding: 8px 0 14px;
        display: flex;
        flex-direction: column;

        .top-panel-contact {
            padding: 0.2em 0.6em;
			flex: 1 0 25%;
			max-height: 25%;

            a {
                color: #000;
                margin-left: .5em;
            }
        }

        .top-panel-recent {
			flex: 1 1 75%;
			max-height: 75%;
			padding: 0 0.6em;
			display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h2 {
                flex: 0 1 10%;
            }

            .panel {
                margin-bottom: 0;
            }

            .top-panel-heading.panel {
                box-shadow: none;
                flex: 0 1 10%;
				& > .panel-heading {
					font-weight: $font-weight-semibold;
					padding: 0;
					font-size: $level-three-heading;
			    }
			}

            .panel.product-list-compact-item {
                font-size: $font-size-base;
                box-shadow: none;
                border-radius: 0;
                flex: 1 1 30%;
                max-height: 30%;
                margin-bottom: 1%;

                &.panel-product:not(:last-child) {
                    box-shadow: 0 3px 2px -2px rgba(0, 0, 0, 0.15);
                }
            }
		}

        .top-panel-social {
			flex: 1 1 75%;
            max-height: 75%;
            padding-left: 0.6em;
            .social-link {
                flex-direction: column;
                justify-content: space-between;
                display: flex;
                height: 100%;
                .social-svg-wrap {
                    border-radius: $rounded-radius;
                    background-color: #273143;
                    flex: 1 1 25%;
                    max-height: 23%;

                    img {
                        height: 100%;
                        object-fit:contain;
                        display: block;
                    }
                }
            }
        }

        @media screen and (max-width: $navbar-min-width) {
            .top-panel-contact {
                display: none;
            }

            .top-panel-recent, .top-panel-social {
                flex: 1 1 100%;
                max-height: 100%;
            }
        }
    }
}