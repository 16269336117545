.side-nav-wrap {
	.nav-path {
		font-weight: $font-weight-semibold;

		.back-arrow {
			@include icon-left-placement('/api/dynimg/icon/chevron_left/1A1A1D', 10px);
			display: flex;
			align-items: center;

			&::before {
				margin-right: 4px;
			}
		}
	}

	.side-nav-header {
		display: block;
		border-bottom: 2px solid $darkest;
		margin-bottom: 8px;

		a {
			@extend .secondary-link;
		}

		h2 {
			margin: 12px 0;
		}

		> * {
			.ximg {
				top: 1px;
				margin-left: -2px;
				margin-right: 4px;
			}
		}
	}

	.nav {
		font-weight: $font-weight-semibold;
	}
}

.nav {
	> li.tile {
		display: flex;
		align-items: stretch;

		a {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 0 8px;
			font-weight: $font-weight-semibold;
			line-height: 1.2;
			transition: all 0.1s ease-in-out;

			.ximg {
				margin-right: 4px;
				position: relative;
				width: 1em;
				height: 1em;
			}

			&.router-link-active,
			&.router-link-active:focus,
			&.router-link-active:active,
			&.router-link-exact-active.router-link-active.active {
				border-radius: inherit;
				background-color: $gray-two;
				color: $lightest;

				.ximg {
					filter: invert(100%) brightness(109%) contrast(105%);
				}

				&:hover {
					background-color: $gray-one;
					color: $lightest;
				}
			}
		}
	}

	> li.selected > a {
		font-weight: $font-weight-semibold;
		padding-right: 20px;

		& > .bg {
			background-image: cdn-url('/img/icons/arrow-down-blue.svg');
		}
	}

	> li > a {
		transition: background-color .1s;

		> .bg {
			background-repeat: no-repeat;
			background-size: 6%;
			background-position: 95% 50%;
		}

		&.active {
			text-overflow: ellipsis;
			padding-right: 20px;
			font-weight: $font-weight-semibold;
		}

		&:hover {
			background-color: transparent;
		}
	}
}