.home, .section-page {
	margin: 0 12px;

	/* General css for home */
	h2, h3 {
		flex: 1 1 100%;
		margin: 0;
		margin-bottom: 10px;
	}

	.progress-space {
		background-color: transparent;
	}

	.show-more-corner {
		position: absolute;
		right: 0;
		margin: 0;
	}


	.section, .parent-section>.section {
		margin-bottom: 10px;
		padding: 15px 0 20px;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		justify-content: center;
	}

	/* Highlight section*/

	.highlight-section {
		header {
			color: $darkest;
			clear: both;
			margin: 0;
			background: none;
			border-bottom: none;
			border-radius: 0px;
			flex: 1 1 100%;
			display: flex;
			flex-wrap: nowrap;
		}

		.highlight-container {
			margin: 0 -2px;
			flex: 1 1 100%;
		}
	}

	/* Top list slider */

	.top-list-slider-wrap {
		h3 {
			margin-bottom: 5px;
		}

		.product-tip-wrap {
			margin: 0;
			flex: 1 0 100%;
		}

		.top-list-reviews {
			display: flex;
			flex-wrap: wrap;
			margin-top: 15px;
			width: 100%;

			.top-review {
				flex: 1 1 50%;
				display: none;

				&:nth-child(2) {
					display: block;
					padding-left: 0px;
				}

				&:last-child {
					padding-right: 0px;
				}

				@media screen and (min-width: $screen-md-min) {
					display: block;
				}

				@media screen and (min-width: $screen-sm-min) {
					max-width: 100%;
				}
			}
		}

		&.top-list-banner {
			h2 {
				text-align: center;
				margin-bottom: 2px;
			}

			.description {
				margin: 0 auto;
			}

			.banner-link {
				flex: 1 1 100%;

				.ximg {
					width: 100%;
					height: auto;
					aspect-ratio: 16 / 9;

					@media screen and (min-width: $screen-md-min) {
						aspect-ratio: 16 / 3;
					}
				}
			}
		}
	}
}

.category-selector {
	.side-nav-header a .ximg {
		width: 1em;
		height: 1em;
	}

	.nav.nav-side li .ximg{
		position: relative;
		margin-right: 2px;
		top: 2px;
		height: 1em;
		width: 1em;
	}
}

/* Category tiles*/

.tiles-body {
	flex: 1 1 100%;
}

.popular-categories-tile {
	margin-top: 0;
	margin-bottom: 11px;
	list-style: none;
	flex: 1 1 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding-inline-start: 0;
	margin: 0 -5px;

	a {
		@extend .secondary-link;
	}

	li {
		flex: 1 1 50%;
		max-width: 50%;
		padding: 5px;
		text-align: center;

		@media screen and (min-width: $screen-md-min) {
			flex: 1 1 25%;
			max-width: 25%;
			padding: 5px;
		}

		.popular-categories-img {
			background: $gray-four;
			border-radius: $border-radius;
			height: 100px;
			display: flex;
			justify-content: center;
			align-items: center;

			.ximg {
				mix-blend-mode: multiply;
				width: 100%;
				max-width: 60%;
				height: 100%;
				max-height: 70%;
			}
		}
		.popular-categories-title {
			margin: 1em 0;
		}
	}

	&.icon-tiles {
		.ximg.popular-categories-img {
			height: 60px;
			padding: 12px 0;

			.ximg-content {
				margin: 12px auto;
			}
		}
	}
}