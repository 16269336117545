.login-form {
	.nav-pills a:hover {
		background-color: rgba(255,255,255,.2);
	}
	label {
		line-height: 1;
	}
	.button-facebook {
		padding: 10px 6px;
		border-radius: 24px;
	}

	.alert-danger {
		padding: 15px 0;
		text-align: center;
	}

	h3 {
		margin-top: 12px;
	}
}