.toggle-switch, .toggle-switch .toggle-knob {
	transition: all 0.2s ease-out;
	user-select: none;
}

.toggle-switch {
	height: 14px;
	width: 24px;
	display: inline-block;
	background-color: $destructive;
	margin-right: 2px;
	border-radius: 30px;
	cursor: pointer;
	padding: 1px;
	border: 1px solid rgba(10,10,10,.3);
	box-shadow: inset 1px 1px 9px -3px rgba(4, 4, 4, 0.08), 1px 2px 6px -2px rgba(0, 0, 0, 0.01);

	input {
		display: none;
	}

	.toggle-knob {
		width: 10px;
		height: 10px;
		display: block;
		background-color: rgba(255, 255, 255, 0.95);
		border: solid 1px rgba(0, 0, 0, 0.1);
		box-shadow: 0 1px 3px rgba(107, 106, 106, 0.26), 0 5px 1px rgba(107, 106, 106, 0.13);
		border-radius: 100%;
	}

	&.active {
		background-color: $success-dark;

		.toggle-knob {
			margin-left: 10px;
		}
	}
}
