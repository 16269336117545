.stars {
    background-image: cdn-url('/img/icons/feedback/star_0.svg');
    height: 12px !important;
    background-size: 12px;
    width: 59px;
    display: inline-block;
	background-position: 0 0;
	background-repeat: repeat-x;

    &.product-display-stars {
        height: 18px !important;
        background-size: 18px;
        width: 90px;
    }
}

.stars-lg {
    height: 19px !important;
    width: 99px;
    background-size: 20px;
}

.stars-content {
    height: 100% !important;
	background-position: 0 0;
    background-size: inherit;
	background-repeat: repeat-x;
}

.stars-content-bg {
	background-image: cdn-url('/img/icons/feedback/star_1.svg');
}

.stars-content-alt-bg {
	background-image: cdn-url('/img/icons/purple-star.svg');
}
