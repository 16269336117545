footer {
	z-index: 1001;
	background-color: $footer-bg;
	position: relative;
	color: $lightest;
	font-size: $font-size-small;
	width: 100%;

	.footer-wrapper {
		max-width: $container-lg;
		padding: 20px 16px;
		margin: 0 auto;

		/* General footer styling */
		a {
			color: $lightest;

			&:hover {
				color: $lightest;
			}
		}

		.level-three-heading {
			margin-bottom: 6px;
		}

		hr {
			margin: 16px 0;
		}

		.logo-info, .footer-links, .footer-icons, .newsletter-subscription {
			max-width: 400px;
			margin: 0 auto;
		}

		/* Footer sub categories */
		.logo-info {
			display: flex;
			flex-direction: column;
			padding-bottom: 10px;

			.footer-logo {
				margin: 0 auto;
				width: 160px;
				height: 56px;
			}
		}

		.footer-links {
			display: flex;
			justify-content: space-between;

			.footer-links-child {
				flex: 1 1 50%;
				width: 48%;
				max-width: fit-content;
			}
		}

		.contact {
			display: flex;
			flex-direction: column;
			align-items: center;

			.socials {
				display: flex;
				gap: 14px;
				height: 30px;
				margin-top: 12px;

				a {
					height: 100%;
				}

				img {
					height: 100%;
				}
			}

			.newsletter-subscription {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				.subscription-input {
					width: 100%;
				}
			}
		}

		.footer-icons {
			max-width: 400px;
			margin: 0 auto;

			.delivery-logos, .footer-logos {
				display: flex;
				justify-content: center;
				margin: 8px 0;

				img {
					height: 100%;
				}
			}

			.delivery-logos {
				gap: 8px;
				height: 15px;
			}

			.footer-logos {
				height: 20px;

				img {
					flex: 1 1 auto;
					object-fit: contain;
				}
			}
		}

		.copyright {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 30px;
			width: 100%;
		}

		@media screen and (min-width: $screen-md-min) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 12px;


			& > hr:first-of-type {
				display: none;
			}

			.logo-info, .footer-links {
				margin: 0;
				flex: 1 1 50%;
				max-width: 46%;
			}

			.contact {
				flex: 1 1 100%;
			}

			.logo-info .footer-logo {
				margin: 0;
			}

			.footer-icons {
				flex: 1 1 100%;

				.footer-logos {
					height: 25px;
				}
			}
		}

		@media screen and (min-width: $screen-lg-min) {
			hr {
				display: none;
			}

			.logo-info, .footer-links, .contact {
				margin: 0;
				flex: 1 1 30%;
				max-width: 29%;
			}

			.logo-info, .contact {
				height: 170px;
				padding: 0;
			}

			.logo-info {
				justify-content: space-between;

				.footer-logo {
					margin: 0;
				}
			}

			.contact {
				align-items: flex-end;

				.newsletter-subscription {
					align-items: flex-end;
					margin-top: auto;
				}
			}
		}
	}
}
