@mixin input () {
	height: $input-normal;
	min-width: $input-normal;

	&._large {
		height: $input-large;
		min-width: $input-large;
		font-size: $level-three-heading;
	}

	&._small {
		height: $input-small;
		min-width: $input-small;
	}

	&._tiny {
		height: $input-tiny;
		min-width: $input-tiny;
	}

	&._center {
		margin: 0 auto;
	}

	&._wide {
		width: 300px;
		max-width: 100%;
	}

	&._stretch {
		width: 100%;
		max-width: 100%;
	}
}

@mixin button-base ($color: $brand-primary) {
	@include input();
	font-size: $font-size-base;
	background-color: $color;
	color: $lightest;
	border: none;
	border-radius: 50rem;

	&:hover {
		text-decoration: none;

		@if $color == $brand-primary {
			background-color: $hover;
			color: $lightest;
		} @else if $color == $gray-one {
			background-color: $darkest;
			color: $lightest;
		} @else if $color == $gray-two {
			background-color: $gray-one;
			color: $lightest;
		} @else if $color == $gray-three {
			background-color: $gray-two;
			color: $darkest;
			.icon {
				filter: invert(100%) brightness(109%) contrast(105%);
			}
		} @else if $color == $gray-four {
			background-color: $gray-three;
			color: $darkest;
		} @else if $color == $destructive {
			background-color: $destructiveHover;
		} @else {
			background-color: $hover;
			color: $lightest;
		}
	}

	&._disabled {
		background-color: $disabled;
		color: $gray-two;
		pointer-events: none;

		span {
			opacity: .7;
		}
	}

	&._secondary {
		background-color: transparent;
		color: $darkest;
		border: 2px solid $color;

		&:hover {
			background-color: transparent;
			color: $hover;
			border-color: $hover;
		}

		&._disabled {
			border: 2px solid $disabled;
			color: $gray-two;
			pointer-events: none;
		}
	}

	&.loading {
		&::after {
			content: '';
			$loader-size: 16px;
			width: $loader-size;
			height: $loader-size;
			left: calc(50% - #{$loader-size} / 2);
			top: calc(50% - #{$loader-size} / 2);
			background-image: none;
			border-radius: 50%;
			border: 2px solid transparent;
			background: conic-gradient(transparent, $lightest) border-box;
			-webkit-mask:
				conic-gradient(#fff 0 0) padding-box,
				conic-gradient(#fff 0 0);
			mask:
				conic-gradient(#fff 0 0) padding-box,
				conic-gradient(#fff 0 0);
			-webkit-mask-composite: destination-out;
			mask-composite: exclude;
			animation: spin 2s linear infinite;

			@keyframes spin {
				0% { transform: rotate(0deg); }
				100% { transform: rotate(360deg); }
			}
		}

		&._tiny::after {
			content: '';
			$loader-size: 12px;
			width: $loader-size;
			height: $loader-size;
			left: calc(50% - #{$loader-size} / 2);
			top: calc(50% - #{$loader-size} / 2);
		}
	}

	&._dark {
		&._disabled {
			background-color: $gray-two;
			color: $darkest;
		}

		&._secondary {
			color: $lightest;

			&:hover {
				color: $gray-three;
			}
		}
	}
}

@mixin icon-placement ($icon-url, $icon-size: 10px) {
	content: "";
	background-image: cdn-url($icon-url);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100%;
	width: $icon-size;
	height: $icon-size;
}

@mixin icon-center-placement ($icon-url: '', $icon-size: 10px) {
	position: relative;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	&::after {
		@include icon-placement($icon-url, $icon-size);
		position: absolute;
		left: calc(50% - #{$icon-size} / 2);
		top: calc(50% - #{$icon-size} / 2);
	}
}

@mixin button-icon-center-placement ($icon-size: 10px) {
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		width: $icon-size;
		height: $icon-size;
		aspect-ratio: $icon-size / $icon-size;
		user-select: none;
	}
}

@mixin icon-left-placement ($icon-url, $icon-size: 10px) {
	&::before {
		@include icon-placement($icon-url, $icon-size);
		background-size: contain;
	}
}

@mixin icon-right-placement ($icon-url, $icon-size: 10px) {
	&::after {
		@include icon-placement($icon-url, $icon-size);
		background-size: contain;
	}
}

@mixin single-icon-button ($color: $brand-primary, $icon-size: 10px) {
	@include button-base ($color);
	@include button-icon-center-placement ($icon-size);
	padding: 0;
	width: $input-normal;
	position: relative;

	&._large {
		width: $input-large;
	}

	&._small {
		width: $input-small;
	}

	&._tiny {
		width: $input-tiny;
	}

	&._corner {
		position: absolute;
		right: calc(-#{$input-normal} / 2);
		top: calc(-#{$input-normal} / 2);

		&._large {
			right: calc(-#{$input-large} / 2);
			top: calc(-#{$input-large} / 2);
		}

		&._small {
			right: calc(-#{$input-small} / 2);
			top: calc(-#{$input-small} / 2);
		}

		&._tiny {
			width: $input-tiny;
			right: calc(-#{$input-tiny} / 2);
			top: calc(-#{$input-tiny} / 2);
		}
	}
}

@mixin center-dropdown-label () {
	&::before {
		content: '';
		width: $dropdownIconWidth;
		height: $dropdownIconWidth;
		flex: 0 1 auto;
	}

	.dropdown-label {
		flex: 1 1 auto;
		text-align: center;
	}
}

@mixin remove-sticky-hover {
	@media (hover: hover) {
		@content;
	}
}
