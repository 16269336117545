@include screen-size-class('absolute-<SIZE>') {
	position: absolute !important;
}

@include screen-size-class('fixed-<SIZE>') {
	position: fixed !important;
}

@include screen-size-class('relative-<SIZE>') {
	position: relative !important;
}

@include screen-size-class('static-<SIZE>') {
	position: static !important;
}
@include screen-size-class('ap-<SIZE>-t') {
	position: absolute !important;
	top: 0 !important;
}
@include screen-size-class('ap-<SIZE>-l') {
	position: absolute !important;
	left: 0 !important;
}
@include screen-size-class('ap-<SIZE>-b') {
	position: absolute !important;
	bottom: 0 !important;
}
@include screen-size-class('ap-<SIZE>-r') {
	position: absolute !important;
	right: 0 !important;
}
@include screen-size-class('ap-<SIZE>-tl') {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
}
@include screen-size-class('ap-<SIZE>-tr') {
	position: absolute !important;
	top: 0 !important;
	right: 0 !important;
}
@include screen-size-class('ap-<SIZE>-bl') {
	position: absolute !important;
	bottom: 0 !important;
	left: 0 !important;
}
@include screen-size-class('ap-<SIZE>-br') {
	position: absolute !important;
	bottom: 0 !important;
	right: 0 !important;
}
@include screen-size-class('ap-<SIZE>-fill') {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	bottom: 0 !important;
	right: 0 !important;
}
@include screen-size-class('ap-<SIZE>-fill-x') {
	position: absolute !important;
	left: 0 !important;
	right: 0 !important;
}
@include screen-size-class('ap-<SIZE>-fill-y') {
	position: absolute !important;
	top: 0 !important;
	bottom: 0 !important;
}