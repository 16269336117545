.member-header {
	background-color: #20244F;
	background-image: cdn-url('/img/member/header_bg.png');
	background-size: cover;
	background-position: center;
	color: $gray-lighter;
	overflow: hidden;

	.member-avatar {
		width: 104px;
		height: 100px;
		background-size: cover;

		.ximg-content {
			transform: scale(.8);
		}
	}

	.member-title {
		margin-left: 104px;
		height: 100px;

		.member-name {
			display: flex;
			align-items: center;
			margin: 0;
			clear: initial;
		}

		.member-class {
			font-size: $font-size-base;
			line-height: $line-height-small;
			margin: 0;
			color: rgb(142, 156, 173);
			display: flex;
			align-items: center;

			img {
				vertical-align: text-top;
				height: 1em;
				margin-right: 0.2em;
			}
		}
	}

	.member-stats {
		min-width: 30%;
		position: relative;
		margin-top: 6px;

		.bottom-stats {
			position: absolute;
			display: flex;
			align-items: center;
			bottom: 0;
			left: 0;
			right: 0;
			padding-left: 8px;
			padding-right: 8px;

			.perks {
				flex-grow: 1;
			}
		}

		.lvl-text {
			color: #8e9cad;
			font-weight: bold;
			height: 24px;
			white-space: nowrap;
		}

		.member-stats-current-user {
			line-height: 1.1;
			font-size: 13px;
			text-align: right;
			white-space: nowrap;

			.member-stats-achievements {
				display: flex;
				align-items: center;
				justify-content: flex-end;
				gap: 4px;

				.text-primary {
					color: #74c5bc;
				}

				.ximg {
					width: 12px;
					height: 12px;
				}
			}
		}

		.friend-buttons {
			line-height: 1.1;
			font-size: 13px
		}
	}


	.achievements {
		display: flex;
		align-items: flex-end;

		.achievement-icons {
			flex-grow: 1;
			overflow: hidden;
			display: flex;

			.achievement-icon {
				height: 100%;
				width: 28px;
				margin-right: 7px;
			}
		}
	}


	.member-supply-drop {
		position: relative;

		.dropper-arrow {
			width: 30px;
			height: 21px;
			position: absolute;
			right: 56px;
			top: 42px;
		}
		.drop-info-wrapper {
			width: 100%;
			@media screen and (min-width: $screen-sm-min) {
				padding-right: 83px;
			}
		}
		.drop-info {
			background-color: #272a4c;
			border: 1px solid rgb(42, 101, 157);
			color: rgb(142, 156, 173);
			margin-right: 84px;
			height: 79px;
			width: 100%;
			font-family: 'Press Start 2P', sans-serif;
			font-size: .68em;
			border-collapse: separate;

			td {
				padding-left: 8px;
			}

			span {
				display: block;
				font-size: 12px;
				line-height: 16px;
			}
		}

		.dropper {
			image-rendering: pixelated;
			width: 79px;
			height: 79px;
			position: absolute;
			border: 1px solid rgb(42, 101, 157);
			right: 0;
		}
	}
}

.member-subpage {
	padding: 12px 16px 0;

	.list-settings {
		padding: 0;
	}
}

.achievement-page .list-settings,
.member-orders .list-settings,
.member-friends .list-settings,
.member-widget-dropdown .list-settings {
	.toggle-btn {
		flex: 1 1 auto;
	}

	@media screen and (max-width: $screen-lg-min) {
		.toggle-btn {
			margin: 6px 3px;

			&:last-of-type {
				margin-right: 0;
			}
		}

		.dropdown-select {
			margin-left: 0;
		}
	}
}

.achievement-page .list-settings {
	@media screen and (max-width: $screen-lg-min) {
		.dropdown-select {
			flex: 1 0 100%;
		}
	}

	@media screen and (max-width: $screen-sm-min) {
		&::before, &::after {
			content: '';
			width: 100%;
			order: 1;
		}
		.toggle-btn {

			&:nth-child(2) {
				margin-right: 0;
			}

			&:nth-child(n + 3) {
				order: 1;
				margin-left: 0;
			}
		}

		.dropdown-select {
			order: 4;
		}
	}

	&.not-current-user {
		@media screen and (max-width: $screen-lg-min) {
			.dropdown-select {
				flex: 0 0 auto;
			}
		}

		@media screen and (max-width: $screen-sm-min) {
			.dropdown-select {
				flex: 1 0 100%;
			}
		}
	}
}

.achievement-page .achievement-updater {
	margin-top: 20px;
}

.member-orders {
	.list-filter-btn {
		margin-bottom: 6px;
	}

	.list-settings {
		@media screen and (max-width: $screen-sm-min) {
			&::before, &::after {
				content: '';
				width: 100%;
				order: 1;
			}

			.toggle-btn {
				&:nth-child(even) {
					margin-right: 0;
				}

				&:nth-child(odd) {
					margin-left: 0;
				}

				&:nth-child(n + 3) {
					order: 1;
				}
			}

			.dropdown-select {
				order: 5;
			}
		}
	}
}

.member-perks {
	.perk-items {
		display: flex;
		align-items: center;

		.perk-link {
			height: 28px;
			filter: drop-shadow(rgba(0, 0, 0, 0.4) 0px 0px 3px);

			.ximg {
				width: 28px;
				height: 28px;

				.ximg-content {
					transform: translateX(-3px);
				}
			}

			@media screen and (min-width: $screen-md-min) {
				height: 35px;

				.ximg {
					width: 35px;
					height: 35px;
				}
			}
		}
	}
}

.perk-modal .modal-container {
	width: 85vw;
	max-width: 500px;
	padding: 16px;
	text-align: center;
	background: rgb(32, 68, 129);
	font-family: 'Press Start 2P', sans-serif;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

	span {
		color: white;
	}

	h2 {
		color: #ff0;
	}

	.perk-description {
		line-height: 1.8;
	}

	.ximg.pixelated {
		padding: 12px;
		width: 100%;
		height: auto;
		aspect-ratio: 1 / 1;
	}
}

.cheevo-counter-link .cheevo-counter {
	display: flex;
	align-items: center;
	line-height: 1;
	margin-right: 2px;
	height: 18px;

	.counter-wrapper {
		white-space: nowrap;
		display: inline-flex;
		align-items: center;
		gap: 8px;

		.ximg {
			width: 18px;
			height: 18px;
		}

		.cheevo-counter-number {
			display: inline-block;
			line-height: 0.7;
			color: #74c5bc;
			margin-left: -2px;
			font-size: 24px;
		}
	}

	@media screen and (min-width: $screen-sm-min) {
		height: 22px;

		.counter-wrapper {
			.ximg {
				width: 22px;
				height: 22px;
			}

			.cheevo-counter-number {
				font-size: 32px;
			}
		}
	}

	.in-progress {
		display: none;
		margin-left: 4px;

		.top {
			color: #74c5bc;
		}

		.bottom {
			white-space: nowrap;
		}

		@media screen and (min-width: $screen-md-min) {
			display: block;
		}
	}
}

.member-avatar, .member-class, .member-progress, .cheevo-counter, .pointer {
	cursor: pointer;
}

.member-friends {
	.list-filter-btn {
		margin-bottom: 6px;
	}

	.friend-list {
		column-count: 1;
		column-gap: 20px;
		column-fill: balance;
		break-inside: avoid;
		page-break-inside: avoid;

		@media screen and (min-width: $screen-sm-min) {
			column-count: 2;
		}

		.card:nth-child(1) { margin-top: 0px; }

		.card {
			width: 100%;
			padding: 10px;
			margin: 20px 0;
			border-radius: $border-radius;
			position: relative;
			background: $gray-four;
			break-inside: avoid;
			page-break-inside: avoid;

			& .chrome-fix {
				bottom: 3px;
				margin-top: 23px;
				position: relative;
			}

			.card-badge {
				position: absolute;
				left: 50%;
				transform: translate(-50%, -2px);
				height: 20px;
				width: 20px;

				&::before {
					content: '';
					top: 50%;
					left: 50%;
					z-index: -1;
					width: 32px;
					height: 1px;
					display: block;
					position: absolute;
					background: $gray-four;
					transform: translateX(-50%);
				}
			}

			.showcase {
				display: flex;
				justify-content: space-between;

				p {
					margin: 0;
				}

				.ximg {
					flex-shrink: 0;
					margin-left: 5px;
					border-radius: 2px;
					height: 55px;
					width: 55px;

					&.clickable {
						border-radius: 0;
						height: 64px;
						width: 64px;
					}
				}
			}

			.review, .question-answer-wrap {
				margin-top: 7px;
				padding: 7px;
			}
		}

		.card-header {
			width: 100%;
			display: flex;

			.ximg {
				flex-shrink: 0;
				margin-right: 8px;
				border-radius: 2px;
				width: 55px;
				height: 55px;
			}
		}

		.card-body {
			margin-top: 13px;
		}

		.card-title {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.username {
			font-size: $level-three-heading;
			display: block;
		}

		.unfriend {
			width: 25px;
			height: 25px;
			display: flex;
			flex-shrink: 0;
			color: white;
			margin-left: auto;
			border-radius: 3px;
			align-items: center;
			justify-content: center;
			background-color: $destructive;

			.ximg {
				width: 15px;
				height: 15px;
				margin: 0;
			}
		}

		.warning-popup {
			padding: 20px;
		}

		.italic {
			font-style: italic;
		}

		.bold {
			font-weight: bold;
		}
	}
}