.price {
	width: 100%;
}

.price-value {
	pointer-events: none;
	font-size: $level-three-heading;
	font-weight: $font-weight-semibold;
	color: $gray-one;
	position: relative;
	display: block;

	&._large {
		line-height: 2.2em;
		font-size: 4em;
	}

	&:not(._large) {
		.level-one-membership {
			.price-icon {
				filter: invert(18%) sepia(90%) saturate(6623%) hue-rotate(244deg) brightness(109%) contrast(106%);
				height: 9px;
				margin-right: -4px;
				margin-top: 0;
			}

			.highlight {
				font-size: $font-size-small;
			}

			.price-type {
				position: relative;
				top: 13px;
				display: flex;
				justify-content: flex-end;
				align-items: center;
			}

			.member-form,
			.level-one-price-text {
				display: none;
			}
		}
	}

	&:not(._regular)._below {
		margin-bottom: .4em;
		.price-value._regular {
			top: 100%;
		}
	}

	&:not(._regular):not(._below) {
		margin-top: .5em;
		&.ex-vat {
			margin-bottom: .35em;
		}
	}

	&:not(._regular)._left {
		.price-value._regular {
			text-align: right;
		}
	}
	&:not(._regular)._right {
		.price-value._regular {
			text-align: right;
		}
	}
	&:not(._regular)._center {
		.price-value._regular {
			text-align: center;
		}
	}
	&:not(._regular)._row {
		margin-top: 0em;
		.price-value._regular {
			display: inline-block;
			position: relative;
		}
	}
	&._regular {
		position: absolute;
		left: 0;
		right: 0;
		font-size: $font-size-small;
		line-height: 0;
		font-weight: $font-weight-normal;
		bottom: 100%;
		text-decoration: line-through;
		color: $gray-two;
	}
	&._campaign {
		color: $destructive;

		.regular-price .regular-price-value {
			text-decoration: line-through;
		}
	}
}

//level one price styling for lists
.quick-search-price .level-one-membership {
	margin-left: -45%;
}

.small.level-one-price {
	color: $brand-primary;
}

.level-one-membership {
	margin-top: 6px;

	.member-form {
		pointer-events: all;
		margin-top: 6px;
	}
}
//end of level one member styling

.price-explainer {
	white-space: nowrap;
	position: absolute;
	height: 0;
	top: -24px;
	display: block;
	left: 0;
	right: 0;
	line-height: 0.35em;

	.price-icon {
		display: inline-block;
		height: 16px;
		width: 16px;
		margin-top: -5px;
	}

	.price-type {
		font-size: $level-three-heading;
		color: $gray-one;
		margin-bottom: 2px;

		&.hide-type {
			visibility: hidden;
		}
	}

	&.ex-vat {
		top: auto;
		bottom: 25px;
	}

	.regular-price, &.ex-vat {
		font-size: $font-size-base;
		color: $text-secondary-color;
		font-weight: $font-weight-normal;

		.price-savings {
			font-size: $font-size-base;
			color: $destructive;
		}
	}

	.sub-title {
		font-weight: $font-weight-normal;
		margin-top: -4px;
	}

	.small {
		.member-form {
			pointer-events: auto;
		}
	}
}

%price-bubble {
	font-weight: $font-weight-semibold;
	line-height: 1.1;
}

.price-bubble {
	@extend %price-bubble;

	.price-icon {
		display: inline-block;
		height: 12px;
		width: 12px;
		margin-top: -1px;
	}

	.price-bubble-content {
		margin-top: -1px;

		.price-savings {
			font-size: $level-two-heading;
		}

		.price-type {
			transform: rotate(1deg);
		}
	}

	.release-bubble-content {
		.new {
			font-size: $level-two-heading;
		}
		.releases {
			font-size: $level-three-heading;
		}

		.today {
			font-size: $level-one-heading;
			margin-top: -4px;
		}
	}
}

.price-bubble._condensed {
	.release-bubble-content {
		margin-top: -4px;
		.releases {
			font-size: 0.6em;
		}

		.today {
			font-size: 1em;
			line-height: 1em;
			margin-top: 0px;

		}
		@media screen and (max-width: $screen-md-max) {
			.releases {
				font-size: 0.5em;
			}

			.today {
				font-size: 0.9em;
			}
		}
	}
}

.highlight-bubble {
	@extend %price-bubble;

	width: 60px;
	height: 60px;
	border: 2px solid #fff;

	.price-savings {
		font-size: $level-three-heading;
	}

	.price-type {
		transform: rotate(1deg);
		font-size: $font-size-small;
	}
}

.split-payment-info {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius;
	background-color: $gray-four;
	margin-bottom: 8px;
	font-size: $font-size-small;
	color: $brand-primary;
	padding: 8px 0;

	.ximg {
		background-color: $darkest;
		border-radius: 100%;
		height: 24px;
		width: 24px;
		margin-right: 8px;

		.ximg-content {
			width: 100%;
			height: auto;
			aspect-ratio: 97 / 80;
			transform: scale(0.75);
		}
	}

	.price {
		font-size: $level-three-heading;
		font-weight: $font-weight-semibold;
		width: auto;
	}
}

.circle-root {
	border-style: solid;
	border-color: $lightest;
	color: $lightest;
	border-radius: 50%;
	box-shadow: 0px 2px 2px -1px rgba(0, 0, 0, 0.2);
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $destructive;

	& > .price-icon { // Bubblan ligger direkt under circle-root i "condensed" vy
		position: absolute;
		top: 6%;
		right: 6%;
		border-radius: 100%;
		box-shadow: 1px 2px 2px -1px rgba(0, 0, 0, 0.3);
	}

	&._member {
		background-color: $brand-primary;
	}

	&._release {
		background-color: $violet-c;
	}

	&._fyndware {
		background-color: $disabled;
		color: $gray-one;
	}
}