.m-auto {
	margin: auto !important;
}

.mt-auto,
.mtl-auto,
.mtr-auto,
.my-auto {
	margin-top: auto !important;
}

.mbr-auto,
.mr-auto,
.mtr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.mbl-auto,
.mbr-auto,
.my-auto {
	margin-bottom: auto !important;
}

.mbl-auto,
.ml-auto,
.mtl-auto,
.mx-auto {
	margin-left: auto !important;
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.mtl-0,
.mtr-0,
.my-0 {
	margin-top: 0 !important;
}

.mbr-0,
.mr-0,
.mtr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.mbl-0,
.mbr-0,
.my-0 {
	margin-bottom: 0 !important;
}

.mbl-0,
.ml-0,
.mtl-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: ($spacer-y*0.25) ($spacer-x*0.25) !important;
}

.mt-1,
.mtl-1,
.mtr-1,
.my-1 {
	margin-top: ($spacer-y*0.25) !important;
}

.mbr-1,
.mr-1,
.mtr-1,
.mx-1 {
	margin-right: ($spacer-x*0.25) !important;
}

.mb-1,
.mbl-1,
.mbr-1,
.my-1 {
	margin-bottom: ($spacer-y*0.25) !important;
}

.mbl-1,
.ml-1,
.mtl-1,
.mx-1 {
	margin-left: ($spacer-x*0.25) !important;
}

.m-2 {
	margin: ($spacer-y*0.5) ($spacer-x*0.5) !important;
}

.mt-2,
.mtl-2,
.mtr-2,
.my-2 {
	margin-top: ($spacer-y*0.5) !important;
}

.mbr-2,
.mr-2,
.mtr-2,
.mx-2 {
	margin-right: ($spacer-x*0.5) !important;
}

.mb-2,
.mbl-2,
.mbr-2,
.my-2 {
	margin-bottom: ($spacer-y*0.5) !important;
}

.mbl-2,
.ml-2,
.mtl-2,
.mx-2 {
	margin-left: ($spacer-x*0.5) !important;
}

.m-3 {
	margin: $spacer-y $spacer-x !important;
}

.mt-3,
.mtl-3,
.mtr-3,
.my-3 {
	margin-top: $spacer-y !important;
}

.mbr-3,
.mr-3,
.mtr-3,
.mx-3 {
	margin-right: $spacer-x !important;
}

.mb-3,
.mbl-3,
.mbr-3,
.my-3 {
	margin-bottom: $spacer-y !important;
}

.mbl-3,
.ml-3,
.mtl-3,
.mx-3 {
	margin-left: $spacer-x !important;
}

.m-4 {
	margin: ($spacer-y*1.5) ($spacer-x*1.5) !important;
}

.mt-4,
.mtl-4,
.mtr-4,
.my-4 {
	margin-top: ($spacer-y*1.5) !important;
}

.mbr-4,
.mr-4,
.mtr-4,
.mx-4 {
	margin-right: ($spacer-x*1.5) !important;
}

.mb-4,
.mbl-4,
.mbr-4,
.my-4 {
	margin-bottom: ($spacer-y*1.5) !important;
}

.mbl-4,
.ml-4,
.mtl-4,
.mx-4 {
	margin-left: ($spacer-x*1.5) !important;
}

.m-5 {
	margin: ($spacer-y*3) ($spacer-x*3) !important;
}

.mt-5,
.mtl-5,
.mtr-5,
.my-5 {
	margin-top: ($spacer-y*3) !important;
}

.mbr-5,
.mr-5,
.mtr-5,
.mx-5 {
	margin-right: ($spacer-x*3) !important;
}

.mb-5,
.mbl-5,
.mbr-5,
.my-5 {
	margin-bottom: ($spacer-y*3) !important;
}

.mbl-5,
.ml-5,
.mtl-5,
.mx-5 {
	margin-left: ($spacer-x*3) !important;
}

.m-auto-xs {
	margin: auto !important;
}

.mt-auto-xs,
.mtl-auto-xs,
.mtr-auto-xs,
.my-auto-xs {
	margin-top: auto !important;
}

.mbr-auto-xs,
.mr-auto-xs,
.mtr-auto-xs,
.mx-auto-xs {
	margin-right: auto !important;
}

.mb-auto-xs,
.mbl-auto-xs,
.mbr-auto-xs,
.my-auto-xs {
	margin-bottom: auto !important;
}

.mbl-auto-xs,
.ml-auto-xs,
.mtl-auto-xs,
.mx-auto-xs {
	margin-left: auto !important;
}

.m-0-xs {
	margin: 0 !important;
}

.mt-0-xs,
.mtl-0-xs,
.mtr-0-xs,
.my-0-xs {
	margin-top: 0 !important;
}

.mbr-0-xs,
.mr-0-xs,
.mtr-0-xs,
.mx-0-xs {
	margin-right: 0 !important;
}

.mb-0-xs,
.mbl-0-xs,
.mbr-0-xs,
.my-0-xs {
	margin-bottom: 0 !important;
}

.mbl-0-xs,
.ml-0-xs,
.mtl-0-xs,
.mx-0-xs {
	margin-left: 0 !important;
}

.m-1-xs {
	margin: ($spacer-y*0.25) ($spacer-x*0.25) !important;
}

.mt-1-xs,
.mtl-1-xs,
.mtr-1-xs,
.my-1-xs {
	margin-top: ($spacer-y*0.25) !important;
}

.mbr-1-xs,
.mr-1-xs,
.mtr-1-xs,
.mx-1-xs {
	margin-right: ($spacer-x*0.25) !important;
}

.mb-1-xs,
.mbl-1-xs,
.mbr-1-xs,
.my-1-xs {
	margin-bottom: ($spacer-y*0.25) !important;
}

.mbl-1-xs,
.ml-1-xs,
.mtl-1-xs,
.mx-1-xs {
	margin-left: ($spacer-x*0.25) !important;
}

.m-2-xs {
	margin: ($spacer-y*0.5) ($spacer-x*0.5) !important;
}

.mt-2-xs,
.mtl-2-xs,
.mtr-2-xs,
.my-2-xs {
	margin-top: ($spacer-y*0.5) !important;
}

.mbr-2-xs,
.mr-2-xs,
.mtr-2-xs,
.mx-2-xs {
	margin-right: ($spacer-x*0.5) !important;
}

.mb-2-xs,
.mbl-2-xs,
.mbr-2-xs,
.my-2-xs {
	margin-bottom: ($spacer-y*0.5) !important;
}

.mbl-2-xs,
.ml-2-xs,
.mtl-2-xs,
.mx-2-xs {
	margin-left: ($spacer-x*0.5) !important;
}

.m-3-xs {
	margin: $spacer-y $spacer-x !important;
}

.mt-3-xs,
.mtl-3-xs,
.mtr-3-xs,
.my-3-xs {
	margin-top: $spacer-y !important;
}

.mbr-3-xs,
.mr-3-xs,
.mtr-3-xs,
.mx-3-xs {
	margin-right: $spacer-x !important;
}

.mb-3-xs,
.mbl-3-xs,
.mbr-3-xs,
.my-3-xs {
	margin-bottom: $spacer-y !important;
}

.mbl-3-xs,
.ml-3-xs,
.mtl-3-xs,
.mx-3-xs {
	margin-left: $spacer-x !important;
}

.m-4-xs {
	margin: ($spacer-y*1.5) ($spacer-x*1.5) !important;
}

.mt-4-xs,
.mtl-4-xs,
.mtr-4-xs,
.my-4-xs {
	margin-top: ($spacer-y*1.5) !important;
}

.mbr-4-xs,
.mr-4-xs,
.mtr-4-xs,
.mx-4-xs {
	margin-right: ($spacer-x*1.5) !important;
}

.mb-4-xs,
.mbl-4-xs,
.mbr-4-xs,
.my-4-xs {
	margin-bottom: ($spacer-y*1.5) !important;
}

.mbl-4-xs,
.ml-4-xs,
.mtl-4-xs,
.mx-4-xs {
	margin-left: ($spacer-x*1.5) !important;
}

.m-5-xs {
	margin: ($spacer-y*3) ($spacer-x*3) !important;
}

.mt-5-xs,
.mtl-5-xs,
.mtr-5-xs,
.my-5-xs {
	margin-top: ($spacer-y*3) !important;
}

.mbr-5-xs,
.mr-5-xs,
.mtr-5-xs,
.mx-5-xs {
	margin-right: ($spacer-x*3) !important;
}

.mb-5-xs,
.mbl-5-xs,
.mbr-5-xs,
.my-5-xs {
	margin-bottom: ($spacer-y*3) !important;
}

.mbl-5-xs,
.ml-5-xs,
.mtl-5-xs,
.mx-5-xs {
	margin-left: ($spacer-x*3) !important;
}
@media(min-width: #{$screen-sm-min}) {
	.m-auto-sm {
		margin: auto !important;
	}

	.mt-auto-sm,
	.mtl-auto-sm,
	.mtr-auto-sm,
	.my-auto-sm {
		margin-top: auto !important;
	}

	.mbr-auto-sm,
	.mr-auto-sm,
	.mtr-auto-sm,
	.mx-auto-sm {
		margin-right: auto !important;
	}

	.mb-auto-sm,
	.mbl-auto-sm,
	.mbr-auto-sm,
	.my-auto-sm {
		margin-bottom: auto !important;
	}

	.mbl-auto-sm,
	.ml-auto-sm,
	.mtl-auto-sm,
	.mx-auto-sm {
		margin-left: auto !important;
	}

	.m-0-sm {
		margin: 0 !important;
	}

	.mt-0-sm,
	.mtl-0-sm,
	.mtr-0-sm,
	.my-0-sm {
		margin-top: 0 !important;
	}

	.mbr-0-sm,
	.mr-0-sm,
	.mtr-0-sm,
	.mx-0-sm {
		margin-right: 0 !important;
	}

	.mb-0-sm,
	.mbl-0-sm,
	.mbr-0-sm,
	.my-0-sm {
		margin-bottom: 0 !important;
	}

	.mbl-0-sm,
	.ml-0-sm,
	.mtl-0-sm,
	.mx-0-sm {
		margin-left: 0 !important;
	}

	.m-1-sm {
		margin: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.mt-1-sm,
	.mtl-1-sm,
	.mtr-1-sm,
	.my-1-sm {
		margin-top: ($spacer-y*0.25) !important;
	}

	.mbr-1-sm,
	.mr-1-sm,
	.mtr-1-sm,
	.mx-1-sm {
		margin-right: ($spacer-x*0.25) !important;
	}

	.mb-1-sm,
	.mbl-1-sm,
	.mbr-1-sm,
	.my-1-sm {
		margin-bottom: ($spacer-y*0.25) !important;
	}

	.mbl-1-sm,
	.ml-1-sm,
	.mtl-1-sm,
	.mx-1-sm {
		margin-left: ($spacer-x*0.25) !important;
	}

	.m-2-sm {
		margin: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.mt-2-sm,
	.mtl-2-sm,
	.mtr-2-sm,
	.my-2-sm {
		margin-top: ($spacer-y*0.5) !important;
	}

	.mbr-2-sm,
	.mr-2-sm,
	.mtr-2-sm,
	.mx-2-sm {
		margin-right: ($spacer-x*0.5) !important;
	}

	.mb-2-sm,
	.mbl-2-sm,
	.mbr-2-sm,
	.my-2-sm {
		margin-bottom: ($spacer-y*0.5) !important;
	}

	.mbl-2-sm,
	.ml-2-sm,
	.mtl-2-sm,
	.mx-2-sm {
		margin-left: ($spacer-x*0.5) !important;
	}

	.m-3-sm {
		margin: $spacer-y $spacer-x !important;
	}

	.mt-3-sm,
	.mtl-3-sm,
	.mtr-3-sm,
	.my-3-sm {
		margin-top: $spacer-y !important;
	}

	.mbr-3-sm,
	.mr-3-sm,
	.mtr-3-sm,
	.mx-3-sm {
		margin-right: $spacer-x !important;
	}

	.mb-3-sm,
	.mbl-3-sm,
	.mbr-3-sm,
	.my-3-sm {
		margin-bottom: $spacer-y !important;
	}

	.mbl-3-sm,
	.ml-3-sm,
	.mtl-3-sm,
	.mx-3-sm {
		margin-left: $spacer-x !important;
	}

	.m-4-sm {
		margin: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.mt-4-sm,
	.mtl-4-sm,
	.mtr-4-sm,
	.my-4-sm {
		margin-top: ($spacer-y*1.5) !important;
	}

	.mbr-4-sm,
	.mr-4-sm,
	.mtr-4-sm,
	.mx-4-sm {
		margin-right: ($spacer-x*1.5) !important;
	}

	.mb-4-sm,
	.mbl-4-sm,
	.mbr-4-sm,
	.my-4-sm {
		margin-bottom: ($spacer-y*1.5) !important;
	}

	.mbl-4-sm,
	.ml-4-sm,
	.mtl-4-sm,
	.mx-4-sm {
		margin-left: ($spacer-x*1.5) !important;
	}

	.m-5-sm {
		margin: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.mt-5-sm,
	.mtl-5-sm,
	.mtr-5-sm,
	.my-5-sm {
		margin-top: ($spacer-y*3) !important;
	}

	.mbr-5-sm,
	.mr-5-sm,
	.mtr-5-sm,
	.mx-5-sm {
		margin-right: ($spacer-x*3) !important;
	}

	.mb-5-sm,
	.mbl-5-sm,
	.mbr-5-sm,
	.my-5-sm {
		margin-bottom: ($spacer-y*3) !important;
	}

	.mbl-5-sm,
	.ml-5-sm,
	.mtl-5-sm,
	.mx-5-sm {
		margin-left: ($spacer-x*3) !important;
	}
}
@media(min-width: #{$screen-md-min}) {
	.m-auto-md {
		margin: auto !important;
	}

	.mt-auto-md,
	.mtl-auto-md,
	.mtr-auto-md,
	.my-auto-md {
		margin-top: auto !important;
	}

	.mbr-auto-md,
	.mr-auto-md,
	.mtr-auto-md,
	.mx-auto-md {
		margin-right: auto !important;
	}

	.mb-auto-md,
	.mbl-auto-md,
	.mbr-auto-md,
	.my-auto-md {
		margin-bottom: auto !important;
	}

	.mbl-auto-md,
	.ml-auto-md,
	.mtl-auto-md,
	.mx-auto-md {
		margin-left: auto !important;
	}

	.m-0-md {
		margin: 0 !important;
	}

	.mt-0-md,
	.mtl-0-md,
	.mtr-0-md,
	.my-0-md {
		margin-top: 0 !important;
	}

	.mbr-0-md,
	.mr-0-md,
	.mtr-0-md,
	.mx-0-md {
		margin-right: 0 !important;
	}

	.mb-0-md,
	.mbl-0-md,
	.mbr-0-md,
	.my-0-md {
		margin-bottom: 0 !important;
	}

	.mbl-0-md,
	.ml-0-md,
	.mtl-0-md,
	.mx-0-md {
		margin-left: 0 !important;
	}

	.m-1-md {
		margin: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.mt-1-md,
	.mtl-1-md,
	.mtr-1-md,
	.my-1-md {
		margin-top: ($spacer-y*0.25) !important;
	}

	.mbr-1-md,
	.mr-1-md,
	.mtr-1-md,
	.mx-1-md {
		margin-right: ($spacer-x*0.25) !important;
	}

	.mb-1-md,
	.mbl-1-md,
	.mbr-1-md,
	.my-1-md {
		margin-bottom: ($spacer-y*0.25) !important;
	}

	.mbl-1-md,
	.ml-1-md,
	.mtl-1-md,
	.mx-1-md {
		margin-left: ($spacer-x*0.25) !important;
	}

	.m-2-md {
		margin: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.mt-2-md,
	.mtl-2-md,
	.mtr-2-md,
	.my-2-md {
		margin-top: ($spacer-y*0.5) !important;
	}

	.mbr-2-md,
	.mr-2-md,
	.mtr-2-md,
	.mx-2-md {
		margin-right: ($spacer-x*0.5) !important;
	}

	.mb-2-md,
	.mbl-2-md,
	.mbr-2-md,
	.my-2-md {
		margin-bottom: ($spacer-y*0.5) !important;
	}

	.mbl-2-md,
	.ml-2-md,
	.mtl-2-md,
	.mx-2-md {
		margin-left: ($spacer-x*0.5) !important;
	}

	.m-3-md {
		margin: $spacer-y $spacer-x !important;
	}

	.mt-3-md,
	.mtl-3-md,
	.mtr-3-md,
	.my-3-md {
		margin-top: $spacer-y !important;
	}

	.mbr-3-md,
	.mr-3-md,
	.mtr-3-md,
	.mx-3-md {
		margin-right: $spacer-x !important;
	}

	.mb-3-md,
	.mbl-3-md,
	.mbr-3-md,
	.my-3-md {
		margin-bottom: $spacer-y !important;
	}

	.mbl-3-md,
	.ml-3-md,
	.mtl-3-md,
	.mx-3-md {
		margin-left: $spacer-x !important;
	}

	.m-4-md {
		margin: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.mt-4-md,
	.mtl-4-md,
	.mtr-4-md,
	.my-4-md {
		margin-top: ($spacer-y*1.5) !important;
	}

	.mbr-4-md,
	.mr-4-md,
	.mtr-4-md,
	.mx-4-md {
		margin-right: ($spacer-x*1.5) !important;
	}

	.mb-4-md,
	.mbl-4-md,
	.mbr-4-md,
	.my-4-md {
		margin-bottom: ($spacer-y*1.5) !important;
	}

	.mbl-4-md,
	.ml-4-md,
	.mtl-4-md,
	.mx-4-md {
		margin-left: ($spacer-x*1.5) !important;
	}

	.m-5-md {
		margin: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.mt-5-md,
	.mtl-5-md,
	.mtr-5-md,
	.my-5-md {
		margin-top: ($spacer-y*3) !important;
	}

	.mbr-5-md,
	.mr-5-md,
	.mtr-5-md,
	.mx-5-md {
		margin-right: ($spacer-x*3) !important;
	}

	.mb-5-md,
	.mbl-5-md,
	.mbr-5-md,
	.my-5-md {
		margin-bottom: ($spacer-y*3) !important;
	}

	.mbl-5-md,
	.ml-5-md,
	.mtl-5-md,
	.mx-5-md {
		margin-left: ($spacer-x*3) !important;
	}
}
@media(min-width: #{$screen-lg-min}) {
	.m-auto-lg {
		margin: auto !important;
	}

	.mt-auto-lg,
	.mtl-auto-lg,
	.mtr-auto-lg,
	.my-auto-lg {
		margin-top: auto !important;
	}

	.mbr-auto-lg,
	.mr-auto-lg,
	.mtr-auto-lg,
	.mx-auto-lg {
		margin-right: auto !important;
	}

	.mb-auto-lg,
	.mbl-auto-lg,
	.mbr-auto-lg,
	.my-auto-lg {
		margin-bottom: auto !important;
	}

	.mbl-auto-lg,
	.ml-auto-lg,
	.mtl-auto-lg,
	.mx-auto-lg {
		margin-left: auto !important;
	}

	.m-0-lg {
		margin: 0 !important;
	}

	.mt-0-lg,
	.mtl-0-lg,
	.mtr-0-lg,
	.my-0-lg {
		margin-top: 0 !important;
	}

	.mbr-0-lg,
	.mr-0-lg,
	.mtr-0-lg,
	.mx-0-lg {
		margin-right: 0 !important;
	}

	.mb-0-lg,
	.mbl-0-lg,
	.mbr-0-lg,
	.my-0-lg {
		margin-bottom: 0 !important;
	}

	.mbl-0-lg,
	.ml-0-lg,
	.mtl-0-lg,
	.mx-0-lg {
		margin-left: 0 !important;
	}

	.m-1-lg {
		margin: ($spacer-y*0.25) ($spacer-x*0.25) !important;
	}

	.mt-1-lg,
	.mtl-1-lg,
	.mtr-1-lg,
	.my-1-lg {
		margin-top: ($spacer-y*0.25) !important;
	}

	.mbr-1-lg,
	.mr-1-lg,
	.mtr-1-lg,
	.mx-1-lg {
		margin-right: ($spacer-x*0.25) !important;
	}

	.mb-1-lg,
	.mbl-1-lg,
	.mbr-1-lg,
	.my-1-lg {
		margin-bottom: ($spacer-y*0.25) !important;
	}

	.mbl-1-lg,
	.ml-1-lg,
	.mtl-1-lg,
	.mx-1-lg {
		margin-left: ($spacer-x*0.25) !important;
	}

	.m-2-lg {
		margin: ($spacer-y*0.5) ($spacer-x*0.5) !important;
	}

	.mt-2-lg,
	.mtl-2-lg,
	.mtr-2-lg,
	.my-2-lg {
		margin-top: ($spacer-y*0.5) !important;
	}

	.mbr-2-lg,
	.mr-2-lg,
	.mtr-2-lg,
	.mx-2-lg {
		margin-right: ($spacer-x*0.5) !important;
	}

	.mb-2-lg,
	.mbl-2-lg,
	.mbr-2-lg,
	.my-2-lg {
		margin-bottom: ($spacer-y*0.5) !important;
	}

	.mbl-2-lg,
	.ml-2-lg,
	.mtl-2-lg,
	.mx-2-lg {
		margin-left: ($spacer-x*0.5) !important;
	}

	.m-3-lg {
		margin: $spacer-y $spacer-x !important;
	}

	.mt-3-lg,
	.mtl-3-lg,
	.mtr-3-lg,
	.my-3-lg {
		margin-top: $spacer-y !important;
	}

	.mbr-3-lg,
	.mr-3-lg,
	.mtr-3-lg,
	.mx-3-lg {
		margin-right: $spacer-x !important;
	}

	.mb-3-lg,
	.mbl-3-lg,
	.mbr-3-lg,
	.my-3-lg {
		margin-bottom: $spacer-y !important;
	}

	.mbl-3-lg,
	.ml-3-lg,
	.mtl-3-lg,
	.mx-3-lg {
		margin-left: $spacer-x !important;
	}

	.m-4-lg {
		margin: ($spacer-y*1.5) ($spacer-x*1.5) !important;
	}

	.mt-4-lg,
	.mtl-4-lg,
	.mtr-4-lg,
	.my-4-lg {
		margin-top: ($spacer-y*1.5) !important;
	}

	.mbr-4-lg,
	.mr-4-lg,
	.mtr-4-lg,
	.mx-4-lg {
		margin-right: ($spacer-x*1.5) !important;
	}

	.mb-4-lg,
	.mbl-4-lg,
	.mbr-4-lg,
	.my-4-lg {
		margin-bottom: ($spacer-y*1.5) !important;
	}

	.mbl-4-lg,
	.ml-4-lg,
	.mtl-4-lg,
	.mx-4-lg {
		margin-left: ($spacer-x*1.5) !important;
	}

	.m-5-lg {
		margin: ($spacer-y*3) ($spacer-x*3) !important;
	}

	.mt-5-lg,
	.mtl-5-lg,
	.mtr-5-lg,
	.my-5-lg {
		margin-top: ($spacer-y*3) !important;
	}

	.mbr-5-lg,
	.mr-5-lg,
	.mtr-5-lg,
	.mx-5-lg {
		margin-right: ($spacer-x*3) !important;
	}

	.mb-5-lg,
	.mbl-5-lg,
	.mbr-5-lg,
	.my-5-lg {
		margin-bottom: ($spacer-y*3) !important;
	}

	.mbl-5-lg,
	.ml-5-lg,
	.mtl-5-lg,
	.mx-5-lg {
		margin-left: ($spacer-x*3) !important;
	}
}