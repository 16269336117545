.check-icon {
	vertical-align: middle;
	display: inline-block;
	position: relative;
	&:before {
		content: "";
		border-radius: 2px;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	&:after {
		content: "";
		background-position: center;
		background-repeat: no-repeat;
		background-size: 70%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.check-icon-white {
	@extend .check-icon;
	&:before {
		background-color: #DDD;
	}
}

.check-icon-blue {
	@extend .check-icon;
	&:before {
		background-color: $brand-primary;
	}
}

.check-icon-green {
	@extend .check-icon;
	&:after {
		background-image: cdn-url('/api/dynimg/icon/check/1E8540')
	}
}

.check-icon-checked {
	&:after {
		background-image: cdn-url('/img/check-2.svg');
	}
}

.radio-icon {
	@extend .check-icon;
	&:before {
		border-radius: 1em;
	}
}

.radio-icon-white {
	@extend .radio-icon;
	&:before {
		background-color: #DDD;
	}
}

.radio-icon-blue {
	@extend .radio-icon;
	&:before {
		background-color: $brand-primary;
	}
}

.radio-icon-checked {
	&:after {
		background-image: cdn-url('/img/dot.svg');
	}
}
