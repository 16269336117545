:root {
	font-size: $font-size-base;
	font-weight: $font-weight-normal;
}

/* Headings */
h1, h2 , h3, h4 {
	font-weight: $font-weight-bold;
	margin: 0;

	a {
		font-weight: $font-weight-bold;
	}
}

h1, h2.level-one-heading, h3.level-one-heading, h4.level-one-heading {
	font-size: $level-one-heading-mobile;

	@media screen and (min-width: $screen-md-min) {
		font-size: $level-one-heading;
	}
}

h2, h3.level-two-heading, h4.level-two-heading {
	font-size: $level-two-heading;
}

h3, h2.level-three-heading, h4.level-three-heading {
	font-size: $level-three-heading;
}

h4, h2.level-four-heading, h3.level-four-heading {
	font-size: $font-size-base;
}

/* Text */

small, .small {
	font-size: $font-size-small;
}

.sub-title {
	font-size: $font-size-small;
	color: $text-secondary-color;
}

.tiny {
	font-size: $font-size-tiny;
}

.big {
	font-size: $level-two-heading;
}

strong, b, .semibold, .text-highlight {
	font-weight: $font-weight-semibold;
}

.secondary, .text-secondary {
	color: $text-secondary-color;
}

.text-success {
	color: $success-light;
}

.highlight {
	color: $destructive;
}
