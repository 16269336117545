.supersearch {
	background-color: #f5f5f5;
	border-radius: 5px;
	margin: 0 5px 0 5px;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	.price-range-input {
		display: flex;
		align-items: center;
		.input-group {
			display: block;
		}
	}
	.supersearch-suggestions {
		z-index: 10;
		max-height: 240px;
		overflow-y: auto;
		.list-group-item {
			border-radius: 0 !important;
		}
	}
	.btn-inline:before {
		border-radius: 50px;
		background-color: #828686;
		border: none;
	}
	.btn-inline .glyphicon-remove:before {
			color: #fff;
		}
	.label-default {
		background-color: #5e5f60;
		font-weight: normal;
		display: inline-block;
		margin-bottom: 5px;
		padding-top: 4px;
	}
	.btn-success:before {
		background-color: #53b372;
		border-color: #23843d;
		:hover {
			background-color: #6ae291;
		}
	}
}
