.modal-root {
	position: fixed;
	z-index: 1004;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: 0;
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	overflow-y: scroll;
	padding: $spacer-y*3 $spacer-x*3;
	font-weight: $font-weight-normal;
	max-width: none;
	background: rgba(20, 30, 50, 0.5);
}

.modal-container {
	position: relative;
	margin: auto;
	background: #fff;
	padding: 20px 12px;
	box-shadow: 0px 2px 4px rgba(0,0,0,.25);
	border-radius: $border-radius;
	max-width: 93%;

	&.container {
		max-width: $container-lg - $grid-gutter-width * 2;
	}

	&.workshop-service-report {
		max-width: 350px;
		min-height: 400px;
		padding: 30px 22px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		h2 {
			margin-bottom: 15px;
		}

		.btns {
			display: flex;
			width: 100%;
			justify-content: center;
			margin-top: 30px;
			flex-direction: column;
			padding: 0 20px;
			row-gap: 6px;
		}
	}

	.modal-buttons {
		display: flex;

		&.right {
			justify-content: flex-end;

			.text-btn {
				margin-right: 4px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}

		&.center {
			justify-content: center;
		}
	}
}

html.noscroll {
	overflow-y: hidden;
}

body.noscroll {
	overflow-y: hidden;
}
