.info-link {
	@extend .secondary-link;
	display: block;
	box-shadow: 0 1px 2px rgba(black, 0.15);
	height: 56px;
	overflow: hidden;
	padding-right: 10px;

	div {
		text-decoration: inherit;
	}
}

.info {
	a.tile {
		.one-liner {
			font-size: $font-size-small;
		}

		&:hover , & > :hover {
			text-decoration: none;

			.name {
				text-decoration: underline;
			}
		}
	}

	.member-video {
		margin-top: 20px;
	}
}

.large-btn {
	font-size: 16px;
	font-weight: 700;
	padding: 11px 12px;
}

.chat-btn {
	height: 36px;
	width: 300px;
	font-weight: bold;
	margin-bottom: 18px;
}

.kundforum {
	padding: 7% 5%;
	background-image: cdn-url('/images/620517-infokt2022?raw');
	background-size: cover;
	background-position: 50% 85%;
	background-repeat: no-repeat;
	color: $lightest;
	font-size: 16px;
}

.send-contact-btn {
	display: flex;
	justify-content: flex-end;
}

.contact-btn {
	margin: 5px 0;
}

.kundforum p {
	width:40%;
	margin-bottom:30px!important;
}

.kundforum .show-more-button {
	color: $text-color;
	background: $lightest;
	border: 0px;
	padding: 8px 32px;
	font-weight: $font-weight-semibold;
	font-size: $level-two-heading;
}

@media only screen and (max-width: 900px) {
	.kundforum p {
		font-size: 14px;
		width: 50%;
		margin-bottom: 20px!important;
	}
}