@include screen-size-class('d-<SIZE>-inline-block', 1) {
	display: inline-block !important;
}
@include screen-size-class('d-<SIZE>-inline', 1) {
	display: inline !important;
}
@include screen-size-class('d-<SIZE>-block', 1) {
	display: block !important;
}
@include screen-size-class('d-<SIZE>-flex', 1) {
	display: block !important;
	display: flex !important;
}
@include screen-size-class('d-<SIZE>-inline-flex', 1) {
	display: inline-block !important;
	display: inline-flex !important;
}
@include screen-size-class('d-<SIZE>-table-row', 1) {
	display: table-row !important;
}
@include screen-size-class('d-<SIZE>-table-cell', 1) {
	display: table-cell !important;
}
.d-table-cell {
	@extend td;
}
@include screen-size-class('d-<SIZE>-table', 1) {
	display: table !important;
}
