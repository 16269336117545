.store-selector {
	display: flex;
	gap: 12px;

	.store-selector-list, .store-info-container {
		flex: 1 1 50%;
	}

	.store-info-container {
		display: none;
	}

	.store-selector-list {
		display: flex;
		flex-direction: column;

		.input-field-button-group {
			width: 100%;
		}

		.store-group {
			&:not(:last-child) {
				border-bottom: none;
			}

			.store-group-name {
				margin-bottom: 4px;
			}
		}

		.list-store-group {
			margin-bottom: 10px;

			.list-group-item {
				padding: 10px;
			}

			.store-name, .store-distance {
				float:left !important;
			}

			.store-distance {
				margin-left: 5px;
			}

			.store-location {
				list-style: none;
				cursor: auto;
				margin: 6px 0 5px 0;
				border:none;
				font-size: .8em;
				color: #aaa;

				&:hover {
					background-color: #FFF;
				}

				&:first-child {
					margin-top: 0;
				}
			}

			.store-selector-stock-row {
				display: flex;
				justify-content: space-between;
				align-items: center;

				&:hover {
					background-color: $gray-four;
				}

				.store-name {
					line-height: 1.1;
				}

				.store-stock, .store-distance {
					color: $text-secondary-color;
				}

				.store-stock {
					display: flex;
					align-items: center;

					.icon {
						margin-right: 4px;
					}
				}

				&.selected {
					background-color: $brand-primary;
					color: $lightest;

					&:hover {
						background-color: $hover;
					}

					.sub-title, .store-stock, .store-distance {
						color: $lightest;
					}

					.icon::after {
						filter: $lightest-filter;
					}
				}
			}
		}

		.search-mode {
			margin: 0 auto 14px;
		}
	}

	@media screen and (min-width: $screen-md-min) {
		.store-selector-list {
			border-right: 1px solid $gray-three;
			padding-right: 12px;

			.list-store-group .list-group-item {
				padding: 7px;
				height: 42px;
				line-height: 1.2em;
			}
		}

		.store-info-container {
			display: block;
		}
	}
}
