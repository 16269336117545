.home .parent-section > .section.stream-wrapper,
.section-page .parent-section > .section.stream-wrapper,
.section-page .section.stream-wrapper,
.stream-wrapper {
	width: 100%;
	background-image: cdn-url('/images/751067-Streamhjalpen2024SitespelareBakgrund-1200?raw');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0% 50%, 2% 50%;
	position: relative;
	padding: 12px;
	margin: 0px;
	display: grid;
	justify-items: center;
	align-items: center;
	grid-template-columns: 55% 45%;
	grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end];

	.stream-logo {
		width: 80%;
		grid-column: 1 / span 1;
		grid-row: 1 / span 1;
		margin-top: auto;
	}

	.partner-logos {
		object-fit: contain;
		grid-column: 1 / span 1;
		grid-row: 2 / span 1;
		width: 60%;
	}

	.playerbox {
		padding: 6px;
		grid-column: 2 / span 1;
		grid-row: 1 / span 2;
		width: 100%;
		background: linear-gradient(135deg, $brand-primary 0%, $turquoise 100%);
	}

	.responsive-embed {
		position: relative;
		padding-bottom: 47.5%;
		height: 0;
		margin: 0px 0;
		overflow: hidden;
		background: #000;
	}

	.responsive-embed iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: $screen-sm-max) {
		background-image: cdn-url('/images/751069-Streamhjalpen2024SiteMobilspelareBakgrund?raw');
		background-position: 60% 50%;
		padding: 20px 12px;
		gap: 12px;
		grid-template-columns: 100%;
		grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end row3-start] auto [row3-end];

		.playerbox {
			grid-column: 1 / span 1;
			grid-row: 2 / span 1;
		}

		.stream-logo {
			grid-row: 1 / span 1;
			width: 100%;
			max-width: 500px;
		}

		.partner-logos {
			grid-row: 3 / span 1;
			max-width: 70%;
		}
	}
}