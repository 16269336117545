/* General input styling */

input.input-number, .input-text > input.input-field, input.input-text {
	@include input();
	position: relative;
	padding-left: 8px;
	border-radius: 4px;
	border: 1px solid $input-border;
	color: $text-color;

	&.active, &:focus, &:focus ~ * {
		z-index: 5;
	}

	&:focus, &:focus-visible {
		border-color: $input-border-focus;
		outline: 0;
	}

	&::placeholder, & + .input-placeholder {
		color: $text-secondary-color;
	}

	&.hide-spinner {
		&[type=number]::-webkit-inner-spin-button,
		&[type=number]::-webkit-outer-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;
		}
	}
}

/* Input text */

.input-text {
	position: relative;

	> .input-field {
		width: 100%;
	}

	input.input-field[type=number] {
		-moz-appearance: textfield;
	}

	&.has-placeholder input {
		padding-top: 12px;
		padding-bottom: 2px;
	}

	&.has-icon input, &.has-icon .input-placeholder  {
		padding-left: 2.5em;
	}

	.input-placeholder, .input-marker  {
		position: absolute;
		z-index: 6;
		top: 18%;
		height: 64%;
		display: block;
		display: flex;
		align-items: center;
		pointer-events: none;
		padding-left: 8px;
		color: $input-color-placeholder;
		transition: height .2s;

		span {
			transition: font-size .2s;
		}

		.glyphicon-ok {
			color: $success-dark;
		}

		.glyphicon-remove {
			color: $destructive;
		}
	}

	input:not([disabled]):active, input:not([disabled]):focus, input.nonempty {
		~ .input-placeholder {
			span {
				font-size: $font-size-tiny;
			}

			height: $font-size-tiny;
		}
	}

	&.has-error {
		> input.input-field {
			z-index: 6;
			background-color: $error-light;
			border-color: $destructive;

			+ .input-placeholder {
				color: $destructive;
			}
		}

		&.has-icon {
			.glyphicon {
				z-index: 6;
				filter: none;
				color: $destructive;
			}

			&:after {
				filter: $destructive-filter;
			}
		}
	}

	&.disabled {
		opacity: 0.5;
	}
}

/* Group of input text fields */

.text-group {
	> .text-group-row:not(:first-child) > .input-text > input,
	> .input-text:not(:first-child):not(:first-of-type) > input {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		margin-top: 0;
	}

	> .input-text:not(:last-child) > input {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: -1px;
	}

	> .text-group-row:not(:last-child) > .input-text > input {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
		margin-bottom: -1px;
	}

	> .text-group-row {
		display: flex;

		> .input-text {
			flex: 1 1 auto;

			&:not(:last-child) {
				margin-right: -1px;

				> input {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}

			&:not(:first-child) > input {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
}

/* Input number */

.input-number {
	text-align: center;
	padding: 0;
}

/* Input number field with - + buttons */

.input-number-group {
	@include input();
	display: flex;

	.btn-remove-one {
		border-radius: 50rem 0 0 50rem;
	}

	.btn-add-one {
		border-radius: 0 50rem 50rem 0;
	}

	.input-number {
		border-radius: 0;
		border-width: 1px;
		width: $input-normal;
		text-align: center;
		padding: 0;
	}
}

/* Styling for when inputfield and text-/submit button is next to eachother */

.input-field-button-group {
	display: inline-flex;

	.input-number, .input-text, .input-text > input {
		flex: 1 1 90%;
		border-radius: $border-radius 0 0 $border-radius;
		padding-right: 0;
	}

	.text-btn {
		flex: 1 0 10%;
		padding-right: 12px;
		min-width: fit-content;
		border-radius: 0 50rem 50rem 0;
	}
}

/* Dark styling */

._dark {
	&.input-text > input.input-field {
		background-color: $darkest;
		border-color: $gray-two;
		color: $lightest;

		+ .input-placeholder {
			color: $gray-three;
		}
	}

	&.input-text.has-error {
		> input.input-field {
			background-color: $error-dark;
			border-color: $destructive;

			+ .input-placeholder {
				color: $destructive;
			}
		}

	}
}

/* Overwrite bootstrap styling */

.form-control {
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}
}
