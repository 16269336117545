.info {
	h2:first-child {
		margin-top: 3px;
	}

	p {
		margin-bottom: 13px;
	}

	ul {
		padding-left: 20px;
		color: #000;
		margin-bottom: 13px;
	}

	li {
		color: #333;
	}

	.stores-map {
		.input-field-button-group {
			width: 100%;
		}
	}

	.gift-card-check {
		padding-top: 12px;

		.input-field-button-group {
			width: 100%;
			max-width: 500px;
		}
	}

	.info-page-links {
		height: 60px;

		.ximg {
			height: 42px;
			width: 42px;
		}
	}
}

.info-children-description {
    font-style: italic;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.info-footer {
    clear: both;
}
.info-footer > p {
    text-align: center;
    font-weight: 500;
}
