.supply-drop-box {
	width: 64px;
	height: 64px;
	margin-left: 4px;
	margin-right: 4px;
	margin-bottom: 8px;
	float: left;
	background: $member-bg;
	position: relative;
	box-shadow: 0 0 7px rgba(0,0,0,.2);
}

.supply-drop-item {
	width: 64px;
	height: 64px;
	background: rgb(32, 68, 129);
	cursor: pointer;
}
.supply-drop-item .ximg {
	margin: 10px;
	width: 42px;
	height: 42px;
	transition: all 0.3s;
}
.supply-drop-item:hover .ximg {
	margin: 4px;
	width: 56px;
	height: 56px;
}

.drop-qty {
	position: absolute;
	right: 6px;
	bottom: 0px;
	font-size: 0.7em;
	font-weight: bold;
	color: rgb(234, 165, 86);
	font-family: 'Press Start 2P', sans-serif;
	text-shadow: 2px 2px 0px black;
}

@keyframes cloudScroll {
	from { background-position: 800px 100%; }
	to { background-position: 0px 100%; }
}
#clouds1	{
	background-repeat: repeat-x;
	animation: cloudScroll 80s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/clouds.png');
}
#clouds2	{
	background-repeat: repeat-x;
	animation: cloudScroll 15s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/clouds2.png');
}
.drop-countdown {
	position: absolute;
	left: 0;
	right: 0;
	top: 70%;
	font-family: 'Press Start 2P', sans-serif;
	line-height: 2em;
}
.drop-countdown-header {
	color: white;
	font-size: 12px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.drop-countdown-time {
	font-size: 14px;
	color: rgb(23, 30, 54);
	font-weight: bold;
}
.flat-crate {
	background: cdn-url('/img/member/supply-drop/BOX_flat.png');
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
}

.drop-item-name {
	font-family: 'Press Start 2P', sans-serif;
	color: yellow;
	font-weight: bold;
	font-size: 15px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.drop-item-description {
	font-family: 'Press Start 2P', sans-serif;
	color: white;
	font-size: 13px;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.supply-drop-root .modal-container {
	width: 85vw;
	max-width: 500px;
	padding: 16px;
	text-align: center;
	background: rgb(32, 68, 129);
	font-family: 'Press Start 2P', sans-serif;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

	h3 {
		color: yellow;
	}

	.ximg.pixelated {
		padding: 12px;
		width: 100%;
		height: auto;
		aspect-ratio: 1 / 1;
	}

	span {
		color: white;
	}
}

// SHIP just nu
.drop-container {
	transition: left 0.3s linear;
	position: relative;
	top: 100px;
	width: 300px;

	.drop .ximg {
		position: absolute;
		left: 87px;
		height: 128px;
		width: 128px;

		.ximg-content {
			aspect-ratio: 1 / 1;
		}
	}
}
.drop-count {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  text-align: center;
  font-family: 'Press Start 2P', sans-serif;
  color: #eee;
  text-shadow: 1px 2px 1px rgba(0,0,0,.8);
  font-size: 16px;
  font-weight: bold;
}
.drop { /* @TODO döp om klass */
  position: relative;
  transition: opacity 0.3s linear;
}
.drop-arrow {
  cursor: pointer;
  position: absolute;
  top: 144px;
  width: 56px;
  height: 72px;
}
.drop-arrow._left {
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
  left: 10px;
  background-image: url(/img/member/supply-drop/arrow.png);
}
.drop-arrow._right {
  right: 10px;
  background-image: url(/img/member/supply-drop/arrow.png);
}

// LEVEL-UP
@keyframes sd_bgScroll {
	from {
		background-position: 360px 100%;
	}
	to {
		background-position: 0px 100%;
	}
}
@keyframes sd_bgMountScroll {
	from {
		background-position: 762px 100px;
	}
	to {
		background-position: 0px 100px;
	}
}
@keyframes sd_fgMountScroll {
	from {
		background-position: 500px 170%;
	}
	to {
		background-position: -762px 170%;
	}
}
#sd_shipBackground	{
	background-repeat: repeat-x;
	animation: sd_bgScroll 60s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/ship/supply_levelup_bg.png');
}
#sd_distantMountains	{
	background-repeat: repeat-x;
	animation: sd_bgMountScroll 60s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/ship/supply_levelup_berg_singel.png');
}
#sd_foregroundMountains	{
	background-repeat: no-repeat;
	animation: sd_fgMountScroll 18s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/ship/supply_levelup_berg_singel.png');
}

@keyframes sd_cityBgScroll {
	from {
		background-position: 0 0;
	}
	to {
		background-position: -4608px 0;
	}
}

#sd_cityBg {
	background-repeat: repeat-x;
	animation: sd_cityBgScroll 30s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/car/supply_activity_staden.gif');
}

#sd_cityDropBg {
	background-image: cdn-url('/img/member/supply-drop/car/supply_activity_bg.png');
}

@keyframes sd_sidewalkScroll {
	from {
		background-position: 1024px 100%;
	}
	to {
		background-position: 0 100%;
	}
}

#sd_sidewalk {
	background-repeat: repeat-x;
	animation: sd_sidewalkScroll 5.5s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/car/supply_activity_trottoar.png');
}

@keyframes sd_lightPostScroll {
	from {
		background-position: 400px 100%;
	}
	to {
		background-position: -64px 100%;
	}
}

#sd_lightPost {
	background-repeat: no-repeat;
	animation: sd_lightPostScroll 2s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/car/supply_activity_lyktstolpe.png');
}

@keyframes sd_roadLinesScroll {
	from {
		background-position: 64px 68%;
	}
	to {
		background-position: 0px 68%;
	}
}

#sd_roadLines {
	background-repeat: repeat-x;
	animation: sd_roadLinesScroll 0.41s linear infinite;
	background-image: cdn-url('/img/member/supply-drop/car/road_lines.png');
}

.drop .ximg-content {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.4));
}

.supply-drop-page {
	color:#eee;
	background: $member-bg;
	margin-bottom: -8px;
	overflow: hidden;

	.supply-wrap {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		margin-left: -6px;
		margin-right: -6px;

		.supply-drop-container {
			margin: 0 6px 12px;
			height: 361px;
			overflow-y: auto;
			flex-basis: 192px;
			min-width: 192px;
			flex-grow: 1;
			& > div {
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
			}
		}
	}

	.crate-tab {
		width: 77px;
		height: 56px;
	}

	.active .crate-tab {
		height: 57px;
	}

	.crate-name {
		position: absolute;
		bottom: 10px;
		left: 4px;
		right: 4px;
		font-weight: 900;
		font-size: 0.5em;
		font-family: 'Press Start 2P', sans-serif;
		color: #3097D1;
		text-shadow: 1px 1px 0px rgba(0,0,0,.5);
	}

	.active .crate-name {
		color: #eee;
	}

	.crate-count {
		font-family: 'Press Start 2P', sans-serif;
		color: #eaa556;
		font-size: 0.75em;
		margin: 2px;
		font-weight: bold;
		text-shadow: 1px 2px 0px black;
	}

	.crate-progress-container {
		position: absolute;
		left: 4px;
		right: 4px;
		bottom: 4px;
		height: 5px;
		border: 1px solid #3097D1;
		background: $member-bg;
	}

	.crate-progress-bar {
		height: 100%;
		background-color: #3097D1;

		&._full {
			border-color: brown;
			background-color: #eaa556 !important;
		}
	}

	.nav-tabs {
		a {
			background: #233164;
			padding: 8px;
			border: 1px solid #075783;
			border-bottom: 0;
		}

		.active {
			a {
				background: #3097D1 !important;
				color: #eee;
			}
			.crate-progress-container {
				border-color: #eee;
			}

			.crate-progress-bar {
				background-color: #eee;
			}
		}
	}
}