.question-answer-section {
	.no-feedback {
		font-style: italic;
		text-align: center;
	}

	.show-more {
		text-align: center;
		margin-top: 6px;
	}
}
.question-answer-wrap {
	/* Shared question/answer styling */
	$logo-size: 14px;

	.question, .answer {
		a.username, .fake-link.username {
			@extend .secondary-link;
		}

		.feedback-header {
			height: 32px;
			margin-bottom: 6px;
			display: grid;
			justify-content: space-between;
			grid-template-columns: 40px 1fr auto 1fr;
			grid-template-rows: [row1-start] auto [row1-end row2-start] auto [row2-end];

			.avatar-small {
				grid-column: 1 / span 1;
				grid-row: 1 / span 2;
				width: 32px;
				height: 32px;
				aspect-ratio: 1 / 1;
				border-radius: 100%;
				overflow: hidden;
				margin-right: 8px;

				.ximg-content {
					transform: scale(1.3) translateY(10%);
				}
			}

			.username {
				grid-column: 2 / span 1;
				grid-row: 1 / span 1;
				line-height: 0.6;
				height: $logo-size;
				display: flex;
				align-items: center;
				font-size: $font-size-base;
			}

			.level {
				grid-column: 2 / span 2;
				grid-row: 2 / span 1;
				line-height: .8;
				align-self: flex-end;
			}

			.posted {
				grid-column: 3 / span 2;
				grid-row: 1 / span 1;
				place-self: end;
				line-height: 0.8;
				align-self: center;
			}

			.votes {
				grid-column: 4 / span 1;
				grid-row: 2 / span 1;
				place-self: end;
				align-self: center;
				display: flex;
			}

			.knighthood-icon {
				height: 18px;
				width: 18px;
			}
		}

		.reply {
			font-weight: $font-weight-semibold;
		}
	}

	.show-more {
		margin-left: 20px;

		a {
			font-weight: $font-weight-semibold;
		}
	}

	/* For pages with compact reviews, for exmple friend list */

	&.compact {
		border: 1px solid $gray-three;
		border-radius: $border-radius;
		background: $lightest;

		hr {
			display: none;
		}

		.feedback-header {
			margin-bottom: 4px;

			.username {
				font-weight: $font-weight-semibold;
			}
		}

		.question {
			margin-bottom: 0;

			.text {
				font-weight: $font-weight-normal;
			}
		}
	}

	/* Question */

	.question {
		margin-bottom: 8px;

		.text {
			font-weight: $font-weight-semibold;
			margin-bottom: 4px;
		}

		.username-and-knighthood {
			display: flex;
			align-items: center;
		}
	}

	/* Answer */

	.answer {
		margin-left: 20px;

		.username {
			display: flex;

			.wh-logo {
				width: $logo-size;
				height: $logo-size;
				font-size: $logo-size;
				margin-left: 4px;

				&::after {
					$logo-scale: 0.6;
					$logo-position: calc((#{$logo-size} / #{$logo-scale}) / 2 * -1);
					transform: scale($logo-scale) translate($logo-position, $logo-position);
				}
			}
		}

		.username-and-knighthood {
			display: flex;
			align-items: center;
		}
	}
}