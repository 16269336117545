.product-grid-item {
	margin-top: 6px;
}

.product-grid-item, .product-highlight-grid-item {
	.energy-prod-info {
		font-size: .75em;
		line-height: 1;
		color: $text-secondary-color;
		font-weight: 300;
		background-color: white;
	}
}
.product-list-item, .product-list-compact-item {
	.energy-prod-info {
		bottom: 100%;
		color: $text-secondary-color;
		font-size: .75em;
		line-height: 1;
	}
}
