@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin screen-size-class($class, $direction: 0) {
	.#{str-replace(str-replace(str-replace($class,'-<SIZE>-','-'),'-<SIZE>',''),'<SIZE>-','')} {
		@content;
	}
	.#{str-replace($class,'<SIZE>','xs')} {
		@if $direction > 0 {
			@content;
		} @else {
			@media (max-width: $screen-xs-max) {
				@content;
			}
		}
	}
	.#{str-replace($class,'<SIZE>','sm')} {
		@if $direction == 0 {
			@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
				@content;
			}
		} @else {
			@media (#{if($direction > 0, "min-width: #{$screen-sm-min}", "max-width: #{$screen-sm-max}")}) {
				@content;
			}
		}
	}
	.#{str-replace($class,'<SIZE>','md')} {
		@if $direction == 0 {
			@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
				@content;
			}
		} @else {
			@media (#{if($direction > 0, "min-width: #{$screen-md-min}", "max-width: #{$screen-md-max}")}) {
				@content;
			}
		}
	}
	.#{str-replace($class,'<SIZE>','lg')} {
		@if $direction < 0 {
			@content;
		} @else {
			@media (min-width: $screen-lg-min) {
				@content;
			}
		}
	}
}

@function sqrt($r) {
  $x0: 1;
  $x1: $x0;

  @for $i from 1 through 10 {
    $x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
    $x0: $x1;
  }

  @return $x1;
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

@function cdn-url($url){
	@if (str-index($url, '/') == 0 or $env == 'development' or $env == 'staging' or str-index($url, '/') == 0) {
		@return url(#{$url});
	}
  @return url(#{$cdnUrl}#{$url});
}

@import "helpers/padding.scss";
@import "helpers/margin.scss";
@import "helpers/display.scss";
@import "helpers/rounded.scss";
@import "helpers/hover-saver.scss";
@import "helpers/position.scss";
@import "helpers/flex.scss";
@import "helpers/vertical-align.scss";
@import "helpers/border.scss";

.cover-view {
	position: fixed;
	top: -500px;
	bottom: -500px;
	left: 0;
	right: 0;
	z-index: 101;
	padding-top: 500px;
	padding-bottom: 500px;
}

@include screen-size-class('stretch-<SIZE>-x') {
	width: 100% !important;
};
@include screen-size-class('stretch-<SIZE>-y') {
	height: 100% !important;
};

.overflow-hidden {
	overflow: hidden;
}

.clickable {
	cursor: pointer;
}

.clickable.disabled {
	cursor: not-allowed !important;
}

.underline {
	text-decoration: underline;
}
