.payments-page {
	/* Elements */

	h3, h4 {
		display: flex;
		align-items: center;

		&.pay-h3-margin {
			margin-top: 8px;
			margin-bottom: 7px;
		}
	}

	.pay-icon {
		width: 28px;
		height: 28px;
		background-color: $gray-three;
		border-radius: 100%;
		margin-right: 6px;
		display: inline-flex;
		justify-content: center;
		align-items: center;

		img {
			height: 20px;
			width: 20px;
		}

		&.pay-remaining-dept {
			background-color: $member-bg;
			font-weight: $font-weight-semibold;

			img {
				margin-top: -3px;
			}
		}
	}

	.panel-gray {
		background-color: $gray-four;
	}

	.pay-white {
		color: $lightest;
	}

	.pay-info-label {
		font-size: $font-size-base;
		line-height: 1.2em;
	}

	.pay-glyph-circle {
		font-size: 3em;
		color: $brand-primary;
	}

	.pay-img {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 15px;
	}

	.pay-info-value._big {
		font-size: $level-two-heading;
		font-weight: $font-weight-semibold;
	}

	.input-field-button-group {
		align-items: center;

		input {
			flex: 1 1 90%;
			border-radius: $border-radius 0 0 $border-radius;
			padding-right: 0;
		}
	}

	/* Login page */

	.wh-bankid {
		text-align: center;

		h3 {
			justify-content: center;
		}

		.wh-bankid-form {
			display: flex;
			flex-direction: column;
			max-width: 350px;
			margin: 0 auto;

			label {
				font-weight: $font-weight-semibold;
			}

			.form-control {
				text-align: left;
			}
		}
	}

	/* Payments tab */

	.payments-tab {

		.text-center h3 {
			justify-content: center;
		}

		.panel-body {
			h4 {
				display: flex;
				align-items: center;
			}

			.pay-row {
				background-image: cdn-url('/img/icons/delbetalning_coin.svg');
				background-repeat: no-repeat;
				background-size: 14px;
				background-position: 4px 16px;
				padding-left: 30px;
				margin-top: 9px;
				padding-top: 6px;
				border-top: 1px solid $gray-three;

				.pay-date {
					color: $text-secondary-color;
					font-size: $font-size-base;
					line-height: 0.9em;
				}

				.pay-row-amount{
					padding-top: 7px;
					font-size: $level-three-heading;
					font-weight: $font-weight-semibold;
				}
			}

			&.calendar {
				.select-wrap {
					display: flex;
					justify-content: flex-end;
					margin-top: 4px;
					flex-direction: row;

					.dropdown-select {
						flex: 0 1 auto;

						&:last-child {
							margin:  0 0 0 8px;
						}

						.dropdown-list ul {
							max-height: 200px;
							overflow-y: scroll;

							&::-webkit-scrollbar {
								width: 4px;
								position: absolute;
							}

							/* Handle */
							&::-webkit-scrollbar-thumb {
								background: $gray-three;
								border-radius: 50rem;
							}

							/* Handle on hover */
							&::-webkit-scrollbar-thumb:hover {
								background: $gray-two;
							}
						}
					}

					@media screen and (max-width: $screen-sm-min) {
						flex-direction: column;

						.dropdown-select {
							flex: 1 1 auto;
							width: 100%;

							&:last-child {
								margin: 8px 0 0;
							}
						}
					}
				}
			}
		}

		.pay-remaining-dept {
			display: flex;
			align-items: center;
			background-color: $gray-four;

			.pay-icon {
				background-color: $member-bg;

				img {
					margin-top: -3px;
				}
			}

			.amount {
				margin-left: auto;
				font-weight: $font-weight-semibold;
			}
		}

		.reminder-text {
			font-weight: $font-weight-semibold;
			font-size: $font-size-base;
		}

		.dark-panel {
			background-color: $gray-one;
		}
	}

	/* Account tab */

	.account-tab {
		.pay-input-text {
			width: 80%;
			max-width: 288px;
		}
	}
}
