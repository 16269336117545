#product-list-filter-container {
	.header-toggle {
		margin: 0 -4px;
		padding: 0 4px;
		cursor: pointer;
	}

	.panel .placeholder {
		font-size: $font-size-small;
		font-style: italic;
	}
}

.product-list-filters .filter-contents {
	.text-btn._destructive {
		margin: 8px 0;
		width: 100%;
	}

	&._flash {
		animation: flash linear 1s infinite;
		@keyframes flash {
			0% { background: rgba(255, 232, 174, 1) }
			50% { background: rgba(255, 232, 174, 0) }
			100% { background: rgba(255, 232, 174, 1) }
		}
	}

	.clearfix {
		margin-bottom: 4px;
	}

	@media screen and (max-width: $screen-md-max) {
		h3 {
			margin-bottom: 8px;
		}

		.checkbox-wrap {
			margin: 4px 0;
		}

		.input-number,
		.filter-category-back,
		.filter-category ul,
		.filter-box .filter-box-value-count {
			font-size: $font-size-base;
		}

		.slider {
			margin: 18px 0;
		}
	}
}

.filter-box {
	margin: 12px 0;

	&.active {
		.slider {
			.slider-bar {
				background-color: $brand-primary;
			}

			.slider-handle.slider-handle-left, .slider-handle.slider-handle-right {
				border: 2px solid $brand-primary;
			}
		}
	}

	.filter-box-value-text {
		max-width: 70%;
		flex: 1 2 50%;
	}
	.filter-box-value-count {
		font-size: $font-size-small;
		flex: 0 1 auto;
	}
}

.product-list-filter-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	z-index: 9999;
}

.product-list-filters {
	h3 {
		margin-bottom: 4px;
		margin-top: 4px;
	}

	.input-number {
		font-size: $font-size-small;
		padding: 6px 0;
		text-align: center;

		label {
			min-height: auto;
		}

		.filter-box-value-text {
			flex: 2 1 50%;
		}

		.filter-box-value-count {
			flex: 2 1 10%;
			text-align: right;
			position: relative;
			color: $text-secondary-color;
		}
	}

	.inactive .slider-bar {
		background: #b1c1ca;
	}

	.toggle-icon {
		color: #969BA0;
		font-size: 10px;
		top: 4px;
		text-decoration: none !important;
	}

	.panel {
		margin-top: 8px;
		padding: 4px 8px;

		& .panel {
			box-shadow: none;
			border: none;
			margin: 0;
			padding: 0;
			margin-bottom: 8px;
			padding-bottom: 12px;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}
		}

		&._flash {
			animation: flash linear 1s infinite;

			@keyframes flash {
				0% { background: rgba(255, 232, 174, 1) }
				50% { background: rgba(255, 232, 174, 0) }
				100% { background: rgba(255, 232, 174, 1) }
			}
		}
	}

	&.unhooked {
		min-height: 100vh;
		position: absolute;
		top: 0;
		transition: left .25s linear;
		width: 75vw;
		max-width: 400px;
		background: $lightest;
		z-index: 9999;

		.filter-list-header {
			padding: 6px 0;
			margin: 8px 16px 0 16px;
			border-bottom: 2px solid $darkest;

			img {
				width: 18px;
			}
		}

		.panel {
			box-shadow: none;
			border: none;
			margin: 0;
			padding: 0;
			margin-bottom: 8px;
			padding-bottom: 12px;

			&:not(:last-child) {
				border-bottom: 1px solid #ddd;
			}
		}

		.filter-contents {
			padding: 12px;
		}

		.clearfix {
			height: $input-normal;
		}
	}
}

.price-range-input {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;

	.input-number {
		width: 45%;
	}
}

.product-list-filters-bg {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: rgba(50,50,50,0.4);
	z-index: 9998;
	transition: background .25s linear;

	&.fade {
		background: rgba(0,0,0,0);
	}
}

.filter-list-header {
	display: flex;
	align-items: center;
	padding: 0 2px;
	margin-top: 8px;

	&>img {
		width: 15px;
		margin-right: 4px;
	}
}

.filter-category-header {
	display: flex;
	justify-content: space-between;
	font-size: $font-size-base;
	font-weight: $font-weight-semibold;
	margin: 4px 0;

	h3 {
		margin: 0;
		display: flex;
		align-items: center;
		flex: 1;
	}
}

.filter-category-back {
	display: block;
	padding: 0 0 0 10px;
	color: $text-secondary-color;
	font-size: $font-size-small;
	cursor: pointer;
	background-image: cdn-url('/img/icons/tillbakapil_c5cad0.svg');
	background-position: 0 center;
	background-repeat: no-repeat;
	background-size: 6px auto;
}

.filter-category {
	.category-filter {
		flex: 1 1 auto;

		.filter-box-value-text {
			max-width: 70%;
			flex: 1 2 50%;
		}

		.filter-box-value-count {
			flex: 2 1 10%;
		}

		.checkbox-wrap {
			flex-basis: 100%;

			.checkbox-count {
				margin-left: auto;
			}
		}
	}

	ul > li > a:hover {
		text-decoration: none;
	}

	ul > li > label input {
		margin: 0;
		position: relative;
		top: 2px;
		margin-right: 4px;
	}

	.categoryimg {
		position: relative;
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center center;
		margin-right: 4px;
		display: inline-flex;
	}

	ul li {
		margin: -5px 0 10px 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	ul {
		list-style: none;
		margin-top: 1em;
		padding: 0;
		font-size: $font-size-small;
	}

	ul > li > a,
	ul > li > label {
		padding-top: 0;
		padding-bottom: 0;
		color: $text-color;
		font-weight: $font-weight-normal;
		margin: 0;
		display: flex;
		align-items: center;
		gap: 4px;
	}
}

.filter-box-inactive {
	color: $disabled;
}
