.customer-survey .customer-survey-rating {
	width: fit-content;
	max-width: 100%;

	.toggle-group {
		max-width: 100%;

		.toggle-btn {
			min-width: auto;
			width: $input-normal;
		}
	}
}