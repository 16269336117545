.badge-primary {
	background-color: $brand-primary;
}

.badge-small {
	height: 1.25em;
	font-size: .8em;
	display: inline-flex;
	padding: 0 .3em;
	align-items: center;
	margin-bottom: 2px;
}
