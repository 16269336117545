.checkbox-wrap._picker, .cto-attribute._picker {
	overflow: hidden;
	position: relative;
	transition: color .4s ease 0s;
	border: 1.5px solid $gray-three;
	height: 48px;
	padding: 0 10px;
	margin: 0 0 -1px;

	.checkbox {
		width: $input-check-radio-small;
		height: $input-check-radio-small;
	}

	&._checked {
		border-color: $brand-primary;
		z-index: 1;
		border-width: 2px;
		margin: 0px 0 -2px;
	}
}

.checkbox-wrap {
	display: flex;
	align-items: center;
	font-weight: $font-weight-normal;
	margin: 2px 0;
	cursor: pointer;

	input[type="checkbox"] {
		margin: 0;
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	.checkbox {
		flex: 0 0 auto;
		margin: 0 8px 0 0;
		background-color: $lightest;
		border: 2px solid $gray-three;
		border-radius: 1px;
		width: $input-check-radio;
		height: $input-check-radio;

		&.checked {
			border: none;
			background-color: $brand-primary;
		}

		.checkmark::after {
			$checkmark-height: calc(#{$input-check-radio} * 0.4);
			$checkmark-width: calc(#{$input-check-radio} * 0.2);
			height: $checkmark-height;
			width: $checkmark-width;
			transform: scaleX(-1) rotate(135deg) translate(calc(#{$checkmark-width} * -1), calc(#{$checkmark-width} * -1));
		}
	}

	.checkbox-label {
		flex: 1 1 auto;
		color: $darkest;
		display: flex;
		align-items: center;
	}

	.checkbox-count {
		align-self: flex-end;
	}

	&._small {
		.checkbox {
			width: $input-check-radio-small;
			height: $input-check-radio-small;

			.checkmark::after {
				$checkmark-height: calc(#{$input-check-radio-small} * 0.4);
				$checkmark-width: calc(#{$input-check-radio-small} * 0.2);
				height: $checkmark-height;
				width: $checkmark-width;
				transform: scaleX(-1) rotate(135deg) translate(calc(#{$checkmark-width} * -1), calc(#{$checkmark-width} * -1));
			}
		}

		.checkbox-label, .checkbox-count {
			font-size: $font-size-small;
		}
	}

	&._disabled {
		pointer-events: none;
		cursor: initial;

		.checkbox {
			border: 2px solid $gray-three;
			background-color: $lightest;
			pointer-events: none;
			cursor: initial;

			.checkmark::after {
				border-color: $gray-three;
			}
		}

		.checkbox-label {
			color: $disabled;
		}
	}

	&._dark {
		.checkbox {
			background-color: transparent;
			border: 2px solid $gray-two;

			&.checked {
				border:none;
				background-color: $brand-primary;
			}
		}

		&._disabled {
			.checkbox {
				border: 2px solid $gray-two;
				background-color: transparent;
				pointer-events: none;
				cursor: initial;

				.checkmark::after {
					border-color: $gray-two;
				}
			}

			.checkbox-label {
				color: $disabled;
			}
		}
	}
}
