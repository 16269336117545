.config-modal-container {
	width: 85vw;
	max-width: 550px;
		p {
			color: #969ba0;
		}
		.ximg {
			max-height: 66vh;
		}
}
.inactive-color {
	color: #b2b2b2;
}
