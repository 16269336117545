#background {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	will-change: transform; // testar optimeringsgrej
	transition: background .5s linear;
	&.admin {
		background-image: cdn-url('/img/backgrounds/body-default.svg');
	}
}

.takeover-container {
	display: flex;
	justify-content: space-between;
	position: relative;
	width: 100%;
	height: 110vh;
}

.takeover-link {
	position: absolute;
	top: 0;
	bottom: 0;
	overflow: hidden;
}

.takeover-image {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 200%;
	background-position: center top;
	background-size: cover;
	transition: background-image .5s;
}