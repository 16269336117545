.product-tip-wrap {
	margin: 4px;
	white-space: nowrap;
	overflow: hidden;
	position: relative;

	.btn-scroll {
		position: absolute;
		top: 25%;
		z-index: 1;
	}

	.btn-scroll-right {
		right: 0;
	}

	.product-tip-slider-nav {
		border: none;
		background-image: none;
		background-color: transparent;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
		box-shadow: none;
		border-radius: 2px;
		box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
		display: block;
		background: white;
		position: absolute;
		z-index: 1;
		top: 25%;
		bottom: 0;
		background-repeat: no-repeat;
		height: 4rem;
		width: 4rem;
		padding: 2rem 1.5rem;
		transition: opacity 0.2s linear;
	}

	.btn-scroll-left {
		left: 0;
	}
}

.product-tip-slider-anchor {
	position: relative;
	will-change: transform;
	display: flex;

	.product-tip-slider-anchor-wrap {
		padding: 4px;
		white-space: normal;
	}
}

.product-tip-slider-progress-text {
	color: $text-secondary-color;
	font-size: 75%;
}

/* Styling when there's a title */

.product-tip-title.product-tip-wrap {
	h2 {
		font-size: 1.4em;
		font-weight: 700;
		margin: 2px;
		padding: 6px 6px 8px;
		display: inline-block;
	}
}
