.store-pickup-only, .mail-order-only {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;

	.text {
		font-size: $font-size-small;
	}

	.glyphicon-home, .glyphicon-envelope {
		margin-top: 1px;
	}

	&:not(.light) {
		font-weight: $font-weight-semibold;
		border-radius: $border-radius;
		background-color: $gray-two;
		color: $lightest;
		margin-bottom: 2px;
		padding: 2px 5px 1px;
	}

	&.light {
		color: $destructive;

		hr {
			margin: 0;
		}

		.text {
			margin: 4px 0;
		}

		.glyphicon-home {
			font-size: $font-size-small;
			margin-top: 3px;
		}
	}
}
