.benefit-page {
	$benefit-row-height: 80px;
	$benefit-row-bg-color: #233164;
	$rank-level-bg-color: #191C3A;
	$common-bg-color: #204481;
	$main-bg-color: $member-bg;

	%flex-center-vertical {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	background-color: $main-bg-color;
	color: $lightest;
	padding-bottom: 30px;

	h3 {
		margin-top: 15px;
		margin-bottom: 6px;
		color: $lightest;
	}

	p {
		color: $gray-three;
	}

	a {
		color: $lightest;

		&:hover {
			color: $lightest;
			text-decoration: underline;
		}
	}

	section {
		@media screen and (max-width: $screen-md-min) { /* xs */
			margin-bottom: 30px;
		}
		@media screen and (min-width: $screen-md-min) { /* xs */
			margin-bottom: 10px;
		}
	}

	.column {
		@media screen and (max-width: $screen-md-min) { /* xs */
			width: 100%;
		}
		@media screen and (min-width: $screen-md-min) { /* xs */
			width: 50%;
		}
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.read-more {
		margin-bottom: 0px;
	}

	.benefit-row {
		height: $benefit-row-height;
		display: flex;
		margin-bottom: 6px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

		.benefit-thumbnail {
			display: flex;
			background-color: $common-bg-color;
			border-radius: 2px 0 0 2px;
			height: $benefit-row-height;
			width: $benefit-row-height;

			&.scaled .ximg-content {
				height: $benefit-row-height * 0.75;
				width: $benefit-row-height * 0.75;
			}
		}

		.benefit-info {
			flex: 1;
			background-color: $benefit-row-bg-color;
			padding: 8px 10px 10px 10px;
			border-radius: 0 2px 2px 0;
			font-size: 0.9em;
		}
	}

	.benefit-info p {
		margin: 0;
	}

	.benefit-info .benefit-description {
		line-height: 1.3;
	}

	.benefit-title {
		font-weight: bold;
	}

	#level-progression {
		display: flex;
	}

	#level-progression {
		display: flex;
	}

	#rank-history {
		flex-grow: 1;
	}

	.level-btn {
		@extend %flex-center-vertical;

		background-color: $common-bg-color;
		padding: 7px 5px;
		color: $main-bg-color;
		font-weight: bold;
		border-bottom: $benefit-row-bg-color solid 2px;
		cursor: pointer;
	}

	.level-btn:hover {
		background-color: #2C51A0;
	}

	.disabled:hover {
		background-color: $common-bg-color;
	}

	.disabled {
		cursor: auto;
		color: #20386C;
	}

	.xp-bar, .xp-text {
		margin-bottom: 5px;
		font-weight: bold;
	}

	.xp-text > span:first-child {
		color: #32A8D0
	}

	.opacity {
		opacity: 0.5;
	}
}
