.category {
	p {
		font-size: 13px;
		margin-bottom: 13px;
	}
	ul {
		padding-left: 20px;
		margin-bottom: 13px;
		}
	li {
		font-size: 13px;
	}

	.banner {
		padding: 0 12px;

		.ximg-content {
			aspect-ratio: 16 / 5;
		}
	}

	h1 {
		margin: 0 0 4px 12px;

		.ximg {
			height: 22px;
			width: 22px;
		}
	}

	.article-link-wrap {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		padding: 0 8px;

		.info-link {
			height: auto;
			border-radius: $border-radius;
			display: flex;
			flex-wrap: wrap;
			padding: 0px;
			flex: 1 1 100%;
			gap: 0 8px;
			margin-bottom: 8px;

			&.one-article {
				flex: 1 1 100%;
				width: 100%;
				margin-bottom: 0;
			}

			img {
				width: 100%;
			}

			.ximg {
				flex: 0 0 auto;
				height: 56px;
				width: 56px;
				padding: 6px;
				background-color: $gray-four;
				border: 1px solid $lightest;

				&.dark {
					background-color: #202430;
					border: 1px solid #273143;
				}
			}

			.article-info {
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				flex: 1 1;
				max-width: 80%;

				.heading {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				.sub-title {
					font-style: italic;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}

		@media screen and (min-width: $screen-md-min) {
			flex-wrap: nowrap;

			.info-link {
				flex: 1 1 50%;
				gap: 0 8px;
			}
		}
	}
}