/* latin */
@font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: local('Press Start 2P Regular'), local('PressStart2P-Regular'), url(../fonts/ps2p-latin.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
	font-display: swap;
}

@font-face {
	font-family: 'Glyphicons';
	src: url(../fonts/bootstrap/glyphicons-halflings-regular.woff2) format('woff2');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta'), url(../fonts/averta/Averta-Regular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Italic'), local('Averta-Italic'), url(../fonts/averta/Averta-RegularItalic.woff2) format('woff2');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Semibold'), local('Averta-Semibold'), url(../fonts/averta/Averta-Semibold.woff2) format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Semibold Italic'), local('Averta-Semibold-Italic'), url(../fonts/averta/Averta-SemiboldItalic.woff2) format('woff2');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Extrabold'), local('Averta-Extrabold'), url(../fonts/averta/Averta-Extrabold.woff2) format('woff2');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Averta';
    src: local('Averta Extrabold Italic'), local('Averta-Extrabold-Italic'), url(../fonts/averta/Averta-ExtraboldItalic.woff2) format('woff2');
    font-weight: 800;
    font-style: italic;
    font-display: swap;
}

@font-face {
	font-family: 'BT-Brik';
    src: local('BT Brik'), local('BT-Brik'), url(../fonts/BTBrik/BTBrikRegular.woff2) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
