.flames {
  background-image: cdn-url('/img/icons/feedback/hype_0.svg');
  height: 14px !important;
  background-size: 14px;
  width: 68px;
  background-repeat: repeat-x;
  display: inline-block;
  background-position: 0 0;

  &.product-display-flames {
    height: 18px !important;
    background-size: 18px;
    width: 90px;
  }
}

.flames-lg {
  height: 19px !important;
  width: 99px;
  background-size: 20px;
}

.flames-content {
  height: 100% !important;
  background-position: 0 0;
  background-size: inherit;
  background-repeat: repeat-x;
}

.flames-content-1 {
  background-image: cdn-url('/img/icons/feedback/hype_1.svg');
}

.flames-content-2 {
  background-image: cdn-url('/img/icons/feedback/hype_2.svg');
}

.flames-content-3 {
  background-image: cdn-url('/img/icons/feedback/hype_3.svg');
}

.flames-content-4 {
  background-image: cdn-url('/img/icons/feedback/hype_4.svg');
}

.flames-content-5 {
  background-image: cdn-url('/img/icons/feedback/hype_5.svg');
}

.flames-content-alt-bg {
  background-image: cdn-url('/img/icons/feedback/hype_1.svg');
}
