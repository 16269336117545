.site-container {
    min-height: 100vh;

    .outer-main-wrap {
        width: 100%;
        flex: 1 0 auto;
        display: flex;
    }
}
.livechat-wrap {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1003;
}
main {
    background-color: $main-bg;
    flex-grow: 1;
    flex-shrink: 1;
    position: relative;
    padding-bottom: 12px;

    @media (min-width: $screen-md-min) {
        flex-grow: 0;
    }

    .wrap {
        position: relative;
        display: flex;
        width: 100%;

        /* Left aside */
        .left-col {
            align-self: flex-start;
            display: none;
            @media (min-width: $screen-lg-min) {
                display: block;
                min-height: 1px;
                padding-left: 8px;
                flex: 0 0 16.66666667%;
                width: 16.66666667%; //lg-2
            }
        }

        /* Main content */
        .main-col {
            position: relative;
            min-height: 1px;
            padding: 0;
            flex: 1 1 80%;
            max-width: 100%;
            overflow-x: auto;
            overflow-y: hidden;

            @media (min-width: $screen-md-min) {
                padding: 0 8px;
            }

            .main-container {
                background-color: $main-bg;
                border-radius: 2px;
                padding-bottom: 8px;
            }

            &.no-overflow {
                overflow: unset;
            }
        }

        /* Styling for hiding right aside */
        &.hide-right {
            @media (min-width: $screen-lg-min) {
                .left-col {
                    width: 20%;
                    flex: 0 0 20%;
                }
            }
        }
    }
}
