.fixed-lines {
	font-weight: initial;
	display: block;
	position: relative;
	overflow: hidden;
}

// Only works with webkit based browsers.
.fixed-lines-ellipsis {
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
}

.product-tip-ellipsis {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; 
}