.checkout {

	/* General layout*/

	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: auto;
	align-content: space-between;
	padding: 0 8px;

	.customer-type {
		z-index: 2;
		flex: 0 0 100%;
		height: 40px;
	}

	.radio-checkbox-label {
		input {
			position: absolute;
			opacity: 0;
			cursor: pointer;

			&:checked ~ .checkbox-custom {
				border: 2px solid $brand-primary;
				-webkit-transform: rotate(0deg) scale(1);
				-ms-transform: rotate(0deg) scale(1);
				transform: rotate(0deg) scale(1);

				.checkbox-dot {
					background: $brand-primary;
				}
			}
		}

		.checkbox-custom {
			position: absolute;
			top: 0px;
			left: -2px;
			height: 20px;
			width: 20px;
			background-color: transparent;
			border-radius: 100%;
			border: 1px solid $gray-three;

			&::after {
				position: absolute;
				content: '';
				left: 12px;
				top: 12px;
				height: 0px;
				width: 0px;
				border-radius: 100%;
				border: 1px solid $gray-three;
				-webkit-transform: rotate(0deg) scale(0);
				-ms-transform: rotate(0deg) scale(0);
				transform: rotate(0deg) scale(0);
				opacity: 1;
				transition: all 0.3s ease-out;
				-webkit-transition: all 0.3s ease-out;
				-moz-transition: all 0.3s ease-out;
				-ms-transition: all 0.3s ease-out;
				-o-transition: all 0.3s ease-out;
			}

			.checkbox-dot {
				content: '';
				background: $lightest;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				position: relative;
				left: 3px;
				top: -3px;
				display: inline-block;
			}

			.ok-mark {
				color: $brand-primary;
				left: 2px;
				top: -2px;
				font-size: $font-size-small;
			}
		}
	}

	.steps-wrap {
		flex: 1 1 90%;
		order: 2;
		display: flex;
		flex-direction: column;

		.step-header {
			width: 100%;
			float: left;
			justify-content: space-between;
			padding: 22px 5px 11px;

			&.active-header {
				color: $brand-primary;
			}

			&.step-header-background {
				background-color: $gray-four;
				border-radius: $border-radius;
				margin-top: 10px;
				padding: 16px 15px;
			}

			h2 {
				float: left;
			}
		}

		.continue-btn {
			margin: 12px 0;
			float: right;
		}
	}

	.panel {
		border: 1px solid $gray-three;
		box-shadow: none;
		border-radius: 4px;
	}

	.list-group, .form-control {
		border: 1px solid $gray-three;
		border-radius: 4px;
		margin-bottom: 0;
		padding: 0;

		&.loading-spinner {
			border: none;
		}

		.list-group-item, .list-group-item:hover {
			border: 2px solid transparent;
			padding: 16px 15px;
			border-radius: 4px;
			cursor: pointer;
			background-color: $lightest;
			color: $text-color;
			width: 100%;

			&.active {
				border: 2px solid $brand-primary;
			}

			.list-group-item-text {
				color: $text-secondary-color;
			}

			.dropdown-select {
				margin-bottom: 8px;
			}

			.stock-icon {
				margin-right: 5px;
			}

			.sub-title {
				display: inline-block;
			}

			.stock {
				padding: 0;
				display: flex;
				align-items: center;
				margin-top: 4px;
			}

			.resurs-info {
				display: flex;
				flex-direction: column;
				gap: 8px;
				font-size: $font-size-small;

				ul {
					list-style: none;
					padding: 0;
				}
			}

			.invoice-info {
				font-size: 12px;
			}

			.part-payment-info {
				background-color: $gray-four;
				width: 100%;
				float: left;
				border-radius: $border-radius;
			}

			.part-payment-info, ul.part-payment-info {
				font-size: 12px;
				list-style: inside !important;
				padding: 15px 15px !important;
			}
		}

		& > div:last-child > .list-group-item + .divider.border-bottom {
			display: none;
		}
	}

	.glyphicon.glyphicon-chevron-down, .glyphicon.glyphicon-chevron-up {
		font-weight: $font-weight-semibold;
	}

	.sub-title {
		margin: 0;
	}

	.no-col-padding {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.border {
		border-color: $text-color !important;
	}

	.border-bottom {
		width: 96%;
		margin-left: 2%;
		margin-right: 2%;
	}

	.price-value {
		font-size: $font-size-base;
	}

	.price-value._regular {
		font-size: $font-size-small;
	}

	.input-group input {
		padding-left: 12px;
	}

	@media (min-width: $screen-lg-min) {
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;

		.steps-wrap {
			flex: 0 0 67%;
    		max-width: 67%;
			order: 1;
		}
	}

	@media (max-width: $screen-lg-min) {
		.list-group {
			width: 100%;
		}
	}

	/* Cart, vouchers and cart calculator */

	.checkout-wrap {
		order: 1;
		position: relative;
		z-index: 4;
		width: 100%;
		display: block;

		h2 {
			margin: 11px 0;
		}

		.cart-list {
			$height: 54px; // Used on main children for nice flex
			$marginX: 10px; // Used for scroll fade
			border: 1px solid $gray-three;
			border-radius: $border-radius;
			padding: 0 $marginX;

			.additions-panel {
				background-color: $gray-four;
				border-radius: $border-radius;
				margin: 8px 0;
				padding: 10px 12px;

				.additions-row {
					display: grid;
					grid-template-columns: 1fr 1fr;

					& > span  {
						font-size: $font-size-small;

						&:nth-child(even) {
							text-align: end;
						}
					}
				}
			}

			.product-title-wrap, .cart-price-qty {
				height: $height;
			}

			.cart-list-row {
				&:first-child {
					margin-top: 5px;
				}

				&:last-child {
					margin-bottom: 5px;
				}
			}

			&::before, &::after {
				content: '';
				position: sticky;
				display: block;
				z-index: 5;
				height: 12px;
				width: calc(100% + #{$marginX} * 2);
				transition: all .15s linear;
				opacity: 0;
				pointer-events: none;
			}

			&::before {
				top: 0;
				margin-left: -$marginX;
				margin-bottom: -12px;
				background: linear-gradient(0deg, rgba(214,214,214,0), rgba(214,214,214,0.8));
			}

			&::after {
				bottom: 0;
				margin-left: -$marginX;
				margin-top: -12px;
				background: linear-gradient(0deg, rgba(214,214,214,0.8), rgba(214,214,214,0));
			}

			&.content-above::before {
				opacity: 1;
			}

			&.content-below::after {
				opacity: 1;
			}
		}

		.voucher-input {
			padding: 12px 0;

			.input-group.empty {
				margin: 0 auto;

				.form-control {
					border-radius: 4px;
					min-width: 270px;

					&.unfocused {
						-webkit-box-shadow: none;
						box-shadow: none;
						border: 0px;
					}
				}
			}

			.list-group {
				padding: 1px 0;
				margin: 10px auto;

				.list-group-item {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 16px 10px;

					.ximg {
						background-color: $gray-four;
						flex: 0 0 10%;
						width: 45px;
						height: 36px;
						margin: -6px 12px 0 -3px;
						border-radius: $border-radius;

						.ximg-content {
							transform: scale(.4);
						}
					}

					.discount-type {
						flex: 1 1 50%;
						line-height: 0.9em;

						.title {
							font-size: $font-size-small;
						}

						.sub-title {
							font-size: $font-size-small;
							margin: 4px 0 0;
							color: $text-secondary-color;
						}
					}

					.discount-amount {
						flex: 1 0 30%;
						text-align: right;
						font-weight: $font-weight-semibold;
						font-size: $font-size-base;
						margin-right: 10px;
					}

					.btn-remove-item {
						flex: 0 0 10%;
						max-width: 20px;
					}
				}
			}

			.apply-available-store-credit {
				display: flex;
				justify-content: space-between;
				margin-top: 12px;

				.available-store-credit-wrapper {
					display: flex;
					align-items: center;

					.available-store-credit {
						margin-right: 4px;
					}
				}
			}
		}

		.voucher-input {
			flex: 0 0 auto;
		}

		.cart-calculator {
			flex: 1 0 auto;

			.perk-wrap {
				padding: 0;

				&.disabled {
					pointer-events: none;
					opacity: 0.5;
				}

				.perk-selector {
					border: 1px solid #e0dfdf;
					padding: 0;
					position: relative;
					display: inline-block;
					cursor: pointer;

					.btn {
						padding-right: 32px;
						font-weight: $font-weight-normal;
					}

					img {
						height: 24px;
					}
				}

				.perk-selector-menu {
					white-space: nowrap;
					min-width: 100%;
					text-align: left;
					z-index: 1;
					box-shadow: 0 1px 6px rgba(0,0,0,.5);
					border-radius: 2px;
					position: absolute;
					top: 100%;
					left: 0;
					background: $lightest;
				}

				.perk-selector-option:not(:last-child) {
					border-bottom: 1px solid #ddd;
				}

				.perk-selector-option:hover {
					background: $brand-primary;
				}
			}

			.cart-footer {
				background-color: $gray-four;
				padding: 15px 24px;
				width: 100%;
				float: left;
				border-radius: $border-radius;

				hr {
					width: 100%;
				}

				& > div {
					width: 100%;
					float: left;
					display: flex;
					justify-content: space-between;
				}

				.cart-total {
					font-weight: $font-weight-semibold;
				}
			}
		}

		@media (min-width: $screen-lg-min) {
			z-index: 2;
			flex: 1 1 30%;
			width: 33%;
			max-width: 390px;
			order: 3;
			padding: 0 0 0 16px;
			position: relative;
			max-height: 100%;

			.sticky-cart {
				position: sticky;
				top: 0;
				display: flex;
				justify-content: flex-start;
				flex-direction: column;
				transition: top 200ms ease-in 0.5ms;
				max-height: calc(100vh - 80px);
				min-height: 500px;

				&.header-spacing {
					top: $headerHeight-lg;
				}
			}

			h2 {
				flex: 0 0 auto;
				height: 19px;
			}

			.cart-list {
				flex: 0 1 auto;
				overflow: hidden;
				overflow-y: scroll;
				position: relative;
				padding-right: 6px;

				&::-webkit-scrollbar {
					width: 4px;
					position: absolute;
				}

				/* Handle */
				&::-webkit-scrollbar-thumb {
					background: $gray-three;
					border-radius: 50rem;
				}

				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background: $gray-two;
				}
			}
		}
	}

	/* Step one / Delivery method */

	.step-one {
		z-index: 2;

		.step-header {
			padding: 11px 5px;
		}

		.delivery-method-selector {
			padding-right: 0;

			.list-group-item.disabled {
				opacity: 0.5;
			}
		}

		.store-padding {
			padding: 16px 15px;
			border-radius: 4px;

			&.negative-margin-top {
				margin-top: -1px !important;
			}

			&.two-px-border-transparent {
				border: 2px solid transparent !important;
			}

			&.no-pointer-grey-bg {
				pointer-events: none;
				background-color: #f9f9f9;
			}
		}
	}

	/* Step two / Shipping methods / Stores */

	.step-two {
		z-index: 3;
		display: flex;
		flex-direction: column;

		.check-partial-delivery {
			display: flex;
			flex-direction: column;
			padding: 8px 5px 0;

			.checkbox-wrap {
				align-items: flex-start;

				.checkbox {
					margin-top: 2px;
				}
			}

			hr {
				width: 100%;
			}
		}

		.ship-from-store {
			margin-top: 20px !important;
		}

		.checkout-zip {
			display: flex;
			flex-direction: column;
			align-items: center;
			position: relative;
			margin-bottom: 4px;

			.current-zip {
				padding: 0 5px;
				align-self: flex-start;

				.change-zip {
					font-weight: $font-weight-semibold;
					text-decoration: underline;
				}
			}

			label, .input-text, .input-group {
				width: 100%;
			}

			.input-text {
				&:focus, &:focus-visible {
					border-color: $brand-primary;
					outline: 0;
				}

				.form-control-feedback {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;

					& > a {
						pointer-events: initial;
						color: $darkest;
					}
				}
			}

			@media (min-width: $screen-md-min) {
				label, .input-text {
					max-width: 400px;
				}

				.input-group {
					max-width: 500px;
				}
			}
		}

		.eol-info {
			.eol-list {
				padding: 0;

				.eol-product {
					display: flex;
					flex-direction: column;
				}
			}
		}

		.shipping-header {
			padding: 0;

			.radio-inline {
				font-weight: $font-weight-semibold;

				.has-rebate {
					text-decoration: line-through;
					font-size: $font-size-small;
					font-weight: $font-weight-normal;
					padding-right: 6px;
				}
			}
		}

		.shipping-stock-delivery, .shipping-stock-delivery > .expected-delivery {
			padding: 0;
			display: flex;
			align-items: center;
		}

		.shipping-name {
			padding: 0;
			margin-left: 4px;
		}

		.dropdown-wrap {
			border: 1px solid #e0dfdf;
			padding: 0;
			margin: 12px 0;

			.btn.btn-variants.btn-dropdown {
				align-items: flex-end;
				flex-wrap: wrap;
				padding: 10px 32px 10px 12px;
			}
		}

		.shipping-icon {
			height: 20px;
			position: relative;
			max-width: 55px;
			top: -2px;
			float: right;
		}

		.postnord-time-slots {
			display: flex;
			flex-direction: column;
			gap: 8px;

			.sub-title {
				flex: 1 0 100%;
			}

			.time-slot {
				background-color: $gray-four;
				border-radius: $border-radius;
				font-weight: $font-weight-semibold;
				display: flex;
				justify-content: space-between;
				flex: 1 1 auto;
				padding: 8px;

				.small {
					font-weight: $font-weight-normal;
					align-self: center;
				}
			}

			@media (min-width: $screen-md-min) {
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				gap: 4px 16px;

				.time-slot {
					flex-direction: column;
					padding: 4px 8px;

					.small {
						align-self: flex-start;
					}
				}
			}
		}

		.divider {
			width: 96%;
			margin-left: 2%;
			margin-right: 2%;
		}

		.disabled {
			pointer-events: none;
			color: $disabled;

			.shipping-name {
				text-decoration: line-through;
			}

			&.list-group-item {
				color: $text-color;
				opacity: 0.6;

				.stock .sub-title {
					color: $destructive;
				}
			}
		}

		.loading-spinner {
			height: 65px;
		}
	}

	/* Step three / Payment */

	.step-three {
		z-index: 2;

		.part-payment-warning {
			background-color: transparent;
			float: left;
			width: 100%;
			padding-top: 15px;

			.ximg {
				float: left;
				margin-right: 8px;
				height: calc(($font-size-base * $line-height-base) * 2.4);
				aspect-ratio: 43 / 38;
			}

			p {
				font-size: $font-size-base;
				margin: 0;
			}
		}
	}

	/* Shipping method / Payment method dropdown */

	.shipping-option-dropdown {
		width: 100%;
		color: $text-color;
		position: absolute;
		z-index: 1003;
		background: $lightest;
		box-shadow: 0px 1px 5px rgba(0,0,0,.5);
		border-radius: 2px;
	}

	.shipping-option {
		padding: 6px 10px;
		cursor: pointer;

		&:hover {
			background-color: $brand-primary;

			.shipping-option-title, .shipping-option-subtitle {
				color: $lightest;
			}
		}
	}

	.shipping-option-title {
		font-weight: $font-weight-normal;
		margin-right: 4px;
		padding: 3px 0;
		line-height: 1;
	}

	.shipping-option-subtitle {
		font-size: $font-size-small;
		font-weight: $font-weight-normal;
		color: $text-secondary-color;
		padding: 3px 0;
		margin-left: 5px;
		line-height: 1;
	}

	.shipping-option:not(:first-child) {
		border-top: 1px solid #ccc;
	}

	/* Step four / Customer information */

	.step-four {
		z-index: 1;

		.panel {
			margin-bottom: 8px;
		}

		.customer-info {
			width: 500px;
			max-width: 100%;
			margin: 0 auto;
			padding: 0;
			display: flex;
			flex-direction: column;

			.text-group.disabled {
				pointer-events: none;
				opacity: 0.5;
			}

			.corporate-info {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				row-gap: 4px;

				.subtitle, h3 {
					flex: 1 1 100%;
				}

				.corporate-number {
					font-weight: $font-weight-semibold;
				}

				.text-btn {
					margin-left: auto;
				}
			}
		}

		.kco-html {
			width: 100%;
			border: none;

			#klarna-checkout-container {
				width: 100%;
			}
		}

		.checkout-amount {
			font-size: $level-one-heading;
			font-weight: $font-weight-semibold;
			line-height: 1.2;
			margin-top: 30px;
		}

		.checkout-confirmation-wrap {
			.confirm-checkout {
				max-width: 320px;

				.confirm-order-btn {
					margin-bottom: 10px;
				}

				.checkout-loading {
					font-size: $level-two-heading;
					padding: 8px;

					.loading-spinner {
						width: 20px;
						display: inline-block;
						margin-top: -4px;
					}
				}
			}

			.sub-title {
				text-align: center;
				margin-top: 6px;
				max-width: 400px;
				margin: 0 auto;
			}
		}

		.input-field-button-group {
			width: 100%;
		}
	}
}

.checkout-complete {
	.member-btn-wrap {
		max-width: 400px;
		width: 100%;
		margin: 0 auto;
	}

	.prisjakt-voting-elements {
		position: relative;
		display: flex;
		justify-content: center;
		background-color: $gray-four;
		padding: 8px 0;
		margin-bottom: 8px;
		border-radius: $border-radius;

		iframe {
			border: none;
		}
	}
}
