body:after {
	content: 'mq-xs';
	display: none;
}

@media screen and (min-width: $screen-sm-min) {
	body:after {
		content: 'mq-sm';
		display: none;
	}
}

@media screen and (min-width: $screen-md-min) {
	body:after {
		content: 'mq-md';
		display: none;
	}
}

@media screen and (min-width: $screen-lg-min) {
	body:after {
		content: 'mq-lg';
		display: none;
	}
}
