.avatar-page {
	color:#eee;
	background: $member-bg;
}

.avatar-editor {
	background-color: $member-bg;
	border-radius: 2px;
}

.avatar-selector-wrap {
	display: flex;
	gap: 8px;

	.my-avatar {
		flex-grow: 2.5;

		.ximg {
			height: 263px;
			width: 100%;
			border-radius: 2px;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
			background-size: cover;
			background-position: center;

			.ximg-content {
				position: absolute;
			}
		}
	}

	.avatar-item-selector-col {
		flex-basis: 100px;
		flex-grow: 1;

		.avatar-item-selector {
			height: 48px;

			.avatar-slot-icon {
				background: #204481;
				height: 100%;
				width: 48px;

				.ximg-content {
					height: 70%;
					width: 70%;
					margin: 15%;
				}

				&.pixelated .ximg-content {
					height: 100%;
					width: 100%;
					margin: 0;
				}
			}

			h5 {
				margin-bottom: 6px;
			}
		}

		@media screen and (max-width: $screen-xs-max) {
			flex-grow: 0;
			flex-basis: 0;
		}
	}
}

.avatar-item-selector, .avatar-item-modal {
	cursor: pointer;
	color: #eee;
	margin-bottom: 6px;
	background: #233164;
	border-radius: 3px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	overflow: hidden;
	position: relative;
	.item-content {
		position: absolute;
		left: 56px;
		top: 6px;
		right: 6px;
		line-height: 1.3em;
	}
	.equipped-item-name {
		color: #8e9cad;
		font-size: $font-size-small;
	}
	.item-type {
		font-size: $font-size-base;
		font-weight: 500;
		color: white;
	}
	.btn-primary:before {
		background-color: $member-bg;
		border-color: #000;
	}
	&.disabled {
		cursor: not-allowed;
		.item-type {
			color: #aaa;
		}
	}
}

.avatar-item-equip-marker {
	width: 12px;
	height: 12px;
	border-radius: $rounded-radius * 3;
	background: blue;
	display: inline-block;
}

.avatar-info-footer {
	padding: 6px;
	font-size: $level-two-heading;
	font-weight: bold;
	text-align: center;
}

.avatar-stats {
	color: #fff;
	background: #233164;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 12px;
	border-radius: 3px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
	display: flex;
	justify-content: center;
}

.avatar-stat {
	display: inline-block;
	margin: 4px 12px;
	flex: 0 1 20%;
	max-width: max-content;
	text-align: left;

	img {
		height: 32px;
		margin-top: -21px;
		margin-right: 4px;
	}

	& > div {
		display: inline-block;
		line-height: 1em;
		padding-left: 4px;
	}
}

.avatar-stat-name {
	font-weight: bold;
	font-size: $level-two-heading;
}

.avatar-stat-type {
	font-size: $font-size-base;
}

// modal
.modal-container.avatar-item-modal {
	background: $member-bg;
	padding: 24px;
	overflow: visible;
	margin: auto;
}

.avatar-item-showcase-box {
	background: #204481;
	border-radius: 3px;
	display: inline-block;

	& > .ximg {
		width: 100%;
		height: 100%;
	}
}

.equipped-item-header {
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	gap: 8px;

	.avatar-item-showcase-box {
		width: 48px;
		height: 48px;
	}
}

.avatar-item-showcase {
	max-width: 616px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;

	.avatar-item-showcase-box {
		transition: background .1s;
		cursor: pointer;
		height: 96px;
		width: 96px;

		&.equipped {
			background: #32a8d0;
			border: 4px solid #1791c4;
			box-sizing: content-box;
			margin: -4px -4px;
		}

		&:hover {
			background: $gray-three;
		}
	}
}
