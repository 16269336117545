.alert-feed {
	width: 100%;
	@include alert-variant(#c1e2e0, #9ad8d8, $gray-one);
	border-bottom: 2px solid gray;
	padding: 4px 2px;
	&:hover {
		color: #000;
		text-decoration: none;
	}
	&:hover::after {
		content: '';
		background: rgba(255,255,255,0.2);
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: -2px;
		border-radius: 4px;
		pointer-events: none;
	}
}

.alert-feed-content {
	display: flex;
	flex-direction: row;
	margin-left: 24px;
	font-size: 13px;
	line-height: 18px;
	margin-right: 10px;
	z-index: 2;
	position: relative;
}

.alert-feed-icon {
	width: 20px;
	top: 0;
	bottom: 0;
	position: absolute;
	left: 6px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.alert-feed-text {
	width: 100%;
	white-space: normal;
	text-align: left;
}

.product-list-compact-item .feed-remove-icon {
	background-color: $gray-three;
	z-index: 100;
}

.feed-remove-icon {
	background: $destructive;
	border-radius: 100px;
	position: absolute;
	right: 2px;
	top: 2px;
	border-radius: 100px;
	padding-left: 2.6px;
	padding-top: 2px;
	color: #273043;
	line-height: 0;
	width: 14px;
	height: 14px;
	&:hover {
		background: $destructive;
		color: #eee;
	}
	.glyphicon-remove {
		font-size: 8px;
	}
}
