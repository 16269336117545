// CSS mostly copied from real livechat
.livechat-container {
	-webkit-font-smoothing: antialiased;
	font-size: initial;
	z-index: 9001;

	@media screen and (min-width: $screen-md-max) {
		width: 400px;
	}

	max-width: 100%;
	height: 450px;
	bottom: 50px;
	will-change: opacity;
	backface-visibility: hidden;
	padding: 1em 1em 0px;

	.livechat-panel {
		position: relative;
		height: 100%;

		.livechat-panel-inner {
			display: flex;
			flex-direction: column;
			min-width: 0px;
			height: 100%;
			box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 16px;
			overflow: hidden;
			border-radius: 3px 3px 0px 0px;
			background: $lightest;

			.header-wrapper {
				width: 100%;
				position: relative;
				z-index: 2;
				background-color: $brand-primary;
				font-size: $level-three-heading;
				height: 46px;
				-webkit-box-pack: start;
				justify-content: flex-start;
				color: $lightest;
				flex-shrink: 0;
				-webkit-box-flex: 0;
				flex-grow: 0;
				display: flex;
				-webkit-box-align: stretch;
				align-items: stretch;
				font-weight: 700 !important;
				text-align: center !important;
				border-width: initial;
				border-style: initial;
				border-color: rgb(0, 0, 0);
				border-image: initial;
				padding: 0px 0.8em;
				transition: background-color 0.3s ease 0s;

				.header-text {
					font-size: $level-three-heading;
					-webkit-box-flex: 1;
					flex-grow: 1;
					display: flex;
					-webkit-box-align: center;
					align-items: center;
					-webkit-box-pack: inherit;
					justify-content: inherit;
					text-overflow: ellipsis;
					margin: 0px;
					overflow: hidden;
				}

				.minimize-button {
					display: flex;
					cursor: pointer;
					-webkit-appearance: none;
					color: inherit;
					background: transparent;
					border-width: 0px;
					border-style: initial;
					border-color: initial;
					border-image: initial;
					margin: 0px;
					padding: 0px 0.5em;
					height: 100%;

					svg {
						align-self: center;
					}
				}
			}

			.content {
				position: relative;
				font-size: $font-size-base;
				overflow-y: auto;
				height: 100%;
				-webkit-box-flex: 1;
				flex-grow: 1;
				padding: 15px 15px 0px;

				.content-footer {
					margin-top: 30px;
					text-align: center;

					.text-btn {
						margin-bottom: 8px;
					}
				}
			}

			.footer {
				right: 0px;
				bottom: 0px;
				font-size: $font-size-small;
				text-align: right;
				position: relative;
				background-color: $gray-four;
				text-shadow: none;
				padding: 1em;
			}
		}
	}
}

.livechat-button-container {
	-webkit-font-smoothing: antialiased;
	z-index: 9001;
	font-size: initial;
	will-change: opacity;
	backface-visibility: hidden;
	padding: 0.8em 0.8em 0px;

	.inner-container {
		display: flex;
		flex-direction: column;
		min-width: 0px;

		.button {
			width: 255px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 12px;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			height: 40px;
			color: $lightest;
			background: $brand-primary;
			padding: 0px 0.9em;
			border-radius: 3px 3px 0px 0px;

			p {
				margin: 1em 0px;
				-webkit-box-flex: 1;
				flex-grow: 1;
				font-weight: 700;
				font-size: 0.9em;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			svg {
				width: 28px;
				height: 22.5px;
			}
		}
	}
}

.livechat-button-mobile-container {
	-webkit-font-smoothing: antialiased;
	font-size: initial;
	z-index: 9001;
	will-change: opacity;
	backface-visibility: hidden;
	padding: 0.5em;

	.inner-container {
		display: flex;
		width: 60px;
		height: 60px;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 8px;
		-webkit-box-pack: center;
		justify-content: center;
		position: relative;
		color: $lightest;
		background: $brand-primary;
		border-radius: 50%;

		.button {
			height: 100%;
			display: flex;
			-webkit-appearance: none;
			color: inherit;
			background: transparent;
			border-width: 0px;
			border-style: initial;
			border-color: initial;
			border-image: initial;
			margin: 0px;
			padding: 0px;

			svg {
				align-self: center;
			}
		}
	}
}

/* purgecss start ignore */

[data-puzzel-chat] {
	& > section {
		z-index: 1001;

		& > button, button:focus {
			margin-left: -10px;
			margin-top: -10px;
			background-color: $brand-primary;
			border: none;
			box-shadow: none;

			&:hover {
				background-color: $hover;
				border: none;
				box-shadow: none;
			}
		}
	}

	header {
		background-color: $brand-primary !important;
	}

	#title {
		margin: 0;
		font-weight: 500;
		font-size: 20px;
	}

	.close {
		opacity: 1;

		&.is-icon {
			background-color: $brand-primary;

			&:focus {
				background-color: $brand-primary;
				border: none;
			}
		}

		&:focus {
			background-color: $brand-primary;
		}
	}

	textarea {
		min-height: 38px;
		line-height: unset;
	}

	form {
		button {
			border: none;

			&.is-outline-focused:focus{
				border: none;
				background-color: $brand-primary;
			}

			&.button-0-0-4.is-primary.is-centered.is-outline-focused {
				margin: 20px auto 0;
			}
		}

		input {
			border: 1px solid $gray-three;
			border-radius: 4px;

			&:focus {
				box-shadow: none;
				border-color: $brand-primary;
				outline: 0;
			}
		}
	}

	&.mobile, &.web {
		z-index: 1001;

		@media screen and (max-width: $screen-md-max) {
			&.hide-livechat-margin {
				& > section, .chat-bubble {
					transition: margin-top 0.25s linear;
				}
			}

			&.show-livechat-margin {
				& > section {
					transition: margin-top 0.25s linear;
					margin-top: -66px;

					&.chat-bubble {
						margin-top: -74px;
					}
				}
			}
		}
	}
}

.mobile-lock-scroll {
	& [data-puzzel-chat].mobile {
		z-index: 1005;
	}
}

/* purgecss end ignore */