.free-shipping-info {
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 12px 4px 4px;
	font-weight: $font-weight-semibold;
	color: $success-dark;

	.shipping-icon {
		margin-right: 4px;
		width: 14px;
		height: 14px;
	}

	.free-shipping-sum {
		color: $text-color;
		transition: all 0.2s linear;
		display: inline-block;

		&._grow {
			transition: none;
			font-size: 1.2em;
			margin-top: -12px;
			margin-bottom: -12px;
		}
	}
}