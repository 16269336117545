.member-tutorial-modal {
	@extend .welcome-modal;

	.ximg {
		border-radius: $border-radius $border-radius 0px 0px;
		overflow: hidden;
		height: auto;
		aspect-ratio: 450 / 253;

		/* Avataren */

		.ximg.pixelated {
			height: 100%;
			width: auto;
			aspect-ratio: 21 / 20;
			margin: 0 auto;

			.ximg-content {
				transform: scale(0.85) translateY(10%);
			}
		}
	}

	.member-video {
		border-radius: $border-radius $border-radius 0 0;
	}
}
