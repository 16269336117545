.wishlists {
	.tile > a {
		height: $input-large;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 0 15px;
		gap: 12px;

		.fake-link {
			font-weight: $font-weight-semibold;
		}

		.ximg {
			height: 31px;
			width: 31px;
		}

		.remove-wishlist {
			display: flex;
			color: $text-secondary-color;
			gap: 6px;
			margin-left: auto;
		}
	}

	.back-arrow {
		@include icon-left-placement('/api/dynimg/icon/chevron_left/1A1A1D', 10px);
		display: flex;
		align-items: center;

		&::before {
			margin-right: 4px;
		}
	}

	.wishlist-action-link {
		margin-right: 16px;
		font-weight: $font-weight-semibold;
	}
}

.share-wishlist {
	display: flex;

	.input-text {
		border-color: $gray-three;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:focus, &:focus-visible {
			border-color: $brand-primary;
			outline: 0;
		}
	}
}