.loading-spinner {
	display: block;
	max-width: 128px;
	max-height: 128px;
	margin: auto;
}

.locked-loading {
	background-color: #333333b0;
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1004;
	display: flex;
}