.stock-row {
	display: table-row;
	.stock-location {
		display: table-cell;
		width: 100%;
	}
	.stock-status {
		display: flex;
		align-items: center;
		width: 155px;
		max-width: 100%;

		.stock-text {
			margin-left: 3px;
		}

		.stock-select {
			min-width: 20px;
			display: inline-block;
		}
	}
}

.stock {
	display: table;
	width: 100%;
	input {
		display: none;
	}
}

.stock-small {
	display: block;
	width: 100%;
	font-size: $font-size-small;
	padding-right: 3px;
	&:after {
		content: "";
		position: absolute;
		left: 100%;
		top: 0;
		bottom: 0;
		width: 6px;
	}
}

.stock-row-small {
	@extend .stock-row;
	position: relative;
	width: 100%;
	display: flex;
	align-items: center;
	color: $text-secondary-color;
	font-size: $font-size-small;
	margin-top: -1px;
	margin-bottom: -2px;

	.stock-location {
		display: block;
		width: 100%;
		padding-left: 1em;
		margin-left: $spacer-x;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.stock-status {
		display: flex;
		position: absolute;
		width: 1em;
		height: 1em;
	}
}

.stock-row-large {
	@extend .stock-row;
}

.stock-favorite {
	margin: 0;
	margin-right: 1px;

	&.disabled {
		cursor: not-allowed;
	}

	input {
		display: none;
	}

	span {
		margin-right: 1px;
		width: 10px;
		height: 10px;
		display: block;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&::after {
			background-image: cdn-url('/api/dynimg/icon/heart/D6D6D6');
			content: "";
			background-position: bottom;
			background-repeat: no-repeat;
			background-size: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}

		&.stock-in-favourite::after {
			background-image: cdn-url('/api/dynimg/icon/heart/1E8540');
		}

	}

	& input[type=checkbox]:checked + span::after {
		background-image: cdn-url('/api/dynimg/icon/heart/D50855');
	}
}

.starred-stores-ht {
	border-color: #ddd;
}

.starred-stores-hr {
	margin: 0;
	margin-right: 1px;
	&::after {
		background-image: cdn-url('/api/dynimg/icon/heart/D50855');
		content: "";
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: 100%;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: -12px;
		margin-top: 3px;
		width: 10px;
		height: 10px;
		display: block;
	}
}
// GREY: D6D6D6
// PINK: EA83AA
// DARK: 121212
.stock-in-favourite:not(:last-child) {
	margin-right: 1px;
}

.stock-icon-instock {
	@extend .check-icon-green;
	width: 11px;
	height: 11px;
	&:after {
		background-size: 100% !important;
	}
}

.truck-icon {
	@extend .check-icon;
	&:after {
		background-size: 100% !important;
		background-image: cdn-url('/img/icons/truck_yellow.svg')
	}
}

.stock-icon-in-supplier-stock {
	@extend .truck-icon;
	width: 11px;
	height: 11px;
	&:after {
		background-image: cdn-url('/img/icons/truck.svg')
	}
}

.stock-icon-outofstock-incentral {
	@extend .truck-icon;
	width: 11px;
	height: 11px;

	&:before {
		background-color: rgba(0,0,0,0);
	}
}

$stock-border-radius: 2px;
.stock-icon-outofstock {
	@extend .check-icon;
	width: 11px;
	height: 11px;
	border: 2px solid $highlight;
	border-radius: $stock-border-radius;

	&:before {
		background-color: rgba(0,0,0,0);
	}
}

.stock-icon-partialstock {
	@extend .check-icon;
	width: 11px;
	height: 11px;
	&:before {
		background: linear-gradient(60deg, $success-dark 50%, $highlight 0);
	}
}

.stock-icon-hype {
	@extend .check-icon;
	width: 11px;
	height: 11px;
	border: 2px solid $success-dark;
	border-radius: $stock-border-radius;

	&:before {
		background-color: rgba(0, 0, 0, 0);
	}
}

.stock-icon-notreleased {
	@extend .check-icon;
	width: 11px;
	height: 11px;
	border: 2px solid #cacaca;
	border-radius: $stock-border-radius;

	&:before {
		background-color: rgba(0, 0, 0, 0);
	}
}

.stock-hover {
	padding-left: 5px;
	left: 100%;
	margin-left: 1px;
	top: 0;
	.stock-hover-inner {
		box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
		padding: 3px 8px;
		color: $text-secondary-color;
		white-space: nowrap;
		background-color: #fff;
		border-radius: $rounded-radius;
		&:before {
			position: absolute;
			width: 0;
			height: 0;
			border-color: transparent;
			border-style: solid;
			top: 3em;
			left: 0;
			margin-top: -5px;
			content: "";
			border-width: 5px 5px 5px 0;
			border-right-color: #fff;
		}
		.stock-status {
			margin-right: 10px;
			min-width: 45px;
		}
		.stock-location {
			max-width: 105px;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}

.web-stock {
	font-size: $font-size-base;
	.stock-icon-instock, .stock-icon-outofstock, .stock-icon-partialstock, .stock-icon-notreleased {
		width: 14px;
		height: 14px;
	}
}

.stock-dropdown {
	z-index: 1001;
}

.compact-stock-link:hover {
	text-decoration: none;
}

.stock-bar-wrapper {
	line-height: 0.95;

	&.stock-type-compact-list {
		position: absolute;
		right: 15px;
		top: 35px;
	}

	&.stock-type-list {
		position: absolute;
		right: 18px;
		top: 74px;
	}

	&.stock-type-search {
		position: absolute;
		right: 10px;
		bottom: 8px;
	}

	&.stock-type-tip {
		position: absolute;
		right: 10px;
		bottom: 4px;
	}

	@media screen and (max-width: $navbar-min-width) {
		&.stock-type-search {
			bottom: 7px;
		}
	}

	&.stock-type-search {
		@media screen and (max-width: $screen-xs-max) {
			.hidden-xs-search {
				display: none !important;
			}

			.visible-xs-search {
				display: block !important;
			}
		}
	}

	&.stock-type-grid {
		@media screen and (max-width: $screen-xs-max) {
			.hidden-xs-grid {
				display: none !important;
			}

			.visible-xs-grid {
				display: block !important;
			}
		}
	}

	&.stock-type-grid, &.stock-type-search {
		display: flex;
		flex-flow: row-reverse;
	}

	&.stock-type-grid div {
		display: flex;
		align-items: center;
	}

	&.stock-type-search div {
		margin-right: 5px;
	}
}

.progress-stock {
	width: 30px;
}

.stock-bar-wrapper div span{
	font-size: $font-size-small;
	color: $text-secondary-color;
	display: inline-block;
	vertical-align: top;
}

.stock-text {
	font-size: $font-size-small;
}

.additional-stock-text {
	margin-left: 3px;
	color: $text-secondary-color;
	font-size: $font-size-small;
	font-weight: $font-weight-normal;
}

.stock-legend {
	padding: 4px 8px;
	color: $text-color;
	font-size: $font-size-base;

	.stock-icon-legend {
		height: 20px;
		width: 20px;
		margin-right: 15px;
	}
	.stock-shape-legend {
		height: 15px;
		width: 15px;
		margin-right: 20px;
	}
	p, .td-wrapper {
		color: $text-secondary-color;
	}

	p.muted {
		font-size: $font-size-small;
		margin: 0;
	}

	.td-wrapper {
		display: flex;
		align-items: center;
	}

	@media screen and (max-width: $screen-sm-max) {
		.td-wrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			margin-top: 10px;
		}

		.stock-icon-legend {
			margin-right: 0;
		}
		.stock-shape-legend {
			margin-right: 0;
		}
	}

	td span {
		display: block;
	}
}

.show-all-stock {
	color: $text-secondary-color;
	font-size: $font-size-small;
}

.stock-small:hover {
	text-decoration: none;
}

.stock-small:hover .show-all-stock {
	text-decoration: underline;
}

.store-group-label {
	color: $text-color;
	font-size: $font-size-small;
	padding-top: 10px;
}
