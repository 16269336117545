.flex-grow-0 {
	flex-grow: 0;
}

.flex-grow-1 {
	flex-grow: 1;
}

.flex-grow-2 {
	flex-grow: 2;
}

.flex-grow-3 {
	flex-grow: 3;
}

.flex-grow-4 {
	flex-grow: 4;
}

.flex-grow-5 {
	flex-grow: 5;
}
@include screen-size-class('flex-<SIZE>-row') {
	flex-direction: row !important;
}
@include screen-size-class('flex-<SIZE>-row-reverse') {
	flex-direction: row-reverse !important;
}
@include screen-size-class('flex-<SIZE>-column') {
	flex-direction: column !important;
}
@include screen-size-class('flex-<SIZE>-column-reverse') {
	flex-direction: column-reverse !important;
}
@include screen-size-class('flex-<SIZE>-wrap') {
	flex-wrap: wrap !important;
}
@include screen-size-class('flex-<SIZE>-wrap-reverse') {
	flex-wrap: wrap-reverse !important;
}
@include screen-size-class('flex-<SIZE>-nowrap') {
	flex-wrap: nowrap !important;
}
@include screen-size-class('flex-<SIZE>-first') {
	order: -1 !important;
}
@include screen-size-class('flex-<SIZE>-unordered') {
	order: 0 !important;
}
@include screen-size-class('flex-<SIZE>-last') {
	order: 1 !important;
}
@include screen-size-class('justify-content-<SIZE>-start') {
	justify-content: flex-start !important;
}
@include screen-size-class('justify-content-<SIZE>-end') {
	justify-content: flex-end !important;
}
@include screen-size-class('justify-content-<SIZE>-center') {
	justify-content: center !important;
}
@include screen-size-class('justify-content-<SIZE>-between') {
	justify-content: space-between !important;
}
@include screen-size-class('justify-content-<SIZE>-around') {
	justify-content: space-around !important;
}
@include screen-size-class('align-content-<SIZE>-start') {
	align-content: flex-start !important;
}
@include screen-size-class('align-content-<SIZE>-end') {
	align-content: flex-end !important;
}
@include screen-size-class('align-content-<SIZE>-center') {
	align-content: center !important;
}
@include screen-size-class('align-content-<SIZE>-around') {
	align-content: space-around !important;
}
@include screen-size-class('align-content-<SIZE>-stretch') {
	align-content: stretch !important;
}
@include screen-size-class('align-items-<SIZE>-start') {
	align-items: flex-start !important;
}
@include screen-size-class('align-items-<SIZE>-end') {
	align-items: flex-end !important;
}
@include screen-size-class('align-items-<SIZE>-center') {
	align-items: center !important;
}
@include screen-size-class('align-items-<SIZE>-baseline') {
	align-items: baseline !important;
}
@include screen-size-class('align-items-<SIZE>-stretch') {
	align-items: stretch !important;
}
@include screen-size-class('align-self-<SIZE>-start') {
	align-self: flex-start !important;
}
@include screen-size-class('align-self-<SIZE>-end') {
	align-self: flex-end !important;
}
@include screen-size-class('align-self-<SIZE>-center') {
	align-self: center !important;
}
@include screen-size-class('align-self-<SIZE>-baseline') {
	align-self: baseline !important;
}
@include screen-size-class('align-self-<SIZE>-stretch') {
	align-self: stretch !important;
}