.store-group-header {
	font-size: 14px;
	font-weight: bold;
	padding: 4px;
}

.store-group:not(:last-child) {
	border-bottom: 1px solid #ddd;
}

.store-group-stores {
	border-top: 1px solid #ddd;
	padding: 6px;
}

.store-row {
	cursor: pointer;
	&.faded {
		color: #999;
	}
}

.store-list-item {
	display: flex;
	justify-content: space-between;
	padding: 10px 15px;
	align-items: center;

	@media screen and (max-width: $screen-md-min)  { /* bigger than md */
		padding: 6px 15px;

		.store-name {
			display:flex;
			flex-wrap: wrap;
			flex-direction: column;
		}
		.store-location{
			margin-top: -8px;
		}
	}

	.stock-favorite {
		flex: 0 0 10px;
		margin-right: 15px;
	}

	.store-info {
		color: #333;
		text-decoration: none;
		display: flex;
		flex: 1 1 95%;
		justify-content: space-between;
		align-items: center;

		span {
			flex: 1 0 25%;

			&.store-location {
				color: #969ba0;
			}
		}

		.is-store-open {
			flex: 0 0 120px;
			white-space: nowrap;
			min-width: 100px;
		}
	}

	&:hover {
		background-color: #f5f5f5;
	}
}

.store-info-container {
	transition: opacity .5s;
	&.faded {
		opacity: 0.4;
	}

	.store-images .ximg {
		height: 64px;
		width: 64px;
	}
}

.store-gmaps-container {
	width: 100%;
	max-width: 100%;
	position: relative;
	margin-bottom: 6px;
}

.store-gmaps-iframe {
	width: 100%;
	height: 100%;
}

.store-pickup-point {
	font-size: 0.9em;
	color: #969ba0;
	white-space: nowrap;
}
// styling for specific store page
.store {
	h1 .stock-favorite span {
			height: 20px;
			width: 20px;
	}
}