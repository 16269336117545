.bread-crumbs {
	padding: 0 12px;

	.breadcrumb {
		font-size: $font-size-small;
		margin-bottom: 0;
		padding: 0 0 8px 0;
		display: flex;
		flex-wrap: wrap;

		li {
			display: inline-flex;
			align-items: center;

			a {
				font-size: $font-size-small;
				color: $brand-primary;
				font-weight: $font-weight-semibold
			}
		}

		& > li + li {
			@include icon-left-placement('/api/dynimg/icon/chevron_right/1A1A1D', 8px);

			&::before {
				margin: 0 4px;
			}
		}
	}

	.router-link-active {
		color: $gray-one;
	}
}

.hamburger-menu {
	.breadcrumb {
		display: flex;
		justify-content: center;
		margin-bottom: 0;
		padding: 0;

		& > a {
			display: inline-flex;
			align-items: center;

			& + a {
				@include icon-left-placement('/api/dynimg/icon/chevron_right/1A1A1D', 8px);

				&::before {
					margin: 0 4px;
					transform: translateY(1px);
				}
			}
		}
	}
}