@mixin generateSlider($backgroundColor, $barColor) {
	background-color: $backgroundColor;

	.slider-bar {
		background-color: $barColor;
	}
}

.slider {
	@extend .r-3;
	height: 2px;
	position: relative;
	background-color: $gray-three;
	margin: 12px 0;

	.slider-bar {
		background-color: $gray-two;
		left: 0;
		right: 0;
	}
}

.slider-handle {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 18px;
	height: 18px;
	z-index: 2;
	border: 2px solid $gray-two;
	background-color: $lightest;
	border-radius: 50rem;
	cursor: pointer;
	outline-color: transparent;
	user-select: none;
}
.slider-handle-left {
	@extend .slider-handle;
	left: 0;
	transform: translateX(-50%) translateY(-50%);
}

.slider-handle-right {
	@extend .slider-handle;
	right: 0;
	transform: translateX(50%) translateY(-50%);
}

.slider-blue {
	@include generateSlider(#dde2e3, $brand-primary);
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) inset;
	z-index: 5;
}
