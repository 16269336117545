.toplist {
	.panel:nth-of-type(2) {
			background-color: rgb(255, 239, 170);
		}
	.panel:nth-of-type(3) {
			background-color: rgb(229, 233, 235);
		}
	.panel:nth-of-type(4) {
			background-color: rgb(247, 220, 188);
		}
}

.btn-top-list-mobile {
	text-align: start;
}

.dropdown-container {
	justify-content: flex-end;
}

.toplist-container {
	display: flex;
	flex-wrap: nowrap;
	max-height: 70px;
	overflow: hidden;
	padding: 6px 6px;
	align-items: center;

	.username {
		font-size: 1.3em;
	}

	&.user-border {
		border: 2px solid black;
	}

	.sort-value-wrapper {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		color: #74cac2;
		font-weight: $font-weight-semibold;
	}

	.ximg.pixelated {
		background-size: cover;
		height: 54px;
		width: 54px;
	}

	/* Desktop styling */
	.right-container {
		display: flex;
		align-items: center;
		flex: 1 1 33%;
		overflow: hidden;

		.achievements-wrapper {
			overflow: hidden;
			padding: 0;
			flex-grow: 1;
			width: 25%;
			height: 47px;

			@media screen and (min-width: $screen-sm-min) {
				width: 16.6666666667%;
			}

			.ximg {
				height: 47px;
				width: 47px;
				margin-right: 4px;
			}
		}

		.sort-value-wrapper {
			flex-basis: 120px;
			width: 120px;
			font-size: $level-two-heading;
			padding-left: 30px;

			img {
				height: 25px;
				margin-right: 6px;
			}

			span {
				line-height: 1;
			}
		}
	}
	/* Mobile styling */
	.right-container-mobile {
		display: flex;
		align-items: center;
		margin-top: -4px;
		overflow: hidden;
		flex: 1 1 100%;

		.achievements-wrapper {
			overflow: hidden;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			max-height: 32px;

			.ximg {
				height: 32px;
				width: 32px;
			}
		}

		.sort-value-wrapper {
			margin-right: 8px;
			font-size: $level-three-heading;
			min-width: 100px;

			img {
				margin-right: 2px;
				height: 25px;
			}
		}
	}
}

.toplist-number-container {
	vertical-align: middle;
	height: 54px;
	line-height: 54px;
	font-weight: bold;
	width: 33px;
	padding-left: 12px;
	float: left;
	margin-right: 12px;
}

.toplist-user-info {
	flex-grow: 2;
	width: 25%;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
}

.top-list-dropdown {
	.top-list-title {
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 85%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.btn-dropdown {
		font-weight: normal;
		height: 34px;
	}

	.dropdown-menu {
		right: 0px;
		left: inherit;
	}

	.selected-item {
		background-color: #cbdfee;
	}
}

.top-list-icon {
	display: inline-block;
	width: 17px;
	height: 17px;
	background-size: 17px 17px;
	margin-right: 6px;
	vertical-align: text-top;
}

.top-list-top {
	background-image: cdn-url('/img/icons/topplista_toppen.svg');
}

.top-list-rating {
	background-image: cdn-url('/img/icons/feedback/star_1.svg');
}

.top-list-prebook {
	vertical-align: top;
	background-image: cdn-url('/img/icons/topplista_forboka.svg');
	margin-right: 7px;
	width: 16px;
	height: 20px;
	background-size: 16px 20px;
}

.top-list-hype {
	background-image: cdn-url('/img/icons/feedback/hype_5.svg');
	margin-right: 3px;
	margin-left: 1px;
	width: 19px;
	height: 19px;
	background-size: 19px 19px;
}

.top-list-console {
	background-image: cdn-url('/img/icons/topplista_konsol.svg');
}

.top-list-hate {
	background-image: cdn-url('/img/icons/topplista_botten.svg');
}

/* For toplist product pages */
.toplist-page {
	.toplist-header-active {
		position: relative;
	}

	h1 {
		padding: 12px 0 0 12px;

		.ximg {
			width: 25px;
			height: 25px;
		}
	}
}