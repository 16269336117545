@media screen and (max-width: $screen-md-max) {

	.sticky-add-to-cart {
		z-index: 300;
		position: fixed;
		left: 0;
		width: 100%;
		background-color: $lightest;
		display: flex;
		justify-content: space-between;
		overflow: hidden;
		align-items: center;
		min-height: 67px;
		max-height: 67px;

		&.hide-sticky-button {
			visibility: hidden;
			border-top: 0 solid $gray-three;
			padding: 0 8px;
			bottom: -67px;
			transition: all 0.25s linear;
		}

		&.show-sticky-button {
			visibility: visible;
			border-top: 2px solid $gray-three;
			padding: 8px;
			bottom: 0;
			transition: all 0.25s linear;
		}
		.sticky-add-to-cart > .price-value {
			font-size: 3rem;
			margin-right: 8px;
			flex: 2;
		}

		& > .text-btn {
			margin: 0 0 0 8px;
			width: auto;
		}
	}

	.hide-livechat-margin {
		margin-bottom: 0;
		transition: margin 0.25s linear;
	}

	.show-livechat-margin {
		margin-bottom: 66px;
		transition: margin 0.25s linear;
	}
}

@media screen and (min-width: $screen-lg-min) {
	.sticky-add-to-cart {
		display: none;
	}

	.product-top, .product-header {
		display: table;
		table-layout: fixed;
		width: 100%;
		.product-top-row {
			display: table-row;
			> section {
				display: table-cell;
				vertical-align: middle;
				width: 50%
			}
		}
	}
}

.product-info {
	margin-top: 15px;

	.original-description {
		margin: 30px 15px 16px;
	}
}

.product-nav-btn {
	background-color: #ECEFF0;
	color: #afafaf;
	z-index: 800;
	padding: 10px 3px 10px 3px;
	border-bottom: 2px solid #BBC4C8;
	border-radius: 2px;
	cursor: pointer;
	transition: background-color 0.125s ease-in-out;
	transition: color 0.125s ease-in-out;
	transition: opacity 0.125s ease-in-out;
}

.product-nav-btn:hover {
	background-color: #f2f2f2;
	color: #a9a9a9;
}

.product-nav-btn:active {
	background-color: #e7eaeb;
	color: #888888;
}

.product-nav-btn.left-side {
	left: -4px !important;
	box-shadow: -3px 0px 3px -2px rgba(0, 0, 0, 0.3);
}

.product-nav-btn.left-side::after {
	content: '';
	position: absolute;
	bottom: -5px;
	left: 0;
	width: 0;
	height: 0;
	border-top: 4px solid #BBC4C8;
	border-left: 4px solid transparent;
}

.product-nav-btn.right-side {
	right: -4px !important;
	box-shadow: 3px 0px 3px -2px rgba(0, 0, 0, 0.3);
}

.product-nav-btn.right-side::after {
	content: '';
	position: absolute;
	bottom: -5px;
	right: 0;
	width: 0;
	height: 0;
	border-top: 4px solid #BBC4C8;
	border-right: 4px solid transparent;
}

.product-loading {
	cursor: not-allowed;
	background-color: #f4f4f4;
	color: #dfdfdf;
}

.product-loading:hover {
	background-color: #f4f4f4;
	color: #dfdfdf;
}

.breadcrumb > li + li:before {
	padding-left: 5px;
	padding-right: 0;
}

.product-page-wrapper > .product-wrapper {
	position: relative;
	width: 100%;

	.product {
		width: 100%;
		position: absolute;
	}
}

.product-page {
	margin-top: -4px;
	padding-top: 2px;

	.product-header {
		padding: 0;

		h1 {
			margin: 0;
		}

		.energy-marking {
			padding-right: 12px;
			text-align: right;
		}
	}

	p {
		font-weight: initial;
	}

	.variant-selector {
		margin-bottom: 16px;
	}

	.product-wrapper {
		width: 100%;
		max-width: 100%;
		float: left;

		.discontinued-info {
			margin: 10px 0;

			.alert.alert-danger {
				position: relative;
				padding: 18px 26px;
				display: flex;
				align-items: center;
				margin-bottom: 0;

				.ximg {
					height: 63px;
					width: 36px;
					margin-right: 20px;
				}

				.info {
					flex: 1 1 80%;

					h2 {
						margin: 0 0 4px
					}

					p {
						margin: 0;
					}
				}
			}
		}

		.product-top-row .radio-picker .right-text {
			@keyframes fadeInAnimation {
				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}

			animation: fadeInAnimation ease .8s;
		}
	}

	.product-delivery-info {
		background-color: #ECEFF0;
		border-radius: $border-radius;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		text-align: left;

		img {
			flex: 10%;
			width: 11px;
			height: 11px;
		}

		p {
			flex: 90%;
			color: $darkest;
		}
	}

	.product-content {
		margin-top: 10px;
		display:flex;
		width:100%;
		position: relative;

		.product-description {
			flex: 1 1 70%;
			max-width: 70%;
		}

		@media screen and (max-width: $screen-md-max) {
			.product-description {
				flex: 1 1 100%;
				max-width: 100%;
			}
		}
	}

	.product-nav {
		list-style: none;
		padding: 0;
		margin: 0 12px;
		display: flex;
		background: $lightest;
		overflow-x: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;
		z-index: 299;
		position: sticky;
		transition: top 200ms ease-in 0.5ms;
		top: 0;

		&::-webkit-scrollbar {
			height: 0px;
			background: transparent;
		}

		li {
			display: block;
			border-bottom: solid $gray-three 4px;
			align-items: flex-start;
			font-size: 16px;

			a {
				display: inline-block;
				padding: 5px 10px;
				color: $text-color;
				text-decoration: none;
			}

			&:first-child {
				a {
					padding-left: 0;
				}
			}

			&:last-child {
				flex: 1;
				padding-right: 10px;

				&::after {
					content: '';
					border-bottom: $brand-primary 4px solid;
					position: absolute;
					width: var(--cursor-width);
					left: var(--cursor-position);
					transition: width 1s ease,
								left 1s ease;
					bottom: 0;
				}
			}
		}
	}

	.category-title {
		font-weight: bold;

		span {
			font-weight: normal;
			font-size: 18px;
			color: #969ba0;
		}
	}

	.nav > li > a {
		strong {
			display: flex;
			align-items: center;

			.badge {
				margin-left: 4px;
				display: inline-flex;
				align-items: center;
			}
		}

		&:focus {
			background-color: $lightest;
		}
	}
	.nav {
		.loading-spinner {
			height: 20px;
			display: inline-block;
		}
	}
	.nav > li.disabled {
		pointer-events: none;
		color: #eee;
		pointer-events: none;
		:hover {
			color: #eee;
		}

		:focus {
			color: #eee;
		}
	}
	.nav > li.disabled > a {
		color: #eee;
		:hover {
			color: #eee;
		}
		:focus {
			color: #eee;
		}
	}
	.product-specs td {
		width: 50%;
	}

	.scroll-top {
		display: flex;
		justify-content: flex-end;
		font-size: 85%;
	}
}

.read-more-wrapper {
	overflow: hidden;
	position: relative;
	width: 100%;

	&.expanded {
		max-height: initial !important;
		padding-bottom: 35px;
	}

	.read-more-footer {
		position: absolute;
		bottom: 0;
		width: 100%;
		background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 80%);
		padding-top: 200px;
		padding-bottom: 10px;
		pointer-events: none;
		z-index: 205;

		&.hidden {
			display: none;
		}
		&.open {
			padding-top: 0;
			background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%);
			height: 35px;
		}
		button {
			margin: 0 auto;
    		display: block;
			background-color: transparent;
			border: none;
			color: $brand-primary;
			pointer-events: all;
			&:focus {
				border: none;
				outline: none;
			}
		}
	}
}

.info-collapse * {
	max-width: 100%;
}

.tech-specs-table td {
	border-color: #eee !important;
}

.hygiene-article {
	padding: 5px;
	display: flex;
	align-items: center;
	margin: 30px 0 20px;
}

.hygiene-article-icon {
	background-color: #EEE;
	margin-right: 14px;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;

	.ximg {
		width: 46px;
		height: 46px;
	}
}

.product-aside {
	align-self: flex-start;
	display: none;
	@media (min-width: $screen-md-min) {
		display: block;
		float: left;
		min-height: 1px;
		padding-right: 8px;
	}

	flex: 0 1 30%;
	max-width: 30%;
	h2 {
		padding: 5px 0;
		margin: 0;
		font-size: 1.4em;
	}
}

/* Product Campaigns */

.product-campaigns {
	/* Set this to use magic flex for icon position */
	$linkRowHeight: 22px;
	padding: 0 12px;
	margin-top: 12px;

	.campaign-link-wrap {
		display: flex;
		align-items: center;
		margin: 4px 0;

		.icon-wrap {
			display: flex;
			flex-direction: column;
			align-self: stretch;

			.price-icon {
				margin-right: 7px;
				width: 16px;
				height: 16px;
				flex: 0 0 $linkRowHeight;
			}

			&::after {
				content: '';
				flex: 1 1 auto;
			}
		}

		.standard-higlight-link {
			display: flex;
			flex-wrap: wrap;

			.highlight-name {
				font-weight: $font-weight-semibold;
				margin-right: 4px;
				cursor: default;
				min-height: $linkRowHeight;
			}

			.highlight-link {
				text-decoration: underline;
				color: $gray-two;
				min-height: $linkRowHeight;

				&:hover {
					color: $gray-one;
				}
			}
		}

		.custom-highlight-link {
			font-weight: $font-weight-semibold;
			min-height: $linkRowHeight;

			&:hover {
				text-decoration: underline;
			}
		}

		&.list .highlight-name,
		&.list .custom-highlight-link {
			color: $brand-primary;
		}

		&.campaign .highlight-name,
		&.campaign .custom-highlight-link {
			color: $destructive;
		}
	}
}

/* Product tip display / Bundle products */

.product-tip-display {
	display: flex;
	flex-wrap: wrap;
	padding: 4px 12px;

	.bundle-info-wrap, .bundle-product-list {
		flex: 1 1 100%;
	}

	.bundle-info-wrap {
		margin-bottom: 8px;

		.bundle-image-wrap {
			display: flex;

			.glyphicon.glyphicon-plus {
				padding: 1px;
				margin: auto 0;
			}

			.ximg {
				margin: 6px;
				flex-grow: 1;
				width: 80px;
				height: 80px;
			}
		}

		.bundle-price-wrap {
			display: inline-block;

			.text-highlight {
				margin: 0 4px;
			}

			.price-value {
				margin: 2px;
        		display: inline;
			}

			.small.text-secondary {
				margin: 0 2px;

				.original-price {
					margin: 0 4px;
					text-decoration: line-through;
				}
			}
		}
	}

	hr {
		flex: 1 0 100%;
	}

	.btn {
		font-size: $font-size-small;
		font-weight: bold;
		display: flex;
		align-items: center;

		img {
			height: 14px;
			margin-right: 4px;
		}

		.chevron-right {
			font-size: 13px;
		}
	}

	@media (min-width: $screen-md-min) {
		hr {
			&:first-child {
				order: 1;
			}

			&:last-child {
				order: 4;
			}
		}

		.bundle-info-wrap, .bundle-product-list {
			flex: 1 1 50%;
		}

		.bundle-product-list {
			order: 2;
		}

		.bundle-info-wrap {
			order: 3;
			margin-bottom: 0;
		}
	}
}

/* Header for reviews and Q&A section */

.feedback-section-header {
	margin: 4px 0 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;

	/* Used for review-section to align items correctly*/
	&.has-feedback {
		align-items: flex-end;

		h2 {
			flex: 1 1 100%;
		}
	}

	h2 {
		display: flex;
		align-items: center;
		justify-content: left;

		.badge {
			background: transparent;
			color: $text-secondary-color;
			align-self: flex-end;
			font-size: $level-two-heading;
			font-weight: 400;
			line-height: initial;
		}

		.toggle-wrapper {
			line-height: 0;

			.help {
				font-size: $font-size-base;
			}
		}
	}

	.rating-wrap {
		display: flex;
		flex-direction: column;
		flex: 1;

		.rating {
			display: flex;
			align-items: center;
			margin-top: 2px;

			.count {
				margin-left: 4px;
			}
		}
	}

	.loading {
		margin: 0 auto;
	}

	.review-section-button {
		margin-left: auto;
	}

	.review-section-dropdown {
		width: 30%;
		margin-left: 4px;
	}
}

@media (max-width: $screen-md-min) {
	.feedback-section-header {
		flex-wrap: wrap;

		.review-section-dropdown {
			margin-top: 10px;
			width: 100%;
			margin-left: 0;
			@include center-dropdown-label();
		}
	}
}

/* Verticula */
.verticula-root {
	.modal-container {
		padding: 20px 0px 0px;
		max-width: 100%;
	}
}