.comment-wrapper {
	hr {
		margin: 0;
	}
}

.comment {
	display: block;
	padding: 8px 0px;

	.comment-header {
		line-height: 1.22;

		.ximg {
			height: 32px;
			width: 32px;
		}
	}

	.ximg {
		float: left !important;
		margin-right: 8px;
		border-radius: 2px;
	}

	.text {
		margin-left: 40px;
		font-size: 0.9em;
	}
}

.reply-to {
	color: #3097D1;
	font-size: 0.9em;
}

.highlighted {
	animation-fill-mode: forwards;
	animation-timing-function: ease-in;
	animation-name: fadeIn;
	animation-duration: 1s;
}

@keyframes fadeIn {
	from {
		background-color: #fff;
	}
	to {
		background-color: #eee;
		//outline: #e5e5e5 solid 1px;
	}
}
