.product-accessories-popup {
    .popup-buy-button {
        @media screen and (max-width: $screen-md-min) {
            padding: 10px;
            line-height: normal;
        }
    }

    @media screen and (max-width: $screen-md-min) {
        max-width: 100% !important;
    }
}