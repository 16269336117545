.member-nav {
	.mobile-wrap .nav {
		display: flex;
		align-items: center;
		gap: 8px;
		margin: 0;
		padding: 0 8px;
		height: $input-large;
		white-space: nowrap;
		background: $gray-four;

		.nav-item {
			flex: 0 1 auto;
			display: inline-flex;
			align-items: center;
			border-right: 1px solid $gray-three;
			padding-right: 8px;
			height: max-content;

			.secondary-link {
				display: flex;
				align-items: center;
				gap: 4px;
				line-height: 1;

				.member-icon {
					height: 14px;
					width: 14px;
				}
			}

			.member-menu-dropdown .secondary-link {
				line-height: 2;
			}
		}

		.tutorial {
			padding: 0;
		}

		.member-menu-dropdown {
			position: absolute;
			left: 0;
			z-index: 1002;
			background: $gray-four;
			box-shadow: $box-shadow;
			min-width: 100%;
			border-radius: 1px;

			a {
				padding: 10px 15px;
				width: 100%;
				display: block;
			}
		}
	}

	.desktop-wrap {
		padding: 12px 12px 0 0;

		.tile {
			.member-icon {
				height: 16px;
				width: 16px;
				margin-right: 8px;
			}

			.router-link-active .member-icon {
				filter: brightness(2000%);
			}
		}
	}
}