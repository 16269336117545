.campaign-list {
	margin: 0 12px;

	.highlight-section, .highlight-section > header, .highlight-container {
		padding: 0;
		margin: 8px 0;
	}

	.campaign-list-heading, .highlight-section > header {
		padding: 0 2px;

		header .ximg {
			height: 22px;
			width: 22px;
		}
	}

	.section-selector {
		.btn {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	header {
		display: flex;
		align-items: center;
		padding: 6px 3px;
	}

	.product-browser .list-settings {
		padding: 0;
	}

	.campaign-sections {
		display: block;
		min-width: 100%;

		li a {
			display: flex;
			align-items: center;
			gap: 6px;

			.ximg {
				height: 15px;
				width: 15px;
			}
		}
	}
}

.campaign-description {
	padding: 12px;

	* {
		max-width: 100%;
	}

	&.second-description {
		overflow-anchor: none;
		position: relative;
	}

	.text-btn {
		@include button-base();
		min-width: 124px;
		max-width: max-content;

		&._large, &._small, &._tiny {
			min-width: 124px;
		}
	}
}