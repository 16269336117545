.progress {
	box-shadow: none;
	position: relative;
	background-color: $gray-three;

	.progress-text {
		white-space:nowrap;
		font-size: $font-size-base;
		line-height: 22px;
		text-align: left;
		user-select: none;
		cursor: default;
	}

	.progress-bar {
		background-color: $brand-primary;
		box-shadow: none;
	}
}

.product-list-filter-wrapper .filter-result{
	left: 0;
	transition: left .25s linear;
}

.filter-result {
	position: fixed;
	left: -100%;
	bottom: 0;
	width: 75vw;
	max-width: 400px;
	padding: 8px 12px;
	border-top: 1px solid $gray-three;
	background-color: $lightest;
	z-index: 1000;
	transition: left .25s linear;
}

.progress-result-counter {
	flex: 1 1;
	height: $input-normal;

	.progress-bar {
		@include progress-bar-variant($success-dark);
		position: relative;
		height: $input-normal;
		border-radius: $border-radius 50rem $border-radius $border-radius;
		box-shadow: none;

		&._full {
			border-radius: $border-radius;
		}
	}
	.progress-text {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		padding-left: 12px;
		display: flex;
		align-items: center;
	}
}

.progress-poll {
	height: 0;
	padding-top: $padding-base-vertical;
	padding-bottom: $font-size-base * .8;
	.progress-bar {
		@include progress-bar-variant(#2a659d);
		margin-top: -$padding-base-vertical;
		margin-bottom: -$font-size-base * .5;
		height: 0;
		padding-top: $padding-base-vertical;
		padding-bottom: $font-size-base * .8;
	}
	.progress-text {
		position: absolute;
		top: 0;
		bottom: 0;
		display: flex;
		align-items: center;
	}
}

.progress-level {
	$paddingTop: 5px;
	$paddingBottom: 5px;
	$paddingTopSmall: 4px;
	$paddingBottomSmall: 4px;
	$progressHeight: 12px;
	$progressHeightSmall: 10px;
	height: $progressHeightSmall;
	background-color: transparent;
	border: 1px solid $lightest;
	border-radius: 50rem;
	margin-bottom: 6px;

	&._small {
		height: $progressHeightSmall;
	}

	.progress-bar {
		@include progress-bar-variant($success-dark);
		box-shadow: none;
		border-radius: 50rem 100rem 1px 50rem;
	}

	.progress-text {
		position: absolute;
		top: 1px;
		bottom: -1px;
		display: flex;
		align-items: center;
	}
}

.progress-achievements {
	$paddingTop: 3px;
	$paddingBottom: 3px;
	$paddingTopSmall: 2px;
	$paddingBottomSmall: 2px;
	height: 0;
	padding-top: $paddingTop;
	padding-bottom: $paddingBottom;
	&._small {
		padding-top: $paddingTopSmall;
		padding-bottom: $paddingBottomSmall;
	}
	background-color: #20244f;
	border: 1px solid #038263;
	border-radius: 0;

	.progress-bar {
		@include progress-bar-variant(#74c5bc);
		margin-top: -$paddingTop;
		margin-bottom: -$paddingBottom;
		height: 0;
		padding-top: $paddingTop;
		padding-bottom: $paddingBottom;
	}
	.progress-text {
		position: absolute;
		top: 1px;
		bottom: -1px;
		display: flex;
		align-items: center;
	}
}

.progress-stock {
	$paddingTop: 2px;
	$paddingBottom: 1px;

	height: 0;
	padding-top: $paddingTop;
	padding-bottom: $paddingBottom;
	border-radius: 1px;
	.progress-bar {
		@include progress-bar-variant($success-light);
		margin-top: -$paddingTop;
		margin-bottom: -$paddingBottom;
		height: 0;
		padding-top: $paddingTop;
		padding-bottom: $paddingBottom;
	}
	.progress-text {
		position: absolute;
		top: 1px;
		bottom: -1px;
		display: flex;
		align-items: center;
	}
}

.progress-out-of-stock {
	.progress-bar {
		@include progress-bar-variant($highlight);
	}
}

.progress-space {
	background-color: $progress-bg;
	@include box-shadow(inset 0 1px 2px rgba(0,0,0,.1));
}

.progress-bar.progress-space {
	background-color: $gray-three;
}

.product-list-progress-container {
	background: $lightest;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
	z-index: 1000;
	bottom: 0;
	font-size: $font-size-small;
	padding: 0 6px;
	height: 50px;
	display: flex;
	flex-direction: column;

	.secondary, a {
		height: fit-content;
	}

	.progress {
		flex: 0 0 auto;
		height: 5px;
		margin: 2px 0 0;
	}

	@media screen and (min-width: $screen-lg-min) {
		height: 55px;
	}
}
