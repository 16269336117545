.order {
	border-radius: $border-radius;
	border: 1px solid $gray-three;
	margin-bottom: 20px;

	.panel-body {
		padding-bottom: 4px;
	}

	.table {
		border-bottom: 1px solid $gray-three;
		margin-bottom: 0px;

		.order-id-wrap {
			background: $gray-two;
			color: $lightest;
			font-weight: $font-weight-semibold;

			td {
				vertical-align: middle;
				border: none;
				border-radius: $border-radius 0 0 0;

				&.id-number {
					width: 100%;
					border-radius: 0 $border-radius 0 0;
				}
			}
		}

		.postnordWidget {
			max-width: 0;
			white-space: normal !important;
		}
	}

	.table > tbody > tr > td {
		border-color: $gray-three;

		&:first-child {
			white-space: nowrap;
		}
	}

	.order-progression {
		padding-left: 20px;
		white-space: normal;

		.inner-container {
			padding-left: 28px;
			border-left: 2px solid $gray-three;
		}

		.icon {
			display: block;
			position: absolute;
			left: -39px;
			top: 10px;
			z-index: 10;
			width: 22px;
			height: 22px;
			border-radius: 50%;

			> span {
				background-repeat: no-repeat;
				background-position: center;
				width: 14px;
				height: 15px;
				display: block;
				left: 4px;
				top: 3px;
				position: absolute;
			}
		}

		.order-status-event {
			position: relative;
			margin: 16px 0;

			.icon {
				background-color: $brand-primary;
			}

			// Element used to hide the top of the "progression line".
			&:first-child {
				&:before {
					content: '';
					background-color: #fff;
					position: absolute;
					left: -34px;
					width: 10px;
					height: 10px;
				}
			}

			// Element used to hide the bottom of the "progression line". Only hide
			// the bottom of the progression line when there is more than one status
			// event (event list is expanded).
			&:nth-last-child(n + 1):last-child {
				&:after {
					content: '';
					background-color: #fff;
					position: absolute;
					top: 22px;
					left: -34px;
					width: 10px;
					bottom: 0px;
				}
			}
		}
	}

	.service-store {
		background-color: $gray-four;
	}

	.status-entries {
		padding: 2px 0;
		text-align: center;
		border-top: 1px solid $gray-three;
	}

	.panel-body {
		border-top: 1px solid $gray-three;
	}

	.order-footer {
		border-top: 1px solid $gray-three;
		width: 100%;
		background-color: $gray-three;

		td {
			padding: 2px 4px;

			&:first-child {
				padding-left: 8px;
			}

			.ximg {
				width: 20px;
				height: 20px;
			}
		}
	}

	.subscription-products {
		border-top: 1px solid $gray-four;
		width: 100%;
		background-color: $gray-four;

		.subscription-total-cost {
			font-weight: bold;
			padding-bottom: 8px;
		}
		.subscription-cost {
			text-align: right;
		}

		td {
			padding: 0px 8px;
		}

		th {
			padding: 8px 8px 0px 8px;
		}
	}
}

.error-description {
	padding: 12px;

	.ximg {
		height: 40px;
		width: 40px;
	}
}

.order.loading-error .alert.alert-danger {
	text-align: center;
}

.order-store-changer-modal {
	padding: 12px;
	width: 90vw;
	max-width: 100%;

	.change-store-buttons {
		margin-top: 4px;
		display: flex;
		flex-direction: column;
		gap: 8px;

	}
	@media screen and (min-width: $screen-md-min) {
		width: 80vw;
		max-width: 808px;

		.change-store-buttons {
			flex-direction: row;
			max-width: 50%;
			margin-left: auto;
		}
	}
}


.order-row {
	white-space: nowrap;
	overflow: hidden;

	.btn {
		height: 25px;
		width: 23px;
		padding: 0 8px;
	}

	&.member-page {
		.order-row-wrap {
			position: relative;
			height: 73px;
			display: flex;

			.img-wrap {
				margin-right: 8px;
			}

			.info-wrap {
				display: flex;
				justify-content: space-between;
				flex: 1 1 auto;

				.product-info-wrap {
					width: 70%;

					.product-stock {
						display: flex;
						align-items: center;

						span {
							margin-right: 4px;

							&.stock-icon-outofstock {
								margin-bottom: 0;
							}
						}
					}
				}

				.btn-remove-item {
					align-self: center;
				}
			}
		}

		&.cancelled {
			opacity: .7;
			text-decoration: line-through;
		}
	}
}

.order-row:not(:last-child) {
	border-bottom: 1px solid #eee;
	padding-bottom: 4px;
	margin-bottom: 4px;
}

.order-row .ximg {
	height: 62px;
	width: 62px;
}

.postnord-tracking {
	.main-container {
		box-shadow: none;
	}

	.details-box {
		overflow: hidden;
	}

	.details-box__status__text {
		white-space: pre-wrap;
	}
}
