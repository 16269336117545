@mixin button-variant($color, $background, $border, $lighten: 10%) {
	color: $color;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		content: "";
		z-index: -1;
		background-color: $background;
		border-color: $border;
	}

	&.active:before,
	&:active:before,
	&.focus:before,
	&:focus:before {
		color: $color;
		background-color: lighten($background, $lighten * 0.7);
		border-color: lighten($border, $lighten);
	}

	&:hover {
		color: $color;

		&:before {
			color: $color;
			background-color: lighten($background, $lighten * 0.7);
			border-color: lighten($border, $lighten);
		}
	}

	.open > &.dropdown-toggle {
		:before {
			color: $color;
			background-color: lighten($background, $lighten * 0.7);
			border-color: lighten($border, $lighten);
		}

		&.focus:before,
		&:focus:before,
		&:hover:before {
			color: $color;
			background-color: lighten($background, $lighten * 0.7);
			border-color: lighten($border, $lighten);
		}
	}

	&.disabled:before,
	&[disabled]:before,
	fieldset[disabled] &:before {
		&.focus:before,
		&:focus:before,
		&:hover:before {
			background-color: $background;
			border-color: $border;
		}
	}

	.badge {
		color: $background;
		background-color: $color;
	}
}

.btn {
	$normalBorderSize: 2px !default;
	$pressedBorderSize: 1px !default;
	position: relative;
	z-index: 1;
	cursor: pointer;
	background: none !important;
	border: none !important;
	border-radius: $rounded-radius;

	&:before {
		border-bottom: $normalBorderSize solid transparent;
		border-radius: inherit;
	}

	&.focus,
	&:focus,
	&:hover {
		text-decoration: none;
	}

	&.focus,
	&:focus {
		&.active,
		&:active {
			outline: 0;
		}
		outline: 0;
	}

	&.active,
	&:active {
		background-image: none;
		@include box-shadow(none);

		&:before {
			border-bottom-width: $pressedBorderSize;
		}
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: $cursor-disabled;
		@include opacity(.65);
		@include box-shadow(none);
	}
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle):before {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.btn-group > .btn:last-child:not(:first-child):not(.dropdown-toggle):before {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.btn-group > .btn-bordered:first-child:before {
	margin-left: 0;
}

.btn-group > .btn-bordered:before {
	margin-left: -1px;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group {
	margin-left: 0;
}

.btn-bordered  {


	&:before {
		border: 1px solid $btn-white-border;
	}
}

.btn-default {
	@include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}

.btn-white {
	@include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}

.btn-white-outline  {
	&:before {
		border: 1px solid $btn-white-border;
	}
	&:hover {
		color: #fff;
	}
	@include button-variant($btn-warning-color, transparent, $btn-white-border);
}

.btn-primary {
	@include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);

	&.filter {
		display: flex;
		align-items: center;
		& > img {
			width: 12px;
			margin-right: 4px;
		}
	}
}
// Success appears as green

.btn-success {
	@include button-variant($btn-success-color, $btn-success-bg, $btn-success-border);
}
// Info appears as blue-green

.btn-info {
	@include button-variant($lightest, $brand-primary, $brand-primary);
}
// Warning appears as orange

.btn-warning {
	@include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border);
}
// Danger and error appear as red

.btn-danger {
	@include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);

	&:hover {
		color: #fff;
	}
}

.list-filter-btn {
	margin: 0 4px 0 0;
	display: inline-flex;
	align-items: center;
	.badge {
		padding: 0 7px 0 6px;
		margin-left: 4px;
		height: 18px;
		display: flex;
		align-items: center;
		top: 0;
	}
}